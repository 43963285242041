import classNames from 'classnames';
import { TpCardPlanChildrenProps } from '@features/Paywall/types';
import { useCurrentPrice } from '@features/Paywall/widgets/subscription-card/current-price/useCurrentPrice';
import styles from './current-price.module.scss';
type TpCurrentPriceProps = TpCardPlanChildrenProps & {
  className?: string;
  classNamePerTerm?: string;
};
export const CurrentPrice = (props: TpCurrentPriceProps) => {
  const {
    plan,
    checked,
    className,
    classNamePerTerm
  } = props;
  const {
    displayPrice,
    formattedPrice,
    currency,
    isLongCurrencies,
    priceDigit
  } = useCurrentPrice({
    plan
  });
  if (!displayPrice) {
    return null;
  }
  return <div className={classNames({
    [styles.currentPriceChecked]: checked,
    [styles.currentPriceIsLongCurrencies]: isLongCurrencies
  })} data-sentry-component="CurrentPrice" data-sentry-source-file="index.tsx">
      <h4 className={classNames(styles.currentPrice, className)}>
        <span>{currency}</span>
        <span>{priceDigit}</span>
        <div>
          <span>{formattedPrice}</span>
          {!isLongCurrencies && <p className={classNames(styles.perTerm, classNamePerTerm)}>
              {displayPrice.split(' ')[0]} {` `}
              <span className={styles.perPeriod}>
                {displayPrice.split(' ')[1]}
              </span>
            </p>}
        </div>
      </h4>
      {isLongCurrencies && <p className={classNames(styles.perTerm, classNamePerTerm)}>
          {displayPrice}
        </p>}
    </div>;
};