import React, { useCallback, useRef, useState } from 'react';
export const useProgress = (videoRef: React.RefObject<HTMLVideoElement>) => {
  const requestRef = useRef<number | null>(null);
  const [progress, setProgress] = useState(0);
  const startProgressUpdate = useCallback(() => {
    const updateProgress = () => {
      const video = videoRef.current;
      if (!video) return;
      const currentProgress = video.currentTime / video.duration;
      setProgress(Math.min(currentProgress, 1)); // Limit progress to 1

      if (currentProgress >= 1) {
        cancelAnimationFrame(requestRef.current!);
      } else {
        requestRef.current = requestAnimationFrame(updateProgress);
      }
    };
    requestRef.current = requestAnimationFrame(updateProgress);
  }, [videoRef]);
  const stopProgressUpdate = useCallback(() => {
    if (requestRef.current) {
      cancelAnimationFrame(requestRef.current);
      requestRef.current = null;
    }
  }, []);
  return {
    progress,
    startProgressUpdate,
    stopProgressUpdate
  };
};