import { useState } from 'react';
import { ContactFormModal } from '../contact-form-modal';
import styles from './contact-block-email3.module.scss';
export const ContactBlockEmail3 = () => {
  const [isContactModalVisible, setIsContactModalVisible] = useState(false);
  return <>
      <ContactFormModal placeLabel="contact_us_block_paywall" show={isContactModalVisible} onHide={() => setIsContactModalVisible(false)} data-sentry-element="ContactFormModal" data-sentry-source-file="index.tsx" />

      <h3 className={styles.issues}>
        Need help?{' '}
        <span className={styles.contact} onClick={() => setIsContactModalVisible(true)}>
          Contact us
        </span>{' '}
        now
      </h3>
    </>;
};