import { axiosMicroservice } from 'src/api/axios';

const modifyUrlMap = {
  braintree: 'braintree_upgrade',
  stripe: 'stripe/modify_subscription',
};

export const modifySubscription = async ({
  newPlanId,
  userId,
  email,
  app = 'FaceYoga',
  paymentSystem,
}: {
  newPlanId: string;
  userId: number;
  email: string;
  app?: string;
  paymentSystem: 'braintree' | 'stripe';
}): Promise<any> =>
  await axiosMicroservice.post(`${modifyUrlMap[paymentSystem]}`, {
    user_id: userId,
    new_plan_id: newPlanId,
    email: email,
    app: app,
  });
