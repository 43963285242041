import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import CircleButton from 'src/components/common/circle-button/circle-button';
import { MoneyBack } from 'src/components/common/money-back/money-back';
import { useStores } from 'src/components/common/root-store-provider/root-store-provider';
import { UiKitButton } from 'src/components/common/ui-kit-button';
import { UiKitLoadingButton } from 'src/components/common/ui-kit-loading-button';
import Banner, { BannerType } from 'src/features/Paywall/components/banner/banner';
import { PAYMENT_MODAL_QUERY_PARAM } from 'src/features/Paywall/constants';
import { useIsInViewport } from 'src/utils/hooks/use-is-in-viewport';
import { tikTokAdvancedMatching, tikTokEvent, useAnalytics } from '@features/Analytics';
import { IntroOfferDisclaimer, PaymentModalStatus, useGenerateBraintreeClientToken } from '@features/Payments';
import { FormattedMessage } from '@features/intl';
import ChevronLeft from '../../../../assets/icons/chevron-left.svg';
import { SubscriptionPlanCard } from '../subscription-card';
import { EmptySubscriptionPlanCardList } from './components/empty-subscription-plan-list';
import { GuaranteeBlock } from './components/guarantee-block';
import { PrivacyAgreement } from './components/privacy-agreement';
import { TrialsDisclaimer } from './components/trials-disclaimer/trials-disclaimer';
import styles from './payment-plans.module.scss';
const NAMESPACE = 'new-plan-paywall';
type TpPaymentPlansProps = {
  isGuaranteeBlock?: boolean;
  isInlinePaddlePaymentActive?: boolean;
  showPolicyAgreement?: boolean;
  subscriptionPlanGroup?: string;
};
export const PaymentPlans = observer(({
  isGuaranteeBlock = false,
  isInlinePaddlePaymentActive = false,
  showPolicyAgreement = true,
  subscriptionPlanGroup
}: TpPaymentPlansProps) => {
  const {
    paymentsStore,
    authStore: {
      isPaddleVariants,
      variant,
      user
    },
    quizStore: {
      countryCode
    }
  } = useStores();
  const router = useRouter();
  const {
    query,
    pathname
  } = router;
  const isInlinePaymentActive = query[PAYMENT_MODAL_QUERY_PARAM] === PaymentModalStatus.SHOW && isPaddleVariants;
  const {
    allSubscriptionPlans,
    subscriptionPlans,
    getDefaultDiscountPercent
  } = paymentsStore;
  const {
    trackFacebookEvent,
    trackGoogleEvent
  } = useAnalytics();
  const [selectedPlanId, setSelectedPlanId] = useState<string | undefined>(undefined);
  const [subscriptionAgreementChecked, setSubscriptionAgreementChecked] = useState(true);
  const [showModalOnPlanChange, setShowModalOnPlanChange] = useState(false);
  const paymentSectionRef = useRef<HTMLDivElement>(null);
  const isPaymentSectionRefInViewport = useIsInViewport(paymentSectionRef);
  const scrollPaymentSectionIntoView = useCallback(() => {
    if (paymentSectionRef.current) {
      paymentSectionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end'
      });
    }
  }, []);
  const subscriptionPlan = useMemo(() => allSubscriptionPlans ? allSubscriptionPlans?.find(({
    id
  }) => id === selectedPlanId) : undefined, [selectedPlanId, allSubscriptionPlans]);
  const handleCheck = useCallback(() => {
    setSubscriptionAgreementChecked(!subscriptionAgreementChecked);
  }, [subscriptionAgreementChecked]);
  const openPaymentModalHandler = useCallback(() => {
    // noinspection JSIgnoredPromiseFromCall
    router.push({
      pathname,
      query: {
        ...query,
        [PAYMENT_MODAL_QUERY_PARAM]: PaymentModalStatus.SHOW,
        ['plan']: selectedPlanId
      }
    }, undefined, {
      shallow: true
    });
  }, [pathname, query, router, selectedPlanId]);
  const handlePlanChange = useCallback(() => {
    trackGoogleEvent({
      eventName: 'initiate_checkout_another_plan'
    });
    // noinspection JSIgnoredPromiseFromCall
    router.push({
      pathname,
      query: {
        ...query,
        [PAYMENT_MODAL_QUERY_PARAM]: PaymentModalStatus.HIDE
      }
    }, undefined, {
      shallow: true
    });
  }, [pathname, query, router, trackGoogleEvent]);
  useEffect(() => {
    if (subscriptionPlanGroup) {
      // noinspection JSIgnoredPromiseFromCall
      paymentsStore.fetchSubscriptionPlans({
        group: subscriptionPlanGroup,
        countryCode: isPaddleVariants ? countryCode : undefined,
        paddle: isPaddleVariants
      });
    }
  }, [countryCode, isPaddleVariants, paymentsStore, subscriptionPlanGroup]);
  useEffect(() => {
    if (subscriptionPlan && !subscriptionPlan.specialOffer) {
      setSelectedPlanId(
      //eslint-disable-next-line unicorn/prefer-array-find
      allSubscriptionPlans?.filter(item => item.name === subscriptionPlan.name && item.specialOffer)[0]?.id);
    }
    if (subscriptionPlans !== undefined && subscriptionPlans.length > 0 && selectedPlanId == null) {
      setSelectedPlanId(subscriptionPlans.find(({
        preselected
      }) => preselected)?.id);
    }
  }, [allSubscriptionPlans, selectedPlanId, subscriptionPlan, subscriptionPlans]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: variant depends on user
  useEffect(() => {
    trackFacebookEvent({
      eventName: 'AddToCart',
      options: {
        email: user?.email
      }
    });
    trackGoogleEvent({
      eventName: 'add_to_cart_open'
    });
    user?.email ? tikTokAdvancedMatching(user.email) : null;
    tikTokEvent('AddToCart');
  }, [trackFacebookEvent, trackGoogleEvent, user?.email, variant]);
  useGenerateBraintreeClientToken({
    userFields: {
      email: user?.email,
      id: user?.id
    },
    merchantAccountId: subscriptionPlan?.merchantAccountId
  });
  const onSelectPlan = useCallback((id: string) => {
    setSelectedPlanId(id);
    !isPaymentSectionRefInViewport && scrollPaymentSectionIntoView();
  }, [isPaymentSectionRefInViewport, scrollPaymentSectionIntoView]);
  const isPlanHasPriceIntroOffer = subscriptionPlan && +subscriptionPlan.priceAfterIntroOffer > 0 && !subscriptionPlan.hasTrial;
  const handlePlanSelect = useCallback(() => {
    setShowModalOnPlanChange(false);
    openPaymentModalHandler();
    trackGoogleEvent({
      eventName: 'add_to_cart_chosen',
      options: {
        product_id: subscriptionPlan?.braintreePlanId ?? subscriptionPlan?.paddleIternalName ?? subscriptionPlan?.name ?? 'undefined'
      }
    });
  }, [openPaymentModalHandler, subscriptionPlan?.braintreePlanId, subscriptionPlan?.name, subscriptionPlan?.paddleIternalName, trackGoogleEvent]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: selectedPlanId depends on deps
  useEffect(() => {
    if (showModalOnPlanChange) {
      handlePlanSelect();
    }
  }, [handlePlanSelect, showModalOnPlanChange, selectedPlanId]);
  const isLoading = !subscriptionPlanGroup || !selectedPlanId || !subscriptionPlans || subscriptionPlans.length === 0;
  const renderSubscriptionPlans = useMemo(() => {
    if (isLoading) {
      return <EmptySubscriptionPlanCardList />;
    }
    const PlanCardComponent = SubscriptionPlanCard;
    return subscriptionPlans?.map((plan, index) => <PlanCardComponent wasPaymentTried={false} disabled={plan?.name === '7-DAYS PLAN' || plan?.name === '7-DAY PLAN'} prefix={NAMESPACE} key={index} plan={plan} isInlinePaymentActive={isInlinePaymentActive} defaultDiscountPercent={getDefaultDiscountPercent(plan.name)} selectedPlanId={selectedPlanId} onSelectPlan={onSelectPlan} variant={variant} isCardSelected={selectedPlanId === plan.id} />);
  }, [isLoading, subscriptionPlans, isInlinePaymentActive, getDefaultDiscountPercent, selectedPlanId, onSelectPlan, variant]);
  return <>
        {!isInlinePaddlePaymentActive && <h3 className={styles.titleSection}>
            <FormattedMessage id="Onboarding.Paywall.Main.Title.Plans" defaultMessage="Choose your plan" />
          </h3>}
        {isInlinePaddlePaymentActive ? null : <Banner variant={BannerType.RED} />}
        {isInlinePaddlePaymentActive && <CircleButton className={styles.backToPlansButton} onClick={handlePlanChange}>
            <ChevronLeft />
          </CircleButton>}
        <div ref={paymentSectionRef}>
          <ul className={styles.listPlans}>{renderSubscriptionPlans}</ul>

          <MoneyBack />

          {showPolicyAgreement && <PrivacyAgreement isChecked={subscriptionAgreementChecked} onChecked={handleCheck} />}

          <div className={styles.buttonWrapper}>
            {!isInlinePaddlePaymentActive ? <UiKitLoadingButton data-testid="get-my-plan" className={styles.paymentButton} fullWidth onClick={handlePlanSelect} disabled={!subscriptionAgreementChecked || isLoading} loading={isLoading}>
                <FormattedMessage id="Onboarding.Paywall.Main.ActionButton" defaultMessage="Get plan" />
              </UiKitLoadingButton> : <UiKitButton as="a" onClick={handlePlanChange}>
                Choose another plan
              </UiKitButton>}
          </div>
        </div>
        {isPlanHasPriceIntroOffer && !isPaddleVariants && <IntroOfferDisclaimer subscriptionPlan={subscriptionPlan} />}

        {subscriptionPlan?.hasTrial && <TrialsDisclaimer subscriptionPlan={subscriptionPlan} />}

        {isGuaranteeBlock && <GuaranteeBlock />}
      </>;
});