import { useCallback } from 'react';

import { useStores } from 'src/components/common/root-store-provider/root-store-provider';

export const useABTestExperiment = (name: string) => {
  const { analyticsStore } = useStores();
  const { abTests, getCaseNameByTestName } = analyticsStore;

  const getterCaseNameByTestName = useCallback(
    (testName: string) => getCaseNameByTestName(testName),
    [getCaseNameByTestName],
  );

  const caseNameByTestName = getterCaseNameByTestName(name);

  return {
    abTests,
    getCaseNameByTestName,
    caseNameByTestName,
  };
};
