import { useAtom } from 'jotai';
import React, { useCallback, useEffect, useState } from 'react';
import { activeVideoAtom } from '../atoms';
export const useVideoControl = (videoRef: React.RefObject<HTMLVideoElement>, defaultMuted: boolean, id: string) => {
  const [activeVideoId, setActiveVideoId] = useAtom(activeVideoAtom);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(defaultMuted);
  const togglePlay = useCallback(() => {
    const video = videoRef.current;
    if (!video) return;
    if (isPlaying) {
      video.pause();
    } else {
      setActiveVideoId(id);
      video.play();
    }
    setIsPlaying(!isPlaying);
  }, [isPlaying, videoRef, id, setActiveVideoId]);
  const toggleMute = () => {
    const video = videoRef.current;
    if (!video) return;
    video.muted = !isMuted;
    setIsMuted(!isMuted);
  };
  useEffect(() => {
    const video = videoRef.current;
    // If current video no longer active
    if (activeVideoId !== id && video) {
      video.pause(); // Stop prev video
      video.currentTime = 0; // Reset prev video progress
      setIsPlaying(false); // Update state of prev video
    }
  }, [activeVideoId, id, videoRef]);
  return {
    isPlaying,
    isMuted,
    togglePlay,
    toggleMute,
    setIsPlaying
  };
};