import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import { ComponentProps, useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import * as commonApi from 'src/api/common';
import { Image } from 'src/components/common/Image';
import AlertWithCloseButton from 'src/components/common/modal/alert-with-close-button/alert-with-close-button';
import FormModal from 'src/components/common/modal/form-modal/form-modal';
import { useStores } from 'src/components/common/root-store-provider/root-store-provider';
import { UiKitInput } from 'src/components/common/ui-kit-input';
import { useAbortableCallback } from 'src/utils/hooks/use-abortable';
import { useAnalytics } from '@features/Analytics';
import { FormattedMessage, TpMessageKeys, useIntl } from '@features/intl';
import styles from './contact-form-modal.module.scss';
const formSchema = yup.object().shape({
  email: yup.string().trim().required().email().matches(/^[\w%+.-]+@[\d.A-Za-z-]+\.[A-Za-z]{2,}$/),
  message: yup.string().trim().required()
});
const formInitialValues = {
  email: '',
  message: ''
};
export const ContactFormModal = observer((props: ComponentProps<typeof FormModal> & {
  placeLabel: string;
}) => {
  const {
    show,
    onHide,
    placeLabel
  } = props;
  const {
    trackGoogleEvent
  } = useAnalytics();
  const [isSubmittedAlertShown, setIsSubmittedAlertShown] = useState(false);
  const {
    authStore: {
      user
    }
  } = useStores();
  const intl = useIntl();
  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
    touched,
    errors,
    isValid,
    isSubmitting
  } = useFormik({
    initialValues: formInitialValues,
    validationSchema: formSchema,
    onSubmit: useAbortableCallback(async (abortSignal, values) => {
      trackGoogleEvent({
        eventName: 'support_send_tapped',
        options: {
          email: values.email,
          place: placeLabel
        }
      });
      await commonApi.sendFeedback({
        email: values.email,
        message: values.message,
        userId: user?.id,
        isSubscriber: user?.isSubscriptionActive,
        fromLabel: placeLabel
      });
      setIsSubmittedAlertShown(true);
      onHide?.();
    }, [onHide])
  });
  const formatPlaceholder = useCallback((placeholderId: TpMessageKeys, placeholderDefaultMessage: string) => intl.formatMessage({
    asString: true,
    id: placeholderId,
    defaultMessage: placeholderDefaultMessage
  }, {
    br: <br />
  }), [intl]);
  useEffect(() => {
    if (show) {
      resetForm();
      setIsSubmittedAlertShown(false);
      trackGoogleEvent({
        eventName: 'support_opened',
        options: {
          email: user?.email,
          place: placeLabel
        }
      });
    }
  }, [show, resetForm, trackGoogleEvent, placeLabel, user?.email]);
  return <>
        <FormModal title={<FormattedMessage id="Onboarding.Support.Title" defaultMessage="Support" />} description={<FormattedMessage id="Onboarding.Support.Subtitle" defaultMessage="Having an issue? Please write to us and we’ll help!" />} isValid={isValid} onSubmit={handleSubmit} isSubmitting={isSubmitting} submitButtonText={<FormattedMessage id="Onboarding.Support.Button" defaultMessage="Send" />} {...props}>
          <div className={styles.emailFormGroup}>
            <UiKitInput fullWidth type="email" autoComplete="email" placeholder={(formatPlaceholder(('Onboarding.Support.Email' as TpMessageKeys), 'Your email') as string)} required={true} name="email" value={values.email} onChange={handleChange} onBlur={handleBlur} isInvalid={touched.email && errors.email != null} errorText={(intl.formatMessage({
          asString: true,
          id: 'Onboarding.Support.Email.Error',
          defaultMessage: 'Email is a required field'
        }) as string)} errorTextClassName={styles.errorText} />
          </div>
          <div>
            <UiKitInput fullWidth className={styles.messageFormControl} as="textarea" placeholder={(formatPlaceholder(('Onboarding.Support.Message' as TpMessageKeys), 'Leave your request here') as string)} required={true} name="message" value={values.message} onChange={handleChange} onBlur={handleBlur} isInvalid={touched.message && errors.message != null} errorText={(intl.formatMessage({
          asString: true,
          id: 'Onboarding.Support.Message.Error',
          defaultMessage: 'Message is required field'
        }) as string)} errorTextClassName={styles.errorText} />
          </div>
        </FormModal>
        <AlertWithCloseButton show={isSubmittedAlertShown} onHide={() => setIsSubmittedAlertShown(false)} image={<Image src="victory-hand.png" width={80} height={80} alt="" />} title="Thanks" description="Your request has been sent. We will contact you shortly with an update." closeButtonText="Thank you" />
      </>;
});