import { memo, useMemo } from 'react';
import { currencyReplacer, useIsAutoRenewEnabled } from '@features/Payments';
import { FormattedMessage } from '@features/intl';
import { selectedPlanAtom } from '@features/Stripe/atoms';
import { useAtomValue } from 'jotai/index';
import styles from './check-details.module.scss';
export const CheckDetails = memo(({
  fullPriceDiscount,
  currency,
  billingPeriod,
  billingPeriodUnit,
  discountPercentage,
  fullPrice
}: {
  fullPriceDiscount: string | number;
  fullPrice: string | number;
  discountPercentage: number;
  currency: string;
  billingPeriod: number;
  billingPeriodUnit: string;
}) => {
  const isAutoRenewEnabled = useIsAutoRenewEnabled();
  const {
    introOfferData
  } = useAtomValue(selectedPlanAtom);
  const currencySymbol = useMemo(() => currencyReplacer(currency), [currency]);
  const priceGap = useMemo(() => Number(Number(fullPrice) - Number(fullPriceDiscount)).toFixed(2), [fullPrice, fullPriceDiscount]);
  const hasDiscount = useMemo(() => discountPercentage > 0, [discountPercentage]);
  const billingMemoized = useMemo(() => {
    if (isAutoRenewEnabled) {
      return `${introOfferData?.billingPeriod} ${introOfferData?.billingPeriodUnit}`;
    } else {
      return `${billingPeriod} ${billingPeriodUnit}`;
    }
  }, [billingPeriod, billingPeriodUnit, isAutoRenewEnabled, introOfferData?.billingPeriod, introOfferData?.billingPeriodUnit]);
  return <div className={styles.check}>
        <div className={styles.priceInfoBlock}>
          <span className={styles.checkItemText}>
            <FormattedMessage defaultMessage="Personalized plan" id="Onboarding.Checkout.Plan" />
          </span>
          <span className={styles.checkItemValue}>{`${currencySymbol}${fullPrice}`}</span>

          {hasDiscount && <>
              <span className={styles.checkItemText}>
                {discountPercentage}%{' '}
                <FormattedMessage defaultMessage="offer discount" id="Onboarding.Checkout.Discount" />
              </span>
              <span className={styles.checkItemValue}>{`-${currencySymbol}${priceGap}`}</span>
            </>}
        </div>

        <hr className={styles.currentPlanInfoLine} />

        <div className={styles.totalBlock}>
          <span className={styles.checkTotalText}>
            <FormattedMessage defaultMessage="Total" id="Onboarding.Checkout.Title2" />
          </span>
          <span className={styles.checkTotalValue}>{`${currencySymbol} ${fullPriceDiscount}`}</span>

          {hasDiscount && <>
              <span className={styles.checkItemText}>
                <FormattedMessage defaultMessage="per" id="Onboarding.Checkout.Period" />{' '}
                {billingMemoized}
              </span>
              <span className={styles.checkItemValue}>
                <FormattedMessage defaultMessage="You saved" id="Onboarding.Checkout.Saved" />{' '}
                {currencySymbol}
                {priceGap} ({discountPercentage}%{' '}
                <FormattedMessage defaultMessage="off)" id="Onboarding.Checkout.Off" />
              </span>
            </>}
        </div>
      </div>;
});
CheckDetails.displayName = 'CheckDetails';