export const FACEBOOK_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID;
export const FACEBOOK_PIXEL_ID_ADQUANTUM =
  process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID_ADQUANTUM;
export const FACEBOOK_PIXEL_ID_UNI =
  process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID_UNI;
export const FACEBOOK_PIXEL_ID_CPM_TEST =
  process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID_CPM_TEST;
export const FACEBOOK_PIXEL_ID_V17 =
  process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_V17_ID;
export const FACEBOOK_PIXEL_ID_V18 =
  process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_V18_ID;
export const GA_ID = process.env.NEXT_PUBLIC_GA_ID;
export const GA_ID_V2 = process.env.NEXT_PUBLIC_GA_ID_V2;
export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID;
export const PINTEREST_ID = process.env.NEXT_PUBLIC_PINTEREST_ID;
export const PINTEREST_ACCOUNT_ID =
  process.env.NEXT_PUBLIC_PINTEREST_ACCOUNT_ID;
export const TWITTER_ID = process.env.NEXT_PUBLIC_TWITTER_PIXEL_ID;
export const TIK_TOK_PIXEL = process.env.NEXT_PUBLIC_TIK_TOK_PIXEL_ID;
export const CLARITY_ID = process.env.NEXT_PUBLIC_CLARITY_ID;
export const CLARITY_V2_ID = process.env.NEXT_PUBLIC_CLARITY_V2_ID;

export const ANALYTICS_ENDPOINT = process.env
  .NEXT_PUBLIC_API_ANALYTICS_ENDPOINT as string;
export const ANALYTICS_ENDPOINT_V2 = process.env
  .NEXT_PUBLIC_API_ANALYTICS_ENDPOINT_V2 as string;

export const PINTEREST_EVENT_NAME_TO_RIGHT_CONVENTION = {
  AddToCart: 'add_to_cart',
  Checkout: 'checkout',
  Custom: 'custom',
  Lead: 'lead',
  PageVisit: 'page_visit',
  Search: 'search',
  Signup: 'signup',
  ViewCategory: 'view_category',
  WatchVideo: 'watch_video',
};

export const FACEBOOK_IDS = new Set([
  FACEBOOK_PIXEL_ID,
  FACEBOOK_PIXEL_ID_ADQUANTUM,
  FACEBOOK_PIXEL_ID_UNI,
  FACEBOOK_PIXEL_ID_CPM_TEST,
  FACEBOOK_PIXEL_ID_V17,
  FACEBOOK_PIXEL_ID_V18,
]);
