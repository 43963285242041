import * as Sentry from '@sentry/react';
import { ReactNode, memo } from 'react';
import LoadingIndicator from 'src/components/common/loading-indicator/loading-indicator';
import { useAnalytics } from '@features/Analytics';
import PaypalLogo from '../../../../../../assets/images/payment/paypal-logo.svg';
import styles from './paypal-button-stripe.module.scss';
type Props = {
  stripe: any;
  clientSecret: string;
  handleAddPaymentInfoEventsSend: ({
    paymentMethod
  }: {
    paymentMethod: string;
  }) => void;
  handleProcessingStatus: (status: boolean) => void;
  planId: string | number;
  buttonContent?: ReactNode;
};
export const PaypalButtonStripe = memo(({
  stripe,
  buttonContent = <PaypalLogo />,
  clientSecret,
  handleAddPaymentInfoEventsSend,
  handleProcessingStatus,
  planId
}: Props) => {
  const {
    trackGoogleEvent
  } = useAnalytics();
  const onConfirmPaypal = async () => {
    if (!stripe) {
      return;
    }
    handleAddPaymentInfoEventsSend({
      paymentMethod: 'paypal'
    });
    handleProcessingStatus(true);

    // Redirects away from the client
    const {
      error
    } = await stripe.confirmPayPalPayment(clientSecret, {
      return_url: `${window.location.origin}/new-plan-paywall?stripe_plan_id=${planId}`
    });
    if (error) {
      handleProcessingStatus(false);
      Sentry.captureException(error);
      trackGoogleEvent({
        eventName: 'payment_error',
        options: {
          errorCode: error?.code,
          errorDeclineCode: error?.decline_code,
          paymentMethod: 'paypal',
          paymentSystem: 'Stripe'
        }
      });
      console.error(error);
    }
  };
  return <div>
        <button className={styles.paypalButton} type="button" style={{
      opacity: !stripe ? 0.5 : 1
    }} disabled={!stripe} onClick={() => onConfirmPaypal()}>
          {!stripe ? <LoadingIndicator /> : buttonContent}
        </button>
      </div>;
});
PaypalButtonStripe.displayName = 'PaypalButtonStripe';