import { useMemo } from 'react';
import { useIsAutoRenewEnabled } from '@features/Payments';
import { TpCardPlanChildrenProps } from '@features/Paywall/types';
import styles from './billing-period.module.scss';
type TpBillingPeriodProps = {
  plan: TpCardPlanChildrenProps['plan'];
};
export const BillingPeriod = (props: TpBillingPeriodProps) => {
  const {
    plan
  } = props;
  const isAutoRenewEnabled = useIsAutoRenewEnabled();
  const isPlanWithIntroOffer = plan?.introOffer !== null;
  const billingPeriod = useMemo(() => isAutoRenewEnabled ? `${plan?.introOfferData?.billingPeriod} ${plan?.introOfferData?.billingPeriodUnit}` : isPlanWithIntroOffer ? `1 week` : `${plan.billingPeriod} ${plan.billingPeriodUnit}`, [isAutoRenewEnabled, isPlanWithIntroOffer, plan.billingPeriod, plan.billingPeriodUnit, plan?.introOfferData?.billingPeriod, plan?.introOfferData?.billingPeriodUnit]);
  return <span className={styles.perPeriod} data-sentry-component="BillingPeriod" data-sentry-source-file="index.tsx">{`per ${billingPeriod}`}</span>;
};