export const currencyReplacer = (currency: string) => {
  switch (currency) {
    case 'USD':
    case 'SGD':
    case 'HKD':
      return '$';
    case 'BRL':
      return 'R$';
    case 'MXN':
      return 'M$';
    case 'AUD':
      return 'A$';
    case 'CAD':
      return 'C$';
    case 'NZD':
      return 'NZ$';
    case 'EUR':
      return '€';
    case 'GBP':
      return '£';
    case 'RUB':
      return '₽';
    case 'CNY':
      return '¥';
    case 'JPY':
      return '¥';
    case 'PLN':
      return 'zł';
    case 'SEK':
      return 'kr';
    case 'ZAR':
      return 'R';
    case 'CZK':
      return 'Kč';
    case 'HUF':
      return 'Ft';
    case 'DKK':
      return 'kr';
    case 'INR':
      return '₹';
    case 'KRW':
      return '₩';
    case 'TWD':
      return 'NT$';
    case 'NOK':
      return 'kr';
    case 'ILS':
      return '₪';
    case 'THB':
      return '฿';
    case 'UAH':
      return '₴';
    default:
      return currency;
  }
};
