import { Elements } from '@stripe/react-stripe-js';
import classNames from 'classnames';
import { useAtomValue, useSetAtom } from 'jotai';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import CloseButton from 'src/components/common/close-button/close-button';
import Alert from 'src/components/common/modal/alert/alert';
import { introOfferInfoAtom, selectedPlanAtom, userAtom } from 'src/features/Stripe/atoms';
import { tikTokAdvancedMatching, tikTokEvent, useAnalytics } from '@features/Analytics';
import { useIsAutoRenewEnabled } from '@features/Payments';
import { ELEMENTS_APPEARANCE } from '@features/Stripe/constants';
import { useCreateSubscription, useGetStripePromise } from '@features/Stripe/hooks';
import { CheckoutForm } from '@features/Stripe/widgets/checkout-form';
import { CheckDetails } from '@features/Stripe/widgets/modal/components/check-details';
import { FormattedMessage } from '@features/intl';
import PaymentLoader from '../../../../assets/images/payment/payment-loader.svg';
import styles from './stripe-modal.module.scss';
export const StripeModal = memo((props: {
  show: boolean;
  onClose: () => void;
}) => {
  const {
    show,
    onClose
  } = props;
  const {
    trackFacebookEvent,
    trackGoogleEvent
  } = useAnalytics();
  const stripePromise = useGetStripePromise();
  const [isLoading, setIsLoading] = useState(true);
  const [clientSecret, setClientSecret] = useState('');
  const {
    billingPeriod,
    billingPeriodUnit,
    currency,
    fullPriceDiscount,
    discountPercentage,
    fullPrice,
    priceId,
    planName,
    introOffer,
    braintreePlanId,
    braintreeMerchantId,
    introOfferData
  } = useAtomValue(selectedPlanAtom);
  const {
    introOfferDuration: introOfferDurationFromStorage,
    introOfferId: introOfferIdFromStorage,
    fullPriceDiscount: introOfferPrice
  } = useAtomValue(introOfferInfoAtom);
  const {
    email,
    token,
    userId,
    campaign
  } = useAtomValue(userAtom);
  const setCustomerId = useSetAtom(userAtom);
  const isAutoRenewEnabled = useIsAutoRenewEnabled();
  const fullPriceMemoized = useMemo(() => {
    if (isAutoRenewEnabled) {
      return introOfferData?.fullPrice || 0;
    } else if (introOffer) {
      return introOfferPrice;
    } else {
      return fullPrice;
    }
  }, [fullPrice, introOffer, introOfferData?.fullPrice, introOfferPrice, isAutoRenewEnabled]);
  const fullPriceDiscountMemoized = useMemo(() => {
    if (isAutoRenewEnabled) {
      return introOfferData?.fullPriceDiscount || 0;
    } else if (introOffer) {
      return introOfferPrice;
    } else {
      return fullPriceDiscount;
    }
  }, [fullPriceDiscount, introOffer, introOfferData?.fullPriceDiscount, introOfferPrice, isAutoRenewEnabled]);
  const introOfferId = useMemo(() => {
    if (isAutoRenewEnabled) {
      return introOfferData?.introOfferId;
    } else if (introOffer) {
      return introOfferIdFromStorage;
    } else {
      return undefined;
    }
  }, [introOffer, introOfferData?.introOfferId, introOfferIdFromStorage, isAutoRenewEnabled]);
  const introOfferDuration = useMemo(() => {
    if (isAutoRenewEnabled) {
      return Number(introOfferData?.introOfferDuration);
    } else if (introOffer) {
      return Number(introOfferDurationFromStorage);
    } else {
      return undefined;
    }
  }, [introOffer, introOfferData?.introOfferDuration, introOfferDurationFromStorage, isAutoRenewEnabled]);
  const {
    mutateAsync: createSubscription
  } = useCreateSubscription();
  const handleLoading = useCallback((isLoading: boolean) => {
    setIsLoading(isLoading);
  }, []);
  const createCustomerAndSubscription = useCallback(async () => {
    const {
      client_secret,
      customer_id
    } = await createSubscription({
      priceId,
      currency,
      token,
      email,
      userId,
      campaign,
      introOfferId,
      introOfferDuration
    });
    setClientSecret(client_secret);
    setCustomerId(previous => ({
      ...previous,
      customerId: customer_id
    }));
  }, [campaign, createSubscription, currency, email, introOfferDuration, introOfferId, priceId, setCustomerId, token, userId]);
  const options = useMemo(() => ({
    clientSecret: clientSecret,
    appearance: ELEMENTS_APPEARANCE
  }), [clientSecret]);
  const closeHandle = useCallback(() => {
    onClose();
    setClientSecret('');
    setIsLoading(true);
    trackGoogleEvent({
      eventName: 'initiate_checkout_closed'
    });
  }, [onClose, trackGoogleEvent]);
  useEffect(() => {
    if (show) {
      createCustomerAndSubscription().then(r => r);
      trackFacebookEvent({
        eventName: 'InitiateCheckout',
        options: {
          email: email
        }
      });
      trackGoogleEvent({
        eventName: 'initiate_checkout'
      });
      tikTokAdvancedMatching(email);
      tikTokEvent('InitiateCheckout');
    }
  }, [createCustomerAndSubscription, email, show, trackFacebookEvent, trackGoogleEvent]);
  useEffect(() => () => {
    setClientSecret('');
    setIsLoading(true);
  }, []);
  useEffect(() => {
    if (!isLoading) {
      trackGoogleEvent({
        eventName: 'checkout_methods_loaded'
      });
    }
  }, [isLoading, trackGoogleEvent]);
  return <Alert className={styles.container} titleClassName={styles.modalTitle} backdropClassName={styles.backdrop} show={show}>
        <hr className={styles.line} />
        {!isLoading && <CloseButton className={styles.headerCloseButton} onClick={closeHandle} />}
        <div className={styles.content}>
          <h1 className={styles.title}>
            <FormattedMessage defaultMessage="Select your payment method" id="Onboarding.Checkout.Title" />
          </h1>
          <div className={classNames(isLoading && styles.hidePayments)}>
            <CheckDetails currency={currency} fullPriceDiscount={fullPriceDiscountMemoized} billingPeriod={billingPeriod} fullPrice={fullPriceMemoized} discountPercentage={discountPercentage} billingPeriodUnit={billingPeriodUnit} />
            <div className={styles.placeholder}>
              {clientSecret && stripePromise && <Elements options={options} stripe={stripePromise}>
                  <CheckoutForm handleLoading={handleLoading} clientSecret={clientSecret} userEmail={email} planInfo={{
              planName,
              fullPriceDiscount,
              currency,
              priceId,
              billingPeriod,
              introOffer,
              billingPeriodUnit,
              braintreePlanId,
              braintreeMerchantId
            }} />
                </Elements>}
            </div>
          </div>
          {isLoading && <div className={styles.loaderContainer}>
              <PaymentLoader className={styles.loader} />
              <FormattedMessage defaultMessage="Loading payment options…" id="Onboarding.Payment.Loader.Text" />
            </div>}
        </div>
      </Alert>;
});
StripeModal.displayName = 'StripeModal';