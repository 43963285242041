import { axiosMicroservice } from 'src/api/axios';

export const getUpgradeData = async ({
  fullPrice,
  upgradePrice,
}: {
  fullPrice: number;
  upgradePrice: number;
}): Promise<{
  surchargePerDay: number | null;
  surcharge: number;
  upgradePricePerMonth: number;
}> => {
  const { data } = await axiosMicroservice.post('stripe/get_upgrade_data', {
    full_price: fullPrice,
    upgrade_price: upgradePrice,
  });
  return data;
};
