import * as Sentry from '@sentry/react';
import axios from 'axios';

import {
  ENDPOINT,
  ENDPOINT_MICROSERVICE,
  ENDPOINT_SIMPLE,
} from 'src/constants/api';
import { isDevelopment } from 'src/utils';

import { ANALYTICS_ENDPOINT_V2 } from '@features/Analytics/constants';

const TIMEOUT = 30_000;

const createAxiosInstance = (baseURL: string) => {
  const instance = axios.create({
    baseURL,
    timeout: TIMEOUT,
    headers: { 'Content-type': 'application/json' },
  });

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      const status = error.response?.status;
      Sentry.withScope((scope) => {
        scope.setExtras({
          url: error.config.url,
          method: error.config.method,
          headers: error.config.headers,
          body: error.config.data,
          status,
          response: error.response?.data,
        });
        Sentry.captureException(error);
      });

      if (error.response?.status === 401) {
        window.location.href = '/';
      } else {
        console.log('error', error);
      }
    },
  );

  return instance;
};

const axiosSimple = createAxiosInstance(ENDPOINT_SIMPLE);
const axiosWeb = createAxiosInstance(ENDPOINT);
const axiosMicroservice = createAxiosInstance(ENDPOINT_MICROSERVICE);
const axiosAnalytics = createAxiosInstance(
  isDevelopment
    ? 'https://dev.analytics.mentalgrowth.app/api/v1/'
    : ANALYTICS_ENDPOINT_V2,
);
const axiosUtils = createAxiosInstance(
  'https://utils.mentalgrowth.app/api/v1/',
);

export { axiosSimple, axiosWeb, axiosMicroservice, axiosAnalytics, axiosUtils };
