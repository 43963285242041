import { useQuery } from '@tanstack/react-query';

import { getUpgradeData } from '@features/Stripe/api';

export const useGetUpgradeData = ({
  fullPrice,
  upgradePrice,
}: {
  fullPrice: number;
  upgradePrice: number;
}) =>
  useQuery({
    queryKey: ['getUpgradeData', upgradePrice, fullPrice],
    queryFn: () => getUpgradeData({ fullPrice, upgradePrice }),
    staleTime: 5 * 60 * 1000,
  });
