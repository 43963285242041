import { axiosMicroservice } from 'src/api/axios';
import type { TpStripeSubscriptionResponse } from 'src/features/Stripe/types';

export const getStripeSubscriptions = async ({
  productId,
}: {
  productId: string | undefined;
}): Promise<TpStripeSubscriptionResponse> => {
  const { data } = await axiosMicroservice.get(
    `stripe/get_plans?app=FaceYoga&product_id=${productId}`,
  );
  return data;
};
