import { useQuery } from '@tanstack/react-query';

import { TpStripeVariants } from 'src/constants/variants';

import { getStripeProductId } from '@features/Stripe/api';

export const useGetStripeProductId = ({
  variant,
}: {
  variant: TpStripeVariants;
}) =>
  useQuery({
    queryKey: ['stripe-product-id', variant],
    queryFn: () => getStripeProductId({ variant }),
  });
