import classNames from 'classnames';
import { ReactNode, useCallback, useEffect } from 'react';
import { ModalProps } from 'react-bootstrap';
import CloseButton from '../../../../components/common/close-button/close-button';
import MobileHeader from '../../mobile-header/mobile-header';
import ModalContainer from '../modal-container/modal-container';
import styles from './modal.module.scss';
export type TpModalProps = ModalProps & {
  showCloseButton?: boolean;
  showMobileHeader?: boolean;
  title?: ReactNode;
  description?: ReactNode;
  footer?: ReactNode;
  handleCloseModal?: () => void | null;
};
const Modal = ({
  className,
  backdropClassName,
  dialogClassName,
  showCloseButton = true,
  showMobileHeader = true,
  title,
  description,
  footer,
  children,
  handleCloseModal,
  ...props
}: TpModalProps) => {
  const {
    show,
    onHide,
    animation
  } = props;
  const handleClose = useCallback(() => {
    if (handleCloseModal) {
      handleCloseModal();
    }
    if (onHide) {
      onHide();
    }
  }, [handleCloseModal, onHide]);

  // Making the content outside the modal slide to the left.
  // The counter is necessary so that the transform value is restored only
  // once all the nested modals close.
  useEffect(() => {
    if (show && (animation == null || animation)) {
      const body = document.body;
      body.dataset.animatedModalsOpen = +(body.dataset.animatedModalsOpen ?? 0) + 1 + '';
      return () => {
        body.dataset.animatedModalsOpen = +body.dataset.animatedModalsOpen! - 1 + '';
      };
    }
    return undefined;
  }, [show, animation]);
  return <ModalContainer backdropClassName={classNames(styles.backdrop, backdropClassName)} className={classNames(styles.container, className)} dialogClassName={classNames(styles.modal, dialogClassName)} backdrop={!showCloseButton ? 'static' : undefined} {...props} data-sentry-element="ModalContainer" data-sentry-component="Modal" data-sentry-source-file="modal.tsx">
      {showMobileHeader && <MobileHeader className={styles.mobileHeader} titleClassName={styles.mobileHeaderTitle} title={title} right={showCloseButton && <CloseButton className={styles.headerCloseButton} onClick={handleClose} />} />}
      {showCloseButton && <CloseButton className={styles.closeButton} onClick={onHide} />}
      {title && <h2 className={styles.title}>{title}</h2>}
      {description && <p className={styles.description}>{description}</p>}
      {children}
      {footer && <div className={styles.footerContainer}>{footer}</div>}
    </ModalContainer>;
};
export default Modal;