import { PaymentRequest, Stripe } from '@stripe/stripe-js';
import { useAtomValue } from 'jotai/index';
import { useCallback, useEffect, useState } from 'react';
import { useIsAutoRenewEnabled } from '@features/Payments';
import { selectedPlanAtom } from '@features/Stripe/atoms';
export const usePaymentRequest = ({
  stripe,
  planInfo
}: {
  stripe: Stripe | null;
  planInfo: {
    currency: string;
    fullPriceDiscount: number;
    planName: string;
    priceId: string;
  };
}) => {
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(null);
  const [paymentMethodResult, setPaymentMethodResult] = useState<string | null | undefined>(null);
  const isAutoRenewEnabled = useIsAutoRenewEnabled();
  const {
    introOfferData
  } = useAtomValue(selectedPlanAtom);
  const createPaymentRequest = useCallback(({
    ignore
  }: {
    ignore: boolean;
  }) => {
    if (!stripe || planInfo.priceId.length === 0) {
      // We can't create a PaymentRequest until Stripe.js loads.
      return;
    }
    const pr = stripe.paymentRequest({
      country: 'US',
      currency: isAutoRenewEnabled ? introOfferData?.currency.toLowerCase() : planInfo.currency.toLowerCase(),
      total: {
        label: planInfo.planName,
        amount: Number.parseInt(isAutoRenewEnabled ? introOfferData.fullPriceDiscount.toString().replace('.', '') : planInfo.fullPriceDiscount.toString().replace('.', ''), 10)
      },
      disableWallets: ['link'],
      requestPayerName: false,
      requestPayerEmail: false
    });

    // Check the availability of the Payment Request API.
    pr.canMakePayment().then((result: any) => {
      if (result && !ignore) {
        setPaymentRequest(pr);
        setPaymentMethodResult(Object.keys(result).find(method => result[method]));
      }
    });
  }, [introOfferData?.currency, introOfferData.fullPriceDiscount, isAutoRenewEnabled, planInfo.currency, planInfo.fullPriceDiscount, planInfo.planName, planInfo.priceId.length, stripe]);
  useEffect(() => {
    if (!stripe) return; // We can't create a PaymentRequest until Stripe.js loads.

    const createRequest = () => {
      setPaymentMethodResult(null);
      setPaymentRequest(null);
      createPaymentRequest({
        ignore: false
      });
    };
    createRequest();
    return () => {
      createPaymentRequest({
        ignore: true
      });
    };
  }, [createPaymentRequest, stripe]);
  return {
    paymentRequest,
    paymentMethodResult
  };
};