import classNames from 'classnames';
import { ReactNode } from 'react';
import { FormattedMessage, TpMessageKeys } from 'src/features/intl';
import Star from '../../../assets/icons/star.svg';
import styles from './review-card.module.scss';
type Props = {
  avatar: ReactNode;
  name: string | {
    id: TpMessageKeys;
    defaultMessage: string;
  };
  date: string | {
    id: TpMessageKeys;
    defaultMessage: string;
  };
  review: string | {
    id: TpMessageKeys;
    defaultMessage: string;
  };
  rating?: number;
  className?: string;
};
const ReviewCard = ({
  avatar,
  name,
  date,
  review,
  rating = 5,
  className
}: Props) => <div className={classNames(styles.review, className)} data-sentry-component="ReviewCard" data-sentry-source-file="review-card.tsx">
    <div className={styles.headerReview}>
      <div className={styles.reviewCardAvatarContainer}>{avatar}</div>
      <div>
        <h4>
          <>
            {' '}
            {typeof name === 'object' ? <FormattedMessage defaultMessage={name.defaultMessage} id={name.id} /> : review}
          </>
        </h4>
        <ul className={styles.starsList}>
          {Array.from({
          length: rating
        }, (_, index) => <li key={index}>
              <Star />
            </li>)}
        </ul>
      </div>
      <h5>
        <>
          {' '}
          {typeof date === 'object' ? <FormattedMessage defaultMessage={date.defaultMessage} id={date.id} /> : review}
        </>
      </h5>
    </div>
    <p>
      {typeof review === 'object' ? <FormattedMessage defaultMessage={review.defaultMessage} id={review.id} /> : review}
    </p>
  </div>;
export default ReviewCard;