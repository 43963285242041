import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { clarity } from 'react-microsoft-clarity';
import { useStores } from 'src/components/common/root-store-provider/root-store-provider';
import { CLARITY_ID, CLARITY_V2_ID } from '../../constants';
export const Clarity = observer(({
  initSecondClarity
}: {
  initSecondClarity: boolean;
}) => {
  const {
    analyticsStore: {
      segment,
      abTests
    },
    authStore: {
      googlePseudoID
    }
  } = useStores();
  useEffect(() => {
    clarity.init(((initSecondClarity ? CLARITY_V2_ID : CLARITY_ID) as string));
  }, [initSecondClarity]);
  useEffect(() => {
    if (segment) {
      clarity.setTag('abTestSegment', segment);
    }
    const firstTest = abTests?.[0];
    if (firstTest) {
      const {
        test_name,
        case_name
      } = firstTest;
      if (test_name) clarity.setTag('abTestName', test_name);
      if (case_name) clarity.setTag('abTestCaseName', case_name);
    }
  }, [segment, abTests]);
  useEffect(() => {
    if (googlePseudoID) {
      clarity.identify('USER_ID', {
        googlePseudoID: googlePseudoID
      });
    }
  }, [googlePseudoID]);
  return null;
});