import { Dispatch, Reducer, useReducer } from 'react';
import { useEffect } from 'react';

export const useReducerWithStorage = <TpState, TpAction>(
  initialState: TpState,
  reducer: Reducer<TpState, TpAction>,
) => {
  const [modalState, dispatch] = useReducer(
    reducer,
    initialState,
    (): TpState => {
      const localData = localStorage.getItem('modalsState');
      return localData ? JSON.parse(localData) : initialState;
    },
  );

  useEffect(() => {
    localStorage.setItem('modalsState', JSON.stringify(modalState));
  }, [modalState]);

  return [modalState, dispatch] as [TpState, Dispatch<TpAction>];
};
