import { Image } from 'src/components/common/Image';
import { SlickSlider } from 'src/components/common/SlickSlider';
import { getImageEsp } from 'src/utils/get-image-esp';
import { FormattedMessage, TpMessageKeys } from '@features/intl';
import styles from './UpgradeSlider.module.scss';
const SLIDER_ITEMS: {
  title: string;
  titleId: TpMessageKeys;
  description: string;
  descriptionId: TpMessageKeys;
  imageName: string;
}[] = [{
  title: 'x1.5 to the result rate',
  titleId: 'Onboarding.Upgrade.Card1.Title',
  description: 'Intense Focus Area Challenge: Maximize Your Training',
  descriptionId: 'Onboarding.Upgrade.Card1.Subtitle',
  imageName: 'limited-1.webp'
}, {
  title: 'No additional charge',
  titleId: 'Onboarding.Upgrade.Card2.Title',
  description: 'Maintain lasting lifting results, without extra cost',
  descriptionId: 'Onboarding.Upgrade.Card2.Subtitle',
  imageName: 'limited-2.webp'
}, {
  title: 'Lifechanging result',
  titleId: 'Onboarding.Upgrade.Card3.Title',
  description: 'Build your habit and change your life in 5 minutes a day',
  descriptionId: 'Onboarding.Upgrade.Card3.Subtitle',
  imageName: `limited-3${getImageEsp()}.webp`
}, {
  title: 'Healthy programs',
  titleId: 'Onboarding.Upgrade.Card4.Title',
  description: 'Perfect Skin for Every Occasion: Travel, Work, Parties, and Beyond!',
  descriptionId: 'Onboarding.Upgrade.Card4.Subtitle',
  imageName: 'limited-4.webp'
}];
export const UpgradeSlider = () => <SlickSlider data-sentry-element="SlickSlider" data-sentry-component="UpgradeSlider" data-sentry-source-file="index.tsx">
    {SLIDER_ITEMS.map(({
    title,
    titleId,
    description,
    descriptionId,
    imageName
  }) => <div key={title} className={styles.item}>
          <Image width={327} height={132} src={imageName} alt={title} />
          <h3 className={styles.title}>
            <FormattedMessage defaultMessage={title} id={titleId} />
          </h3>
          <p className={styles.description}>
            {' '}
            <FormattedMessage defaultMessage={description} id={descriptionId} />{' '}
          </p>
        </div>)}
  </SlickSlider>;