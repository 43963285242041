import { observer } from 'mobx-react-lite';
import { ComponentProps, ReactNode, useCallback, useState } from 'react';
import { useStores } from 'src/components/common/root-store-provider/root-store-provider';
import { PaymentMethod } from 'src/models/payments-store/payments-store';
import type { SubscriptionPlan } from 'src/models/subscription-plan/subscription-plan';
import { useAnalytics } from '@features/Analytics';
import { AnalyticsPaymentMethod, PaymentSystem, handleCheckoutOpenRequestWithPaymentSystem, usePaymentSystem } from '@features/Payments';
import CreditCard from '../../../../assets/icons/credit-card.svg';
import PaymentButton from '../payment-button/payment-button';
const CardPaymentButton = observer(({
  subscriptionPlan,
  buttonContent = 'Debit / credit card',
  IconComponent = CreditCard,
  ...props
}: {
  buttonContent?: ReactNode;
  IconComponent?: ReactNode;
  subscriptionPlan: SubscriptionPlan;
} & Omit<ComponentProps<typeof PaymentButton>, 'children'>) => {
  const {
    paymentsStore,
    authStore
  } = useStores();
  const {
    user,
    variant
  } = authStore;
  const {
    trackPinterestEvent,
    trackFacebookEvent
  } = useAnalytics();
  let paymentSystem = PaymentSystem.BRAINTREE;
  const [isLoading, setIsLoading] = useState(false);
  const {
    contentNode,
    openCheckout
  } = usePaymentSystem({
    subscriptionPlan,
    paymentSystem,
    setIsLoading
  });
  const handleClick = useCallback(async () => {
    if (paymentSystem != null) {
      handleCheckoutOpenRequestWithPaymentSystem({
        subscriptionPlan,
        paymentMethod: AnalyticsPaymentMethod.CARD,
        paymentSystem,
        email: user!.email,
        variant: variant
      });
      trackFacebookEvent({
        eventName: 'AddPaymentInfo',
        customData: {
          paymentSystem: 'braintree',
          paymentMethod: 'card'
        },
        options: {
          email: user?.email
        }
      });
      trackPinterestEvent({
        eventName: 'AddToCart'
      });
    }
    paymentsStore.setPaymentMethod(PaymentMethod.CARD);
    await openCheckout();
  }, [openCheckout, paymentSystem, paymentsStore, subscriptionPlan, trackFacebookEvent, trackPinterestEvent, user, variant]);
  return <>
        <PaymentButton data-testid="pay-via-card" IconComponent={IconComponent} onClick={handleClick} loading={isLoading} {...props}>
          {buttonContent}
        </PaymentButton>
        {contentNode}
      </>;
});
export default CardPaymentButton;