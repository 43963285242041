import Cookies from 'js-cookie';

export type TpTwitterEventId =
  | 'tw-oehe8-oehen' //registration
  | 'tw-oehe8-oehek' //checkout
  | 'tw-oehe8-oehej'; //purchase

const externalId = Cookies.get('extId');
export const twitterEvent = (
  eventId: TpTwitterEventId,
  options: { [key: string]: any },
) => {
  // @ts-ignore
  window?.twq?.('event', eventId, { conversion_id: externalId, ...options });
};
