import classNames from 'classnames';
import React, { ComponentProps, HTMLProps, ReactNode, useCallback } from 'react';
import { UiKitLoadingButton } from 'src/components/common/ui-kit-loading-button';
import Modal from '../modal/modal';
export interface Props extends ComponentProps<typeof Modal>, Pick<HTMLProps<HTMLFormElement>, 'onSubmit'> {
  isValid?: boolean;
  isSubmitting?: boolean;
  submitButtonText?: ReactNode;
  submitButtonVariant?: string;
}
const FormModal = ({
  contentClassName,
  footer,
  isValid,
  onSubmit,
  isSubmitting,
  submitButtonText,
  submitButtonVariant,
  ...props
}: Props) => {
  const FormComponent = useCallback(({
    className,
    children,
    ...props
  }: React.FormHTMLAttributes<HTMLFormElement>) => <form className={classNames('modal-dialog', className)} onSubmit={onSubmit} {...props}>
        <div className={classNames('modal-content', contentClassName)}>
          {children}
        </div>
      </form>, [contentClassName, onSubmit]);
  return <Modal dialogAs={FormComponent} footer={<>
          {footer}
          <UiKitLoadingButton type="submit" disabled={!isValid} loading={isSubmitting} fullWidth
    // @ts-ignore
    variant={submitButtonVariant}>
            {submitButtonText}
          </UiKitLoadingButton>
        </>} {...props} data-sentry-element="Modal" data-sentry-component="FormModal" data-sentry-source-file="form-modal.tsx" />;
};
export default FormModal;