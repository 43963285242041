import classNames from 'classnames';
import { useMemo } from 'react';
import styles from './discount-percentage.module.scss';
export const DiscountPercentage = (props: {
  discountPercentage: number;
  isActive?: boolean;
  variant?: 'default' | 'outline' | 'outlineDisabled' | 'withText';
  size?: 'small' | 'medium';
  wasPaymentTried?: boolean;
}) => {
  const {
    discountPercentage,
    isActive,
    variant = 'default',
    size = 'medium',
    wasPaymentTried = false
  } = props;
  const variantClassNames = classNames({
    [styles.discountPercentageDefault]: variant === 'default',
    [styles.outline]: variant === 'outline',
    [styles.outlineDisabled]: variant === 'outlineDisabled',
    [styles.withText]: variant === 'withText',
    [styles.withTextDisabled]: variant === 'withText' && !isActive
  });
  const sizeClassNames = classNames({
    [styles.small]: size === 'small',
    [styles.medium]: size === 'medium'
  });
  const discountPercentageString = useMemo(() => `${variant === 'withText' ? 'Save' : '-'} ${discountPercentage}%`, [discountPercentage, variant]);
  return <div className={classNames(styles.discountPercentage, {
    [styles.discountPercentageActive]: isActive,
    [styles.discountPercentageActiveYellow]: wasPaymentTried
  }, variantClassNames, sizeClassNames)} data-sentry-component="DiscountPercentage" data-sentry-source-file="index.tsx">
      {discountPercentageString}
    </div>;
};