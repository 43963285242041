import type { SubscriptionPlan } from 'src/models/subscription-plan/subscription-plan';

import {
  googleAnalyticsEvent,
  tikTokAdvancedMatching,
  tikTokEvent,
} from '@features/Analytics';
import { twitterEvent } from '@features/Analytics/services/twitter-pixel';

import { PaymentSystem } from './use-payment-system';

export enum AnalyticsPaymentSystem {
  BRAINTREE = 'braintree',
}

export enum AnalyticsPaymentMethod {
  CARD = 'credit_card',
  APPLE_PAY = 'apple_pay',
  GOOGLE_PAY = 'google_pay',
  PAYPAL = 'paypal',
}

export type TpCheckoutPaymentRequest = {
  subscriptionPlan:
    | SubscriptionPlan
    | { fullPriceDiscount: number; id: string };
  paymentMethod: AnalyticsPaymentMethod;
  paymentSystem: PaymentSystem | AnalyticsPaymentSystem;
  email: string;
  variant: string | undefined;
};

export const handleCheckoutOpenRequest = (props: TpCheckoutPaymentRequest) => {
  const { subscriptionPlan, paymentMethod, email, variant } = props;

  twitterEvent('tw-oehe8-oehek', { email_address: email });

  tikTokAdvancedMatching(email);
  tikTokEvent('AddPaymentInfo', { content_id: subscriptionPlan.id });

  googleAnalyticsEvent({
    eventName: 'add_payment_info',
    properties: {
      value: subscriptionPlan.fullPriceDiscount,
      payment_system: 'braintree',
      payment_method: paymentMethod,
    },
  });
};

export const handleCheckoutOpenRequestWithPaymentSystem = (
  props: TpCheckoutPaymentRequest,
) => {
  const { subscriptionPlan, paymentMethod, email, variant } = props;
  let analyticsPaymentSystem: AnalyticsPaymentSystem =
    AnalyticsPaymentSystem.BRAINTREE;

  handleCheckoutOpenRequest({
    subscriptionPlan,
    paymentMethod,
    paymentSystem: analyticsPaymentSystem,
    email,
    variant,
  });
};
