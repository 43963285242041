import { ReactNode } from 'react';
import Slider from 'react-slick';
import styles from './SlickSlider.module.scss';
const sliderSettings = {
  dots: true,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1
};
type TpSlickSliderProps = {
  children: ReactNode;
};
export const SlickSlider = ({
  children
}: TpSlickSliderProps) => <Slider className={styles.sliderContainer} {...sliderSettings} data-sentry-element="Slider" data-sentry-component="SlickSlider" data-sentry-source-file="index.tsx">
    {children}
  </Slider>;