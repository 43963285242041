import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import { ComponentProps, ComponentType, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PageLoading from 'src/components/common/page-loading/page-loading';
import { useStores } from 'src/components/common/root-store-provider/root-store-provider';
import page from 'src/components/pages/page/page';
import { PaymentMethod } from 'src/models/payments-store/payments-store';
import type { SubscriptionPlan } from 'src/models/subscription-plan/subscription-plan';
import { usePrevious } from 'src/utils/hooks/use-previous';
import { useAnalytics } from '@features/Analytics';
import { AnalyticsPaymentMethod, PaymentSystem, handleCheckoutOpenRequestWithPaymentSystem, usePaymentSystem } from '@features/Payments';
import * as auth from '../../../../api/auth';
import ApplePayLogo from '../../../../assets/images/payment/apple-pay-logo.svg';
import PaymentButton from '../payment-button/payment-button';
const SAFARI_AUTH_TOKEN_QUERY_PARAM = 'safari_auth_token';
const useSafariAuthToken = () => {
  const {
    query
  } = useRouter();
  const safariAuthTokenQueryValue = query[SAFARI_AUTH_TOKEN_QUERY_PARAM];
  return typeof safariAuthTokenQueryValue === 'string' ? safariAuthTokenQueryValue : undefined;
};
const IS_APPLE_PAY_AVAILABLE = (() => {
  try {
    return ApplePaySession.supportsVersion(3) && ApplePaySession.canMakePayments();
  } catch {
    return false;
  }
})();
const IS_IN_WEB_VIEW = typeof navigator !== 'undefined' && navigator.vendor === 'Apple Computer, Inc.' && /FBA[NV]|Instagram/.test(navigator.userAgent);
const IS_IN_WEB_VIEW_AND_IOS_LOWER_16 = () => {
  if (IS_IN_WEB_VIEW) {
    const array = [...navigator.userAgent.split('iOS')[1]];
    const [first, second, third] = array;
    const version = first + second + third;
    return +version < 16;
  } else {
    return false;
  }
};
export const SHOW_APPLE_PAY_BUTTON = IS_APPLE_PAY_AVAILABLE || IS_IN_WEB_VIEW;
export const useApplePayPaymentButton = () => {
  const safariAuthToken = useSafariAuthToken();
  const showSafariRedirectPrompt = IS_IN_WEB_VIEW && safariAuthToken != null;
  return {
    showSafariRedirectPrompt
  };
};
export const applePayPaymentButtonPage = (Component: ComponentType<{
  openApplePayCheckoutOnMount: boolean;
}>) => page(observer(() => {
  const {
    authStore
  } = useStores();
  const {
    user
  } = authStore;
  const router = useRouter();
  const {
    isReady
  } = router;
  const safariAuthToken = useSafariAuthToken();
  const performLogin = !IS_IN_WEB_VIEW && user === null && safariAuthToken != null;
  const {
    trackGoogleEvent
  } = useAnalytics();

  // biome-ignore lint/correctness/useExhaustiveDependencies: router is not changing https://github.com/vercel/next.js/issues/18127
  useEffect(() => {
    if (!performLogin) {
      return;
    }
    trackGoogleEvent({
      eventName: 'purchase_open_browser_tapped'
    });
    (async () => {
      await authStore.loginBySafariAuthToken({
        safariAuthToken: safariAuthToken!
      });
      const {
        query,
        pathname
      } = router;
      const newQuery = {
        ...query
      };
      delete newQuery[SAFARI_AUTH_TOKEN_QUERY_PARAM];
      await router.replace({
        pathname,
        query: newQuery
      }, undefined, {
        shallow: true
      });
    })();
  }, [authStore, safariAuthToken, performLogin]);
  const [openCheckoutOnMount, setOpenCheckoutOnMount] = useState(false);
  useEffect(() => {
    if (performLogin) {
      setOpenCheckoutOnMount(true);
    }
  }, [performLogin]);
  return !isReady || performLogin ? <PageLoading /> : <Component openApplePayCheckoutOnMount={openCheckoutOnMount} />;
}));
const ApplePayPaymentButton = observer(({
  subscriptionPlan,
  openCheckoutOnMount,
  onLoadEnd,
  buttonContent = <ApplePayLogo />,
  ...props
}: {
  subscriptionPlan: SubscriptionPlan;
  openCheckoutOnMount: boolean;
  onLoadEnd: () => void;
  buttonContent?: ReactNode;
} & Omit<ComponentProps<typeof PaymentButton>, 'children'>) => {
  const {
    paymentsStore,
    authStore
  } = useStores();
  const {
    user,
    variant,
    campaign,
    googlePseudoID
  } = authStore;
  const {
    trackFacebookEvent,
    trackPinterestEvent
  } = useAnalytics();
  let paymentSystem = PaymentSystem.BRAINTREE;
  const previousPlan: any = usePrevious({
    subscriptionPlan
  });
  const shouldFetch = useMemo(() => previousPlan ? previousPlan.subscriptionPlan !== subscriptionPlan : true, [previousPlan, subscriptionPlan]);
  const [isLoading, setIsLoading] = useState(false);
  const {
    contentNode,
    openCheckout,
    openCheckoutWithoutGesture
  } = usePaymentSystem({
    subscriptionPlan,
    paymentSystem,
    setIsLoading,
    onLoadEnd,
    braintreeOptions: {
      useApplePay: true
    },
    shouldFetch: shouldFetch
  });
  const checkoutOnMountOpened = useRef(false);
  useEffect(() => {
    if (openCheckoutOnMount && paymentSystem != null && !checkoutOnMountOpened.current) {
      // noinspection JSIgnoredPromiseFromCall
      openCheckoutWithoutGesture();
      checkoutOnMountOpened.current = true;
    }
  }, [openCheckoutOnMount, paymentSystem, openCheckoutWithoutGesture]);
  const router = useRouter();
  const handleClick = useCallback(async () => {
    if (IS_IN_WEB_VIEW_AND_IOS_LOWER_16()) {
      setIsLoading(true);
      try {
        const safariAuthToken = await auth.getSafariAuthToken();
        const {
          query,
          pathname
        } = router;
        await router.push({
          pathname,
          query: {
            ...query,
            [SAFARI_AUTH_TOKEN_QUERY_PARAM]: safariAuthToken,
            ['variant']: variant,
            ['utm_campaign']: campaign,
            ['pseudoId']: googlePseudoID
          }
        });
      } finally {
        setIsLoading(false);
      }
      return;
    }
    if (paymentSystem != null) {
      handleCheckoutOpenRequestWithPaymentSystem({
        subscriptionPlan,
        paymentMethod: AnalyticsPaymentMethod.APPLE_PAY,
        paymentSystem,
        email: user!.email,
        variant: variant
      });
      trackFacebookEvent({
        eventName: 'AddPaymentInfo',
        customData: {
          paymentSystem: 'braintree',
          paymentMethod: 'applePay'
        },
        options: {
          email: user?.email
        }
      });
      trackPinterestEvent({
        eventName: 'AddToCart'
      });
    }
    paymentsStore.setPaymentMethod(PaymentMethod.APPLE_PAY);
    await openCheckout();
  }, [campaign, googlePseudoID, openCheckout, paymentSystem, paymentsStore, router, subscriptionPlan, trackFacebookEvent, trackPinterestEvent, user, variant]);
  return <>
        <PaymentButton onClick={handleClick} loading={isLoading} {...props} style={{
      pointerEvents: props.disabled ? 'none' : 'initial',
      opacity: props.disabled ? 0.7 : 1
    }}>
          {buttonContent}
        </PaymentButton>
        {contentNode}
      </>;
});
export default ApplePayPaymentButton;