import classNames from 'classnames';
import { memo } from 'react';
import { UiKitCheckbox } from 'src/components/common/ui-kit-checkbox';
import { PolicyLink, RefundLink, TermsLink } from 'src/widgets/policy';
import { FormattedMessage } from '@features/intl';
import styles from './privacy-agreement.module.scss';
export const PrivacyAgreement = memo(({
  isChecked,
  onChecked
}: {
  isChecked: boolean;
  onChecked: () => void;
}) => <div className={styles.subscriptionAgreement}>
      <UiKitCheckbox checked={isChecked} label={<FormattedMessage id="Onboarding.Paywall.Main.Terms" defaultMessage="By purchasing, you agree to our {privacy}, {terms}, {mark} {subscription}" values={{
    privacy: <PolicyLink place="paywall" />,
    terms: <TermsLink place="paywall" />,
    subscription: <RefundLink place="paywall" />,
    mark: <span>
                  <FormattedMessage defaultMessage="and" id="Onboarding.Paywall.Main.Terms.Mark" />
                </span>
  }} />} labelClassName={classNames(!isChecked && styles.dangerAgreement)} value="subscriptionAgreement" onChange={onChecked} id="subscriptionAgreement" />
    </div>);
PrivacyAgreement.displayName = 'PrivacyAgreement';