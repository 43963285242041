import { axiosMicroservice } from 'src/api/axios';

export const generateBraintreeClientToken = async ({
  customerId,
  customerEmail,
  merchantAccountId,
}: {
  customerId: string | number;
  customerEmail: string;
  merchantAccountId: string | number;
}): Promise<string> => {
  const { data } = await axiosMicroservice.post(
    'braintree_generate_client_token',
    {
      app: 'FaceYoga',
      user_id: customerId,
      email: customerEmail,
      merchant_account_id: merchantAccountId,
    },
  );
  return data;
};
