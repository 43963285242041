import { TpMessageKeys } from '@features/intl';

export const LEARN_MORE_VARIANT: {
  name: string;
  learnMoreList: {
    icon: string;
    titleId?: TpMessageKeys;
    titleDefaultMessage?: string;
  }[];
}[] = [
  {
    name: '1 MONTH',
    learnMoreList: [
      {
        icon: 'learn-more-2.1.webp',
        titleId: 'Onboarding.Paywall.Main.Card.Bullet1',
        titleDefaultMessage: 'Instant access to your plan',
      },
      {
        icon: 'learn-more-2.0.png',
        titleId: 'Onboarding.Paywall.Main.Card.Bullet2',
        titleDefaultMessage: '27k+ 5-star reviews',
      },
      {
        icon: 'learn-more-2.2.webp',
        titleId: 'Onboarding.Paywall.Main.Card.Bullet3',
        titleDefaultMessage: '24/7 personal customer support',
      },
      {
        icon: 'learn-more-2.3.webp',
        titleId: 'Onboarding.Paywall.Main.Card.Bullet4',
        titleDefaultMessage: '30 day Money-Back guarantee',
      },
    ],
  },
];

export const NO_MONEY_LEARN_MORE = [
  {
    icon: 'learn-more-2.1.webp',
    text: 'Personal goal-oriented program',
  },
  {
    icon: 'learn-more-2.0.png',
    text: '27k + 5-star reviews',
  },
  {
    icon: 'learn-more-2.2.webp',
    text: 'Progress tracking feature',
  },
  {
    icon: 'learn-more-2.3.webp',
    text: '30-day Money-Back guarantee',
  },
];
