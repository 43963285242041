import { DependencyList, useCallback, useEffect, useRef } from 'react';

export const useAbortableEffect = (
  effect: (abortSignal: AbortSignal) => void | (() => void),
  deps?: DependencyList,
): void => {
  // biome-ignore lint/correctness/useExhaustiveDependencies: effect depends on deps
  useEffect(() => {
    const abortController = new AbortController();
    const cleanup = effect(abortController.signal);
    return () => {
      abortController.abort();
      cleanup?.();
    };
  }, deps);
};

export const useAbortableCallback = <A extends any[], R>(
  callback: (abortSignal: AbortSignal, ...args: A) => R,
  deps: DependencyList,
): ((...args: A) => R) => {
  const abortController = useRef<AbortController | undefined>();
  const abort = () => {
    abortController.current?.abort();
    abortController.current = undefined;
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: abort depends on deps
  useEffect(() => abort, deps);

  // biome-ignore lint/correctness/useExhaustiveDependencies: callback depends
  return useCallback((...args) => {
    abort();
    abortController.current = new AbortController();
    return callback(abortController.current.signal, ...args);
  }, deps);
};
