import type { TpButtonProps } from '@mentalgrowth/ui-kit-web';
import classNames from 'classnames';
import { forwardRef } from 'react';
import { UiKitButton } from 'src/components/common/ui-kit-button';
import styles from './circle-button.module.scss';
const CircleButton = forwardRef(({
  className,
  ...props
}: TpButtonProps, ref) => <UiKitButton
// @ts-ignore
ref={ref} className={classNames(styles.container, className)} color="pure" size="sm" {...props} />);
CircleButton.displayName = 'CircleButton';
export default CircleButton;