import { Stripe } from '@stripe/stripe-js';
import classNames from 'classnames';
import { ReactNode, memo, useEffect, useMemo } from 'react';
import { CURRENCY_DOES_NOT_SUPPORT_PAYPAL } from '@features/Stripe/constants';
import { CardPaymentBlock } from '@features/Stripe/widgets/checkout-form/widgets/card-payment-block';
import { PaymentRequestButton } from '@features/Stripe/widgets/checkout-form/widgets/payment-request-button';
import { PaypalButtonBraintree } from '@features/Stripe/widgets/checkout-form/widgets/paypal-button-braintree';
import { PaypalButtonStripe } from '@features/Stripe/widgets/checkout-form/widgets/paypal-button-stripe';
import styles from './payment-option-tab.module.scss';
interface TpPaymentOptionProps {
  paymentMethodResult: string | null | undefined;
  paymentMethodName: 'PayPal' | 'Google Pay' | 'Apple Pay' | 'Credit card';
  paymentMethodLogo: ReactNode;
  paymentMethodContentList?: string[];
  paymentButtonText?: string;
  stripe: Stripe | null;
  clientSecret: string;
  userEmail: string;
  sendAddPaymentInfoEvents: ({
    paymentMethod
  }: {
    paymentMethod: string;
  }) => void;
  handleProcessingStatus: (status: boolean) => void;
  onSuccess: ({
    paymentMethod,
    paymentSystem
  }: {
    paymentMethod: string;
    paymentSystem?: 'stripe' | 'braintree';
  }) => Promise<void>;
  planInfo: {
    priceId: string;
    planName: string;
    fullPriceDiscount: number;
    billingPeriod: number;
    billingPeriodUnit: string;
    introOffer?: string;
    currency: string;
    braintreePlanId?: string;
    braintreeMerchantId?: string;
  };
  selected: boolean;
  onSelectPaymentMethod: (paymentMethod: 'PayPal' | 'Google Pay' | 'Apple Pay' | 'Credit card') => void;
}
export const MappedPaymentMethodComponent = memo(({
  paymentMethodName,
  stripe,
  clientSecret,
  userEmail,
  sendAddPaymentInfoEvents,
  handleProcessingStatus,
  onSuccess,
  onLoadEnd,
  planInfo
}: Omit<TpPaymentOptionProps, 'selected' | 'onSelectPaymentMethod' | 'paymentMethodLogo' | 'paymentMethodResult'> & {
  onLoadEnd?: () => void;
}) => {
  const isBraintreePaypal = typeof planInfo.braintreePlanId === 'string' && planInfo.braintreePlanId.length > 0 && planInfo.braintreeMerchantId !== undefined;
  const isCurrencySupportPayPal = useMemo(() => !CURRENCY_DOES_NOT_SUPPORT_PAYPAL.includes(planInfo.currency), [planInfo.currency]);
  useEffect(() => {
    if (!isBraintreePaypal || !isCurrencySupportPayPal) {
      onLoadEnd && onLoadEnd();
    }
  }, [isBraintreePaypal, isCurrencySupportPayPal, onLoadEnd]);
  if (paymentMethodName === 'Credit card') {
    return <CardPaymentBlock minimalStyle stripe={stripe} clientSecret={clientSecret} userEmail={userEmail} handleAddPaymentInfoEventsSend={sendAddPaymentInfoEvents} handleProcessingStatus={handleProcessingStatus} handleSuccessPayment={onSuccess} />;
  }
  if (paymentMethodName === 'Google Pay' || paymentMethodName === 'Apple Pay') {
    return <PaymentRequestButton height={64} borderRadius={16} clientSecret={clientSecret} stripe={stripe} handleAddPaymentInfoEventsSend={sendAddPaymentInfoEvents} handleSuccessPayment={onSuccess} planInfo={planInfo} />;
  }
  if (paymentMethodName === 'PayPal' && isCurrencySupportPayPal) {
    return isBraintreePaypal ? <PaypalButtonBraintree handleAddPaymentInfoEventsSend={sendAddPaymentInfoEvents} buttonContent="Confirm purchase" isCustomButton className={styles.paypalButton} onSuccess={onSuccess} onLoadEnd={() => onLoadEnd && onLoadEnd()} subscriptionPlan={{
      braintreePlanId: planInfo.braintreePlanId!,
      merchantAccountId: planInfo.braintreeMerchantId!,
      fullPriceDiscount: planInfo.fullPriceDiscount,
      id: (planInfo.priceId as string)
    }} /> : <PaypalButtonStripe stripe={stripe} buttonContent="Confirm purchase" planId={planInfo.priceId} handleAddPaymentInfoEventsSend={sendAddPaymentInfoEvents} handleProcessingStatus={handleProcessingStatus} clientSecret={clientSecret} />;
  }
  return null;
});
MappedPaymentMethodComponent.displayName = 'MappedPaymentMethodComponent';
export const PaymentOptionTab = (props: TpPaymentOptionProps) => {
  const {
    paymentMethodContentList,
    paymentMethodResult,
    paymentMethodLogo,
    paymentMethodName,
    paymentButtonText,
    stripe,
    clientSecret,
    userEmail,
    sendAddPaymentInfoEvents,
    handleProcessingStatus,
    onSuccess,
    planInfo,
    selected,
    onSelectPaymentMethod
  } = props;
  if (paymentMethodName === 'Google Pay' && paymentMethodResult !== 'googlePay' || paymentMethodName === 'Apple Pay' && paymentMethodResult !== 'applePay') {
    return null;
  }
  return <div className={styles.paymentMethodOption_layout} data-sentry-component="PaymentOptionTab" data-sentry-source-file="index.tsx">
      <div onClick={() => onSelectPaymentMethod(paymentMethodName)} className={classNames(styles.paymentMethodOption_title, selected && styles.paymentMethodOption_title_selected)}>
        {paymentMethodName}
        {paymentMethodLogo}
      </div>

      <div className={classNames(styles.paymentMethod_content, selected && styles.paymentMethod_content_selected)}>
        <div style={{
        minHeight: 0
      }}>
          {paymentMethodContentList && <ul className={styles.paymentMethod_contentList}>
              {paymentMethodContentList.map(item => <li className={styles.paymentMethodOption_contentItem} key={item}>
                  {item}
                </li>)}
            </ul>}

          <div>
            <MappedPaymentMethodComponent paymentMethodName={paymentMethodName} stripe={stripe} clientSecret={clientSecret} userEmail={userEmail} sendAddPaymentInfoEvents={sendAddPaymentInfoEvents} handleProcessingStatus={handleProcessingStatus} onSuccess={onSuccess} planInfo={planInfo} data-sentry-element="MappedPaymentMethodComponent" data-sentry-source-file="index.tsx" />
          </div>
        </div>
      </div>
    </div>;
};