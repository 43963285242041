import {
  Instance,
  SnapshotOut,
  applySnapshot,
  flow,
  getRoot,
  types,
} from 'mobx-state-tree';

import * as auth from 'src/api/auth';
import { ALL_VARIANTS, TpVariantObject } from 'src/constants/variants';
import { User, UserModel } from 'src/models/user/user';
import { groupBy } from 'src/utils';

export const AuthStoreModel = types
  .model('AuthStore')
  .props({
    user: types.maybe(types.union(UserModel, types.null)),
    auth_token: types.maybe(types.string),
    access_code: types.maybe(types.string),
    fbp: types.maybe(types.union(types.string, types.undefined)),
    fbc: types.maybe(types.union(types.string, types.undefined)),
    variant: types.maybe(types.string),
    campaign: types.maybe(types.union(types.string, types.undefined)),
    googlePseudoID: types.maybe(types.string),
    loginedByWinback: false,
    firstName: types.maybe(types.string),
    lastName: types.maybe(types.string),
    dateOfBirth: types.maybe(types.string),
    gender: types.maybe(types.string),
    paddlePaymentProcessed: types.maybe(types.boolean),
    registeredFromBonus: false,
  })

  .actions((self) => ({
    setUser: (user: User | null) => {
      if (!self.user || user === null) {
        self.user = user ? UserModel.create(user) : user;
      } else {
        applySnapshot(self.user, user);
      }
    },
    setAuthToken: (token: string) => {
      self.auth_token = token;
    },
    setRegisteredFromBonus: (value: boolean) => {
      self.registeredFromBonus = value;
    },
    setAccessCode: (code: string) => {
      self.access_code = code;
    },
    setFbp: (fbp: string | undefined) => {
      self.fbp = fbp;
    },
    setFbc: (fbc: string | undefined) => {
      self.fbc = fbc;
    },
    setVariant: (variant: string | undefined) => {
      self.variant = variant;
    },
    setCampaign: (campaign: string | undefined) => {
      self.campaign = campaign;
    },
    setGooglePseudoID: (code: string) => {
      self.googlePseudoID = code;
    },
    setLoginedByWinback: (value: boolean) => {
      self.loginedByWinback = value;
    },
    setPaddlePaymentProcessed: (value: boolean | undefined) => {
      self.paddlePaymentProcessed = value;
    },
  }))
  .actions((self) => ({
    fetchUser: flow(function* () {
      const user = yield auth.fetchUser(self.auth_token);
      self.setUser(user);
    }),
    register: flow(function* ({
      email,
      age,
      abortSignal,
    }: {
      email: string;
      age: number;
      abortSignal?: AbortSignal;
    }) {
      const { quizStore } = getRoot<any>(self);
      const user = yield auth.register({
        email,
        age,
        variant: self.variant,
        abortSignal,
        quizAnswers: quizStore,
      });
      self.setAccessCode(user.accessCode);
      self.setAuthToken(user.token);
    }),
    setPasswordAndEmail: flow(function* ({
      email,
      password,
      abortSignal,
    }: {
      email: string;
      password: string;
      abortSignal?: AbortSignal;
    }) {
      yield auth.setPasswordAndEmail({ email, password, abortSignal });
      const user = self.user as User;
      user.setEmail(email);
      user.setPasswordSet();
    }),
    logOut: flow(function* () {
      if (self.auth_token) {
        yield auth.logOut();
      }
      self.setUser(null);
      self.setAuthToken('');
      self.setAccessCode('');
      self.setGooglePseudoID('');
      self.setLoginedByWinback(false);
      self.setRegisteredFromBonus(false);
      self.setPaddlePaymentProcessed(undefined);
    }),
    loginBySafariAuthToken: flow(function* ({
      safariAuthToken,
    }: {
      safariAuthToken: string;
    }) {
      const user = yield auth.loginBySafariAuthToken({ safariAuthToken });
      self.setUser(user);
      self.setAccessCode(user.accessCode);
      self.setAuthToken(user.token);
    }),
    loginByEmailToken: flow(function* ({
      emailToken,
      abortSignal,
    }: {
      emailToken: string;
      abortSignal?: AbortSignal;
    }) {
      const user = yield auth.loginWithEmailToken({ emailToken, abortSignal });
      self.setUser(user);
      user.access_code && self.setAccessCode(user.access_code);
      self.setAuthToken(user.token);
    }),
  }))
  .views((self) => ({
    get isPaddleVariants() {
      //There are no paddle variants for now
      return false;
    },
    get isStripeVariant() {
      return (
        // check if variant exist in stripe variants
        groupBy(ALL_VARIANTS, 'payment_system')['stripe'].some(
          (item: TpVariantObject) => item.variant_name === self.variant,
        ) ||
        //for case without variant
        self.variant === undefined ||
        // for missing variants
        !ALL_VARIANTS.some((variant) => variant.variant_name === self.variant)
      );
    },
    get isBraintreeVariant() {
      return groupBy(ALL_VARIANTS, 'payment_system')['braintree'].some(
        (item: TpVariantObject) => item.variant_name === self.variant,
      );
    },
    get isTwitterSource() {
      return groupBy(ALL_VARIANTS, 'source')['twitter'].some(
        (item: TpVariantObject) => item.variant_name === self.variant,
      );
    },
  }));

type AuthStoreType = Instance<typeof AuthStoreModel>;

export interface AuthStore extends AuthStoreType {}

type AuthStoreSnapshotType = SnapshotOut<typeof AuthStoreModel>;

export interface AuthStoreSnapshot extends AuthStoreSnapshotType {}
