import { ReactNode } from 'react';

import type { TpMessageKeys } from 'src/features/intl';

export enum QuizStepType {
  OTHER,
  TEXT_CARD,
  SINGLE_OPTION_SELECT,
  SINGLE_OPTION_WITH_IMAGE_SELECT,
  MULTIPLE_OPTIONS_SELECT,
  MULTIPLE_OPTIONS_WITH_IMAGE_SELECT,
  REVIEWS,
  ACHIEVE_RESULTS,
}

export type QuizSection =
  | 'Goals'
  | 'Lifestyle'
  | 'Nutrition'
  | 'Your skin'
  | 'Feelings'
  | 'Final Screens'
  | 'SingleSection';

export enum QuizStep {
  WELCOME_LOADER,
  WELCOME_LOADER_SECOND,
  EASILY_SPEND,
  SATISFIED, // ?? in use ??
  WORRIES_MOST,
  FOCUS_ON,
  FOCUS_ON_TWO,
  AGE_CHANGES,
  WRINKLES,
  CHANGES_FACED,
  PRACTICE_CARE,
  SUIT_SCHEDULE_MORNING,
  SUIT_SCHEDULE_EVENING,
  BAD_HABITS,
  EAT_HABITS,
  SUGAR_HABITS,
  SODIUM_HABITS,
  VEGETABLES_HABITS,
  WATER_HABITS,
  SWELL_HABITS,
  SLEEP_HABITS,
  SKIN_TYPE,
  OILY_SHINE,
  AFTER_CLEAN_FACE,
  SKIN_ACNE,
  SKIN_TEXTURE,
  SKIN_COLOR,
  SKIN_PROBLEMS_WHITE_SIMPLE,
  SKIN_PROBLEMS_BLACK_SIMPLE,
  STRESS,
  SKIN_RECOMMEND,
  FIRST_CHANGES,
  AGE,
  FACE_SHAPE,
  JAWLINE,
  UNHAPPY,
  PLASTIC_SURGERY,
  DARK_CIRCLES,
  SKIN_SUMMARY,
  LIFESTYLE,
  WORK,
  AFRAID_LOSE_BEAUTY,
  AFRAID_LOSE_BEAUTY_BREAKS_TEST,
  APPEARANCE_REASON,
  APPEARANCE_REASON_BREAKS_TEST,
  TRENDS,
  HEAR_ABOUT_US,
  ACHIEVE_RESULTS,
  EXPECT,
  EXPECT_DYNAMIC,
  ENTER_EMAIL,
  MILLION_USERS,
  CHANGES_WORRIES_MOST,
  MODEL,
  RADIAL_PROGRESS,
  GOALS_TIMELINE,
  INNER_AGE,
  SUIT_SCHEDULE_EVENING_MAP,
  SUIT_SCHEDULE_MORNING_MAP,
  NUTRITION_OPENER,
  NUTRITION_SUMMARY,
  AGE_SIMPLE,
  WE_STUDIED_SIMPLE,
  PERSONALISED_PROGRAMS_SIMPLE,
  DEVELOPED_SYSTEM_SIMPLE,
  EASILY_SPEND_SIMPLE,
  EXCESSIVE_WEIGHT_SIMPLE,
  FACIAL_CARE_SIMPLE,
  DESIRED_RESULTS_SIMPLE,
  MEDICATIONS_SIMPLE,
  PREGNANT_SIMPLE,
  BAD_HABITS_SIMPLE,
  SKIN_CARE_SIMPLE,
  KIDS_SIMPLE,
  WORK_FROM_HOME_SIMPLE,
  IMPROVE_SLEEP_SIMPLE,
  HEADACHES_SIMPLE,
  BACK_ACHES_SIMPLE,
  SPECIFIC_AREAS_SIMPLE,
  SPECIFIC_ACTIVITIES_SIMPLE,
  TENSION_SIMPLE,
  SITUATIONS_SIMPLE,
  BEHAVIOUR_SIMPLE,
  WITH_MIMIKA_SIMPLE,
  BEST_VERSION_SIMPLE,
  MAIN_MOTIVATION_SIMPLE,
  MENTAL_WELLNESS_SIMPLE,
  WANT_TO_EXPLORE_SIMPLE,
  ACTION_PLAN_SIMPLE,
  RADIAL_PROGRESS_SIMPLE,
  RADIAL_PROGRESS_TWO_SIMPLE,
  LESS_COSTLY_SIMPLE,
  SAVE_MONEY_SIMPLE,
  GOALS_TIMELINE_SIMPLE,
  JUST_ROUTINE_SIMPLE,
  SLEEP_KEY_SIMPLE,
  BEAUTY_STARTS_SIMPLE,
  IMPROVE_QUALITY_SIMPLE,
  GREAT_JOB_SIMPLE,
  ECO_FRIENDLY_SIMPLE,
  AMAZING_STORIES_SIMPLE,
  ENTER_EMAIL_SIMPLE,
  BEST_SKIN_SIMPLE,
  GOAL_PLAN_SIMPLE,
  DISCOMFORT_SIMPLE,
  NAME,
  SKIN_CARE_GOALS_SIMPLE,
  SKIN_ALLERGIES_SIMPLE,
  ONE_WEEK,
  RID_OF,
  ELIMINATION,
  BOOST_RESULT,
  UNIQUE_TECHNOLOGIES,
  LONG_PREPAYWALL,
}

interface BaseQuizStep {
  type: QuizStepType;
  gaEventName?: string;
  section: QuizSection;
  step: QuizStep;
  nextButtonText?: string | ReactNode;
  title?: ReactNode;
  titleId?: TpMessageKeys;
  markId?: TpMessageKeys;
  markDefaultMessage?: string;
  defaultTitleMessage?: string;
  subtitle?: ReactNode;
  subtitleId?: TpMessageKeys;
  defaultSubtitleMessage?: string;
  TitleComponent?: ReactNode;
  SubTitleComponent?: ReactNode;
  imagesForPreload?: Array<string>;
}

export interface OtherQuizStep extends BaseQuizStep {
  type: QuizStepType.OTHER;
  options?: SingleOptionSelectQuizStepOption[];
  background?: ColoredBackgroundQuizStepBackground;
  image?: ReactNode;
  imageHeight?: number;
}

export interface TextCardQuizStep extends BaseQuizStep {
  type: QuizStepType.TEXT_CARD;
  image?: ReactNode;
  background: ColoredBackgroundQuizStepBackground;
}

export interface SelectQuizStepOption<T> {
  value: T;
  label?: string | ReactNode;
  labelId?: TpMessageKeys;
  labelDefaultMessage?: string;
}

export interface SingleOptionSelectQuizStepOption
  extends SelectQuizStepOption<number> {
  image?: ReactNode;
  imageColor?: string;
}

export type TpReviewsQuizStepReview = {
  image: ReactNode;
  title: {
    id: TpMessageKeys;
    defaultMessage: string;
  };
  description: {
    id: TpMessageKeys;
    defaultMessage: string;
  };
};
export type TpReviewsQuizStep = BaseQuizStep & {
  type: QuizStepType.REVIEWS;
};

export type TpAchieveResultsQuizStep = BaseQuizStep & {
  type: QuizStepType.ACHIEVE_RESULTS;
};

export interface SingleOptionSelectQuizStep extends BaseQuizStep {
  type: QuizStepType.SINGLE_OPTION_SELECT;
  options: SingleOptionSelectQuizStepOption[];
  background?: ColoredBackgroundQuizStepBackground;
  image?: ReactNode;
}

export interface SingleOptionImageSelectQuizStep extends BaseQuizStep {
  type: QuizStepType.SINGLE_OPTION_WITH_IMAGE_SELECT;
  options: SingleOptionSelectQuizStepOption[];
}

export interface MultipleOptionsSelectQuizStepOption
  extends SelectQuizStepOption<string> {
  image?: ReactNode;
}

export interface MultipleOptionsSelectQuizStep extends BaseQuizStep {
  type: QuizStepType.MULTIPLE_OPTIONS_SELECT;
  options: MultipleOptionsSelectQuizStepOption[];
  skipButtonText?: string | ReactNode;
  skipOptionText?: MultipleOptionsSelectQuizStepOption;
  extraContent?: ReactNode;
}

export interface MultipleOptionsImageSelectQuizStep extends BaseQuizStep {
  type: QuizStepType.MULTIPLE_OPTIONS_WITH_IMAGE_SELECT;
  options: MultipleOptionsSelectQuizStepOption[];
  skipButtonText?: string | ReactNode;
  skipOptionText?: MultipleOptionsSelectQuizStepOption;
}

export enum ColoredBackgroundQuizStepBackground {
  WHITE,
  BROWN,
  BROWN_LIGHT,
  CREAM,
  BLUE,
  PASTEL_GREEN,
  GREEN,
  LIGHT_PINK,
  PINK,
  DEFAULT,
  GREY,
  LIGHT_GREY,
  SOFT_GREEN,
  LIGHT_BEIGE,
  LIGHT_BLUE,
  PASTEL,
  SECONDARY_GREEN,
  SECONDARY_PINK,
  SECONDARY_ORANGE,
  LINEAR_ORANGE,
}

export type TpStep =
  | OtherQuizStep
  | TextCardQuizStep
  | SingleOptionSelectQuizStep
  | SingleOptionImageSelectQuizStep
  | MultipleOptionsSelectQuizStep
  | MultipleOptionsImageSelectQuizStep
  | TpReviewsQuizStep
  | TpAchieveResultsQuizStep;

export const isStepHeaderShown = (step: TpStep) => {
  switch (step.type) {
    case QuizStepType.OTHER:
      switch (step.step) {
        case QuizStep.ENTER_EMAIL:
        case QuizStep.WELCOME_LOADER:
        case QuizStep.WELCOME_LOADER_SECOND:
        case QuizStep.ENTER_EMAIL_SIMPLE:
        case QuizStep.MILLION_USERS:
        case QuizStep.WE_STUDIED_SIMPLE:
        case QuizStep.PERSONALISED_PROGRAMS_SIMPLE:
        case QuizStep.DEVELOPED_SYSTEM_SIMPLE:
        case QuizStep.LESS_COSTLY_SIMPLE:
        case QuizStep.SAVE_MONEY_SIMPLE:
        case QuizStep.MODEL:
        case QuizStep.RADIAL_PROGRESS:
        case QuizStep.RADIAL_PROGRESS_SIMPLE:
        case QuizStep.RADIAL_PROGRESS_TWO_SIMPLE:
        case QuizStep.GOALS_TIMELINE:
        case QuizStep.GOALS_TIMELINE_SIMPLE:
        case QuizStep.SUIT_SCHEDULE_EVENING_MAP:
        case QuizStep.SUIT_SCHEDULE_MORNING_MAP:
        case QuizStep.NUTRITION_OPENER:
        case QuizStep.NUTRITION_SUMMARY:
        case QuizStep.NAME:
        case QuizStep.JUST_ROUTINE_SIMPLE:
        case QuizStep.SLEEP_KEY_SIMPLE:
        case QuizStep.BEAUTY_STARTS_SIMPLE:
        case QuizStep.IMPROVE_QUALITY_SIMPLE:
        case QuizStep.GREAT_JOB_SIMPLE:
        case QuizStep.ECO_FRIENDLY_SIMPLE:
        case QuizStep.AMAZING_STORIES_SIMPLE:
        case QuizStep.BEST_SKIN_SIMPLE:
        case QuizStep.GOAL_PLAN_SIMPLE:
          return false;

        default:
          return true;
      }

    default:
      return true;
  }
};

export const isStepProgressShown = (step: TpStep) => {
  switch (step.type) {
    case QuizStepType.OTHER:
      switch (step.step) {
        case QuizStep.WELCOME_LOADER:
        case QuizStep.WELCOME_LOADER_SECOND:
        case QuizStep.EXPECT:
        case QuizStep.EXPECT_DYNAMIC:
        case QuizStep.SKIN_RECOMMEND:
        case QuizStep.FIRST_CHANGES:
        case QuizStep.MILLION_USERS:
        case QuizStep.MODEL:
        case QuizStep.RADIAL_PROGRESS:
        case QuizStep.GOALS_TIMELINE:
        case QuizStep.GOALS_TIMELINE_SIMPLE:
        case QuizStep.SUIT_SCHEDULE_EVENING_MAP:
        case QuizStep.SUIT_SCHEDULE_MORNING_MAP:
        case QuizStep.NUTRITION_OPENER:
        case QuizStep.NUTRITION_SUMMARY:
        case QuizStep.JUST_ROUTINE_SIMPLE:
        case QuizStep.SLEEP_KEY_SIMPLE:
        case QuizStep.BEAUTY_STARTS_SIMPLE:
        case QuizStep.IMPROVE_QUALITY_SIMPLE:
        case QuizStep.GREAT_JOB_SIMPLE:
        case QuizStep.ECO_FRIENDLY_SIMPLE:
        case QuizStep.AMAZING_STORIES_SIMPLE:
        case QuizStep.BEST_SKIN_SIMPLE:
        case QuizStep.GOAL_PLAN_SIMPLE:
        case QuizStep.NAME:
        case QuizStep.WE_STUDIED_SIMPLE:
        case QuizStep.PERSONALISED_PROGRAMS_SIMPLE:
        case QuizStep.LESS_COSTLY_SIMPLE:
        case QuizStep.DEVELOPED_SYSTEM_SIMPLE:
        case QuizStep.SAVE_MONEY_SIMPLE:
        case QuizStep.ENTER_EMAIL_SIMPLE:
        case QuizStep.RADIAL_PROGRESS_SIMPLE:
        case QuizStep.ONE_WEEK:
        case QuizStep.RID_OF:
        case QuizStep.ELIMINATION:
        case QuizStep.BOOST_RESULT:
        case QuizStep.UNIQUE_TECHNOLOGIES:
        case QuizStep.LONG_PREPAYWALL:
          return false;
        default:
          return true;
      }
    case QuizStepType.TEXT_CARD:
    case QuizStepType.ACHIEVE_RESULTS:
      return false;

    default:
      return true;
  }
};

export enum QuizAgeOption {
  FROM_18_TO_24 = 0,
  FROM_25_TO_34 = 1,
  FROM_35_TO_44 = 2,
  FROM_45_TO_54 = 3,
  FROM_55_TO_64 = 4,
  FROM_65 = 5,
}

export enum OptionVariant {
  DEFAULT,
  GREEN,
}

export type OnboardingHeaderProps = {
  onPreviousPress: () => void;
  onSkipPress: undefined | (() => void);
  showProgressBar?: boolean;
  showBurgerMenu: boolean;
  country?: string;
  calculateProgress: ({ quizSection }: { quizSection: QuizSection }) => number;
  sectionName: string;
  showPreviousButton?: boolean;
};
