import { FormattedMessage } from '@features/intl';
import ApplePayBadge from '../../../../public/images/applepayBadge.svg';
import styles from './money-back.module.scss';
export const MoneyBack = ({
  showApplePayBadge
}: {
  showApplePayBadge?: boolean;
}) => <div className={styles.container} style={{
  justifyContent: showApplePayBadge ? 'flex-start' : 'center'
}} data-sentry-component="MoneyBack" data-sentry-source-file="money-back.tsx">
    {showApplePayBadge && <ApplePayBadge className={styles.applePayBadge} />}
    <h3 className={styles.guarantee}>
      <FormattedMessage defaultMessage="30-day {mark}" id="Onboarding.Paywall.Main.MoneyBack" values={{
      mark: <mark>
              <FormattedMessage defaultMessage="money-back guarantee" id="Onboarding.Paywall.Main.MoneyBack.Mark" />
            </mark>
    }} data-sentry-element="FormattedMessage" data-sentry-source-file="money-back.tsx" />
    </h3>
  </div>;