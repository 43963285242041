import { UserSnapshot } from '../models/user/user';
import { RequestError, get, post } from './api';

const responseToUser = (response: any): UserSnapshot => ({
  id: response['id'],
  email: response['email'],
  isSubscriptionActive: response['is_subscription_active'],
  isPasswordSet: response['is_web_password_set'],
  token: response['token'],
  accessCode: response['access_code'],
});

const responseToUserVariant2 = (response: any): UserSnapshot => ({
  token: response['token'],
  id: response['server_id'],
  email: response['email'],
  isSubscriptionActive: response['is_subscription_active'],
  isPasswordSet: response['is_web_password_set'],
  accessCode: response['access_code'],
});

export const fetchUser = async (
  token?: string,
): Promise<UserSnapshot | null> => {
  if (!token) return null;
  try {
    return responseToUser(await get({ path: 'user-profile', token }));
  } catch (error) {
    if (error instanceof RequestError && error.statusCode === 403) {
      return null;
    }
    throw error;
  }
};

export const register = async ({
  email,
  age,
  variant,
  abortSignal,
  quizAnswers,
}: {
  email: string;
  age: number;
  variant: string | undefined;
  abortSignal?: AbortSignal;
  quizAnswers: unknown;
}): Promise<UserSnapshot> =>
  responseToUser(
    await post({
      path: 'register-user',
      body: {
        email: email,
        age: age,
        variant: variant,
        data_version: process.env.PACKAGE_VERSION,
        user_data: quizAnswers,
      },
      abortSignal,
    }),
  );

export const setPasswordAndEmail = async ({
  email,
  password,
  abortSignal,
}: {
  email: string;
  password: string;
  abortSignal?: AbortSignal;
}): Promise<void> =>
  await post({
    path: 'set-password-and-email',
    body: {
      email: email,
      password: password,
    },
    abortSignal,
  });

export const logOut = async () => {
  try {
    await post({
      path: 'logout',
      body: {},
    });
  } catch (error) {
    if (error instanceof RequestError && error.statusCode === 403) {
      return;
    }
    throw error;
  }
};

export const getSafariAuthToken = async (): Promise<string> => {
  const responseObject = await post({
    path: 'get-safari-auth-token',
    body: {},
  });
  return responseObject['temp_token'];
};

export const loginBySafariAuthToken = async ({
  safariAuthToken,
}: {
  safariAuthToken: string;
}) =>
  responseToUserVariant2(
    await post({
      path: 'perform-login-by-safari-auth-token',
      body: {
        temp_token: safariAuthToken,
      },
    }),
  );

export const loginWithEmailToken = async ({
  emailToken,
  abortSignal,
}: {
  emailToken: string;
  abortSignal?: AbortSignal;
}): Promise<UserSnapshot> =>
  responseToUserVariant2(
    await post({
      path: 'login-with-email-token',
      body: { email_token: emailToken },
      abortSignal,
    }),
  );
