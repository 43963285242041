import { Image } from 'src/components/common/Image';
import { FormattedMessage, TpMessageKeys } from 'src/features/intl';
import styles from './answers.module.scss';
const answers: {
  id: TpMessageKeys;
  defaultMessage: string;
}[] = [{
  id: 'Onboarding.Paywall.Main.Bullet1',
  defaultMessage: 'Shiny and smooth skin'
}, {
  id: 'Onboarding.Paywall.Main.Bullet2',
  defaultMessage: 'Improved blood circulation and lymph flow'
}, {
  id: 'Onboarding.Paywall.Main.Bullet3',
  defaultMessage: 'Full Face relaxations'
}, {
  id: 'Onboarding.Paywall.Main.Bullet4',
  defaultMessage: 'Reduced imperfections'
}, {
  id: 'Onboarding.Paywall.Main.Bullet5',
  defaultMessage: 'Released toxins'
}, {
  id: 'Onboarding.Paywall.Main.Bullet6',
  defaultMessage: 'A toned, slimmer face'
}];
export const Answers = () => <div className={styles.container} data-sentry-component="Answers" data-sentry-source-file="index.tsx">
    <h3>
      <FormattedMessage defaultMessage="Personalized for you" id="Onboarding.Paywall.Main.BasedOn.Title1" data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
    </h3>
    <h3 className={styles.subtext}>
      <FormattedMessage defaultMessage="based on your responses" id="Onboarding.Paywall.Main.BasedOn.Title2" data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
    </h3>
    <p>
      <FormattedMessage defaultMessage="Here’s what to expect after just {mark}" id="Onboarding.Paywall.Main.BasedOn.Text1" values={{
      mark: <strong>
              <FormattedMessage defaultMessage="one week" id="Onboarding.Paywall.Main.BasedOn.Text.Mark" />
            </strong>
    }} data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />{' '}
      <FormattedMessage defaultMessage="of exercising with Mimika:" id="Onboarding.Paywall.Main.BasedOn.Text.2" data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
    </p>

    <Image className={styles.image} src="face-yoga-woman-paywall.webp" width={213} height={213} alt="Woman picture" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
    <ul className={styles.listAnswers}>
      {answers.map((answer, index) => <li key={index}>
          <div>{++index}</div>
          <p>
            <FormattedMessage id={answer.id} defaultMessage={answer.defaultMessage} />
          </p>
        </li>)}
    </ul>
  </div>;