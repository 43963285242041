import { Stripe } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useMemo } from 'react';

import { STRIPE_API_KEY } from '@features/Stripe/constants';

let stripePromise: Stripe | null;

const getStripe = async () => {
  if (!stripePromise) {
    stripePromise = await loadStripe(STRIPE_API_KEY);
  }
  return stripePromise;
};

export const useGetStripePromise = () => useMemo(() => getStripe(), []);
