import braintreeDropIn from 'braintree-web-drop-in';
import { useCallback, useState } from 'react';
import Button from 'src/components/common/button/button';
import { useStores } from 'src/components/common/root-store-provider/root-store-provider';
import { SubscriptionPlan } from 'src/models/subscription-plan/subscription-plan';
import { createChargebeeCustomer, useGenerateBraintreeClientToken } from '@features/Payments';
const BRAINTREE_CONTAINER_ID = 'braintree-dropin-container';
type TpChargebeeButtonProps = {
  subscriptionPlan: SubscriptionPlan;
};
export const ChargebeeButton = (props: TpChargebeeButtonProps) => {
  const {
    subscriptionPlan
  } = props;
  const [isFormValid, setIsFormValid] = useState(false);
  const [braintreeInstance, setBraintreeInstance] = useState<
  // @ts-expect-error braintreeDropIn cannot use as type
  undefined | braintreeDropIn>(undefined);
  const {
    authStore
  } = useStores();
  const {
    user
  } = authStore;
  const clientToken = useGenerateBraintreeClientToken({
    userFields: {
      email: user?.email,
      id: user?.id
    },
    merchantAccountId: subscriptionPlan.merchantAccountId
  });
  const dropIn = useCallback(async () => {
    if (!clientToken) {
      throw new Error('client token not found');
    }
    try {
      const initializeBraintree = await braintreeDropIn.create({
        authorization: clientToken,
        container: `#${BRAINTREE_CONTAINER_ID}`,
        card: {
          vault: {
            vaultCard: false
          }
        },
        threeDSecure: {
          amount: subscriptionPlan.immediatePrice.toFixed(2)
        }
      });
      setBraintreeInstance(initializeBraintree);
      initializeBraintree.on('paymentMethodRequestable', () => {
        setIsFormValid(true);
      });
      initializeBraintree.on('noPaymentMethodRequestable', () => {
        setIsFormValid(false);
      });
    } catch (error) {
      console.error(error);
    }
  }, [clientToken, subscriptionPlan.immediatePrice]);
  const handleProceed = useCallback(async () => {
    try {
      const {
        nonce,
        threeDSecureInfo
      } = await braintreeInstance?.requestPaymentMethod({
        threeDSecure: {
          amount: subscriptionPlan.immediatePrice.toFixed(2),
          email: user!.email,
          collectDeviceData: true,
          vaultCard: false
        }
      });
      await createChargebeeCustomer({
        nonce
      });
    } catch (error) {
      console.error(error);
    }
  }, [braintreeInstance, subscriptionPlan.immediatePrice, user]);
  return <>
      <Button onClick={dropIn} data-sentry-element="Button" data-sentry-source-file="index.tsx">Pay</Button>
      <div id={BRAINTREE_CONTAINER_ID} />

      {isFormValid && braintreeInstance && <Button onClick={handleProceed}>Proceed</Button>}
    </>;
};