import { NextSeo } from 'next-seo';
import { IMAGE_SOURCE } from 'src/utils/constants';
const ogImages = [{
  url: `${IMAGE_SOURCE}og-1200x628.png`,
  width: 1200,
  height: 600,
  alt: 'Face yoga'
}, {
  url: `${IMAGE_SOURCE}og-1024x1024.png`,
  width: 1024,
  height: 1024,
  alt: 'Face yoga'
}, {
  url: `${IMAGE_SOURCE}og-200x200.png`,
  width: 200,
  height: 200,
  alt: 'Face yoga'
}];
export const Seo = ({
  canonicalUrl
}: {
  canonicalUrl: string;
}) => <NextSeo title="Facial yoga & massage" twitter={{
  handle: '@Official_R_deep',
  site: '@Official_R_deep',
  cardType: 'summary_large_image'
}} openGraph={{
  type: 'website',
  url: canonicalUrl,
  title: 'Facial yoga & massage',
  images: ogImages
}} data-sentry-element="NextSeo" data-sentry-component="Seo" data-sentry-source-file="index.tsx" />;