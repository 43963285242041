import { axiosMicroservice } from 'src/api/axios';
import { TpStripeCreateInvoice } from 'src/features/Stripe/types';

const upsellUrlMap = {
  braintree: 'braintree_upsell',
  stripe: 'stripe/create_invoice',
};

export const createInvoice = async ({
  customerId,
  amount,
  paymentSystem,
  currency,
}: TpStripeCreateInvoice): Promise<{ message: string }> => {
  const { data } = await axiosMicroservice.post(
    `${upsellUrlMap[paymentSystem]}`,
    {
      customer_id: paymentSystem === 'stripe' ? customerId : undefined,
      app: 'FaceYoga',
      amount: amount,
      currency: currency,
      user_id: paymentSystem === 'braintree' ? customerId : undefined,
    },
  );
  return data;
};
