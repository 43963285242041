import { useState } from 'react';
import { UiKitButton } from 'src/components/common/ui-kit-button';
import { FormattedMessage } from 'src/features/intl';
import { ContactFormModal } from '../contact-form-modal';
import styles from './contact-block.module.scss';
export const ContactBlock = () => {
  const [isContactModalVisible, setIsContactModalVisible] = useState(false);
  return <>
      <ContactFormModal placeLabel="contact_us_block_paywall" show={isContactModalVisible} onHide={() => setIsContactModalVisible(false)} data-sentry-element="ContactFormModal" data-sentry-source-file="index.tsx" />

      <h3 className={styles.issues}>
        <FormattedMessage defaultMessage="Experiencing an issue?" id="Onboarding.Paywall.Main.Contact.Title" data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
      </h3>
      <UiKitButton onClick={() => setIsContactModalVisible(true)} className={styles.buttonContact} data-sentry-element="UiKitButton" data-sentry-source-file="index.tsx">
        <FormattedMessage defaultMessage="Contact us" id="Onboarding.Paywall.Main.Contact.Button" data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
      </UiKitButton>
    </>;
};