import LanguageDetector from 'i18next-browser-languagedetector';

export const languageDetector = new LanguageDetector(null, {
  order: [
    'navigator',
    'querystring',
    'cookie',
    'localStorage',
    'sessionStorage',
    'htmlTag',
  ],
});
