import classNames from 'classnames';
import React, { memo } from 'react';
import { useAnalytics } from '@features/Analytics';
import { FormattedMessage, TpMessageKeys } from '@features/intl';
import styles from '../links.module.scss';
export const RefundLink = memo(({
  place,
  className,
  children
}: {
  place: 'paywall' | 'checkout' | 'burger_menu' | 'email_modal' | 'upsale';
  className?: string;
  children?: React.ReactNode;
}) => {
  const {
    trackGoogleEvent
  } = useAnalytics();
  let formattedMessageId: TpMessageKeys;
  switch (place) {
    case 'paywall':
      {
        formattedMessageId = 'Onboarding.Paywall.Main.Contact.RefundPolicy.A';
        break;
      }
    case 'burger_menu':
      {
        formattedMessageId = 'Onboarding.Menu.Refund';
        break;
      }
    case 'upsale':
      {
        formattedMessageId = 'Onboarding.Book.RefundPolicy.A';
        break;
      }
    case 'checkout':
      {
        formattedMessageId = 'Onboarding.Checkout.RefundPolicy.A';
        break;
      }
    default:
      formattedMessageId = 'Onboarding.Menu.Refund';
      break;
  }
  return <a className={classNames(styles.link, className)} href="https://legal.mentalgrowth.app/mimika/refund-policy.pdf" target="_blank" rel="noreferrer" onClick={() => trackGoogleEvent({
    eventName: 'link_open',
    options: {
      place: place,
      link_name: 'refund policy'
    }
  })}>
        {children || <FormattedMessage id={formattedMessageId} defaultMessage={place === 'upsale' || place === 'checkout' ? 'Money Back and Cancellation Policy' : 'Refund Policy'} />}
      </a>;
});
RefundLink.displayName = 'RefundLink';