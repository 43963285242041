import { FC, useCallback, useEffect, useRef } from 'react';
import { useProgress } from 'src/components/common/circular-video-player/hooks/use-progress';
import { useVideoControl } from 'src/components/common/circular-video-player/hooks/use-video-control';
import styles from './circular-video-player.module.scss';
import VolumeMuteIcon from './icons/mute-icon.svg';
import PlayIcon from './icons/play-icon.svg';
import VolumeUpIcon from './icons/volume-up-icon.svg';
type TpCircularVideoPlayerProps = {
  id: string; // uniq ID of circlePlayer
  src: string; // Path to the video
  size?: number; // Diameter of circle in px
  strokeWidth?: number; // Width of progress-bar
  autoPlay?: boolean; // AutoPlay the video
  muted?: boolean; // Mute the video
  loop?: boolean; // Loop the video
};

export const CircularVideoPlayer: FC<TpCircularVideoPlayerProps> = ({
  id,
  src,
  size = 150,
  strokeWidth = 5,
  autoPlay = false,
  muted = true,
  loop = false
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const {
    progress,
    startProgressUpdate,
    stopProgressUpdate
  } = useProgress(videoRef);
  const {
    isPlaying,
    isMuted,
    togglePlay,
    toggleMute,
    setIsPlaying
  } = useVideoControl(videoRef, muted, id);
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  // Ignore small video progress for Safari iOS (#t=0.0001 workaround)
  const progressOffset = progress < 0.000_02 ? circumference : circumference - progress * circumference;
  const handlePlay = useCallback(() => {
    togglePlay();
    if (!isPlaying) startProgressUpdate();else stopProgressUpdate();
  }, [isPlaying, startProgressUpdate, stopProgressUpdate, togglePlay]);
  useEffect(() => {
    const video = videoRef.current;
    const handleEnded = () => {
      setIsPlaying(false);
      stopProgressUpdate();
    };
    if (video) {
      video.addEventListener('ended', handleEnded);
    }
    return () => {
      if (video) {
        video.removeEventListener('ended', handleEnded);
      }
    };
  }, [setIsPlaying, stopProgressUpdate]);
  return <div className={styles.wrapper} style={{
    width: size,
    minWidth: size,
    height: size,
    minHeight: size
  }} data-sentry-component="CircularVideoPlayer" data-sentry-source-file="index.tsx">
      <svg className={styles.progressRing} width={size} height={size} data-sentry-element="svg" data-sentry-source-file="index.tsx">
        <circle className={styles.progressBackground} strokeWidth={strokeWidth} r={radius} cx={size / 2} cy={size / 2} data-sentry-element="circle" data-sentry-source-file="index.tsx" />
        <circle className={styles.progressBar} strokeWidth={strokeWidth} strokeDasharray={circumference} strokeDashoffset={progressOffset} strokeLinecap="round" r={radius} cx={size / 2} cy={size / 2} data-sentry-element="circle" data-sentry-source-file="index.tsx" />
      </svg>

      <video ref={videoRef}
    // #t=0.0001 – is a workaround for Safari, without that video doesnt showup thumbnail of video
    src={src + '#t=0.0001'} controls={false} playsInline preload="metadata" className={styles.video} autoPlay={autoPlay} muted={muted} loop={loop} onClick={handlePlay} onError={() => console.error('Failed to load video')} />

      {!isPlaying && <div className={styles.playIcon} onClick={handlePlay}>
          <PlayIcon />
        </div>}

      {isPlaying && <div className={styles.soundIcon} onClick={toggleMute}>
          {isMuted ? <VolumeMuteIcon /> : <VolumeUpIcon />}
        </div>}
    </div>;
};