import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import { ComponentProps, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Modal from 'src/components/common/modal/modal/modal';
import { useStores } from 'src/components/common/root-store-provider/root-store-provider';
import { SubscriptionPlan } from 'src/models/subscription-plan/subscription-plan';
import { tikTokAdvancedMatching, tikTokEvent, useAnalytics } from '@features/Analytics';
import { twitterEvent } from '@features/Analytics/services/twitter-pixel';
import { PaymentModalStatus, generatePaddlePayLink } from '@features/Payments';
import { PAYMENT_MODAL_QUERY_PARAM } from '@features/Paywall';
import styles from './paddle-inline-checkout.module.scss';
export const PaddleInlineCheckout = observer(({
  country,
  productId,
  subscriptionPlan
}: {
  country?: string;
  productId: string | number | undefined;
  subscriptionPlan?: SubscriptionPlan;
} & ComponentProps<typeof Modal>) => {
  const {
    authStore: {
      setPaddlePaymentProcessed,
      isPaddleVariants,
      auth_token,
      campaign,
      user,
      firstName,
      lastName,
      dateOfBirth,
      gender
    },
    paymentsStore: {
      paymentMethod
    }
  } = useStores();
  const {
    trackFacebookEvent,
    trackGoogleEvent,
    trackPinterestEvent
  } = useAnalytics();
  const router = useRouter();
  const {
    query,
    pathname
  } = router;
  const {
    email,
    id
  } = user!;
  const paddleInlineRef = useRef<HTMLDivElement>(null);
  const onScrollToPayment = useCallback(() => {
    if (paddleInlineRef.current) {
      paddleInlineRef.current.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }, []);
  const onHide = useCallback(() => {
    // noinspection JSIgnoredPromiseFromCall
    router.push({
      pathname,
      query: {
        ...query,
        [PAYMENT_MODAL_QUERY_PARAM]: PaymentModalStatus.HIDE
      }
    }, undefined, {
      shallow: true
    });
  }, [pathname, query, router]);
  const isPaymentModalActive = query[PAYMENT_MODAL_QUERY_PARAM] === PaymentModalStatus.SHOW && isPaddleVariants;
  const [paddlePayLink, setPaddlePayLink] = useState<String | undefined>(undefined);
  const analyticsCombinedParameters = useMemo(() => ({
    value: subscriptionPlan?.fullPriceDiscount.toFixed(2),
    currency: subscriptionPlan?.currency
  }), [subscriptionPlan?.currency, subscriptionPlan?.fullPriceDiscount]);
  const onPaymentComplete = useCallback(() => {
    setPaddlePaymentProcessed(true);
    trackFacebookEvent({
      eventName: 'Purchase',
      customData: {
        variant: 'paddle',
        paymentSystem: 'paddle'
      },
      options: {
        ...analyticsCombinedParameters,
        email: user!.email,
        subscription_id: subscriptionPlan?.paddlePlanId,
        first_name: firstName ?? undefined,
        last_name: lastName ?? undefined,
        date_of_birth: dateOfBirth ?? undefined,
        gender: gender ?? undefined,
        fn: firstName ?? undefined,
        ln: lastName ?? undefined,
        ge: dateOfBirth ?? undefined,
        db: gender ?? undefined
      }
    });
    trackFacebookEvent({
      eventName: 'Subscribe',
      customData: {
        variant: 'paddle',
        paymentSystem: 'paddle'
      },
      options: {
        email: user!.email
      }
    });
    twitterEvent('tw-oehe8-oehej', {
      email: user!.email,
      ...analyticsCombinedParameters
    });
    trackPinterestEvent({
      eventName: 'Checkout',
      options: analyticsCombinedParameters
    });
    trackGoogleEvent({
      eventName: 'purchase_success',
      options: {
        product_id: subscriptionPlan?.paddleIternalName ?? subscriptionPlan?.name ?? 'undefined',
        payment_type: paymentMethod ?? 'undefined',
        campaign_name_manual: campaign ?? 'undefined',
        email: user!.email,
        ...analyticsCombinedParameters
      }
    });
    tikTokAdvancedMatching(user!.email);
    tikTokEvent('CompletePayment', {
      content_id: subscriptionPlan?.id,
      quantity: 1,
      ...analyticsCombinedParameters
    });
    onHide!();
  }, [analyticsCombinedParameters, campaign, dateOfBirth, firstName, gender, lastName, onHide, paymentMethod, setPaddlePaymentProcessed, subscriptionPlan?.id, subscriptionPlan?.name, subscriptionPlan?.paddleIternalName, subscriptionPlan?.paddlePlanId, trackFacebookEvent, trackGoogleEvent, trackPinterestEvent, user]);
  useEffect(() => {
    if (isPaymentModalActive && email && productId && auth_token && user) {
      (async () => {
        try {
          const paddlePayLink = await generatePaddlePayLink({
            productId: +productId,
            customerEmail: email,
            token: auth_token,
            userId: id
          });
          setPaddlePayLink(paddlePayLink.response.url);
        } catch (error) {
          console.log(error);
          return;
        }
      })();
    }
  }, [email, productId, isPaymentModalActive, auth_token, user, id]);
  const sendAnalytics = useCallback(() => {
    onScrollToPayment();
    trackFacebookEvent({
      eventName: 'InitiateCheckout',
      options: {
        email
      }
    });
    twitterEvent('tw-oehe8-oehek', {
      email_address: email
    });
    trackPinterestEvent({
      eventName: 'AddToCart'
    });
  }, [onScrollToPayment, email, trackFacebookEvent, trackPinterestEvent]);
  useEffect(() => {
    if (isPaymentModalActive && paddlePayLink !== undefined) {
      window.Paddle.Checkout.open({
        override: paddlePayLink,
        email: email,
        country: country,
        method: 'inline',
        disableLogout: true,
        loadCallback: sendAnalytics,
        successCallback: onPaymentComplete,
        paymentMethod: 'paddle',
        frameTarget: 'paddle-checkout-container',
        // className of your checkout <div>
        frameInitialHeight: 620,
        // `450` or above
        frameStyle: 'width:100%; min-width:312px; min-height:620px; background-color: transparent; border: none;'
      });
    }
  }, [country, email, onPaymentComplete, paddlePayLink, isPaymentModalActive, sendAnalytics]);
  return <div className={classNames(styles.container, isPaymentModalActive && paddlePayLink !== undefined && styles.open)}>
        <div ref={paddleInlineRef} style={{
      minHeight: 0
    }} className="paddle-checkout-container" />
      </div>;
});
PaddleInlineCheckout.displayName = 'PaddleInlineCheckout';