import jsCookies from 'js-cookie';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import { ComponentType, Suspense, useEffect, useMemo, useState } from 'react';
import FirstScreenLoading from 'src/components/common/FirstScreenLoading';
import PageLoading from 'src/components/common/page-loading/page-loading';
import { useStores } from 'src/components/common/root-store-provider/root-store-provider';
import { FIRST_SCREEN_LOADER_AB_TEST_NAME, FirstScreenLoaderAbTestCaseName } from 'src/constants/analytics';
import { routes } from 'src/constants/navigation';
import { GOOGLE_OR_PINTEREST_SET, TpStripeVariants, TpVariants } from 'src/constants/variants';
import { useAbortableEffect } from 'src/utils/hooks/use-abortable';
import { useABTestExperiment } from '@features/ABTesting';
const page = <T extends object,>(Component: ComponentType<T>) => observer((props: T) => {
  const [mounted, setMounted] = useState(false);
  const [goToQuiz, setGoToQuiz] = useState(false);
  const {
    caseNameByTestName
  } = useABTestExperiment(FIRST_SCREEN_LOADER_AB_TEST_NAME);
  const {
    authStore: {
      user,
      variant,
      fetchUser
    },
    analyticsStore: {
      getAbTests,
      segment,
      isFetching
    }
  } = useStores();
  const router = useRouter();
  const {
    pathname,
    route,
    query,
    isReady
  } = router;
  const googlePseudoIdCurrent = jsCookies.get('_ga')?.slice(6);
  const emailToken = typeof query['email_token'] === 'string' ? query['email_token'] : undefined;
  const isFirstScreenLoaderTest = caseNameByTestName === FirstScreenLoaderAbTestCaseName.NEW;
  const isFirstScreenAndVariantCheck = GOOGLE_OR_PINTEREST_SET.has((variant as TpVariants | TpStripeVariants)) && isFirstScreenLoaderTest || variant === 'variantlong';
  const result = useMemo(() => {
    if (!route.includes(routes.quiz) && (!mounted || user === undefined)) {
      return <PageLoading />;
    } else if (pathname === routes.quiz && !goToQuiz && isFirstScreenAndVariantCheck) {
      return <>
            <FirstScreenLoading setGoToQuiz={setGoToQuiz} />
            <Suspense>
              <div style={{
            display: 'none'
          }}>
                <Component {...props} />
              </div>
            </Suspense>
          </>;
    } else {
      return <Component {...props} />;
    }
  }, [route, mounted, user, pathname, goToQuiz, isFirstScreenAndVariantCheck, Component, props]);
  useEffect(() => {
    setMounted(true);
  }, []);
  useEffect(() => {
    router.prefetch(routes.quiz).then(r => r);
    router.prefetch(routes.newPlanPaywall).then(r => r);
  }, [router]);
  useAbortableEffect(() => {
    if (emailToken === undefined || variant !== 'emailtraf') {
      // noinspection JSIgnoredPromiseFromCall
      fetchUser();
    }
  }, [emailToken, fetchUser]);
  useAbortableEffect(() => {
    if (!segment && !isFetching && query['reset'] === undefined && query['hard_reset'] === undefined && isReady) {
      getAbTests({
        variant: variant ?? '',
        utm_source: (query['utm_source'] as string) ?? '',
        utm_campaign: (query['utm_campaign'] as string) ?? '',
        region: (query['region'] as string) ?? '',
        user_id: googlePseudoIdCurrent
      }).then(r => r);
    }
  }, [getAbTests, isReady, query, segment, variant, googlePseudoIdCurrent]);

  // The check on `mounted` helps avoid hydration DOM mismatch
  // that happens because `user` is always empty during SSR,
  // but may be `null` if the user is not logged in
  return result;
});
export default page;