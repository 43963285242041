import { useRouter } from 'next/router';
import { useCallback } from 'react';

import { routes } from 'src/constants/navigation';

import { PaymentModalStatus } from '@features/Payments';
import { PAYMENT_MODAL_QUERY_PARAM } from '@features/Paywall';

export enum PaymentCompletionStatus {
  SUCCESS = 'success',
  FAILURE = 'failure',
}

const PAYMENT_COMPLETION_STATUSES = Object.values(
  PaymentCompletionStatus,
) as string[];
export const PAYMENT_STATUS_QUERY_PARAM = 'payment_status';

export const getPaymentCompleteUrl = (
  status?: PaymentCompletionStatus,
  useAlternativeRoute = false,
) => {
  const url = new URL(location.href);
  url.searchParams.delete(PAYMENT_STATUS_QUERY_PARAM);
  if (useAlternativeRoute && status === PaymentCompletionStatus.SUCCESS) {
    url.pathname = routes.paymentSuccess;
  } else if (status != null) {
    url.searchParams.append(PAYMENT_STATUS_QUERY_PARAM, status);
  }
  return url.href;
};

export const usePaymentCompletionStatus = () => {
  const router = useRouter();
  const { query } = router;

  const paymentCompletionStatusQueryValue = query[PAYMENT_STATUS_QUERY_PARAM];
  const paymentCompletionStatus =
    typeof paymentCompletionStatusQueryValue === 'string' &&
    PAYMENT_COMPLETION_STATUSES.includes(paymentCompletionStatusQueryValue)
      ? (paymentCompletionStatusQueryValue as PaymentCompletionStatus)
      : undefined;

  // biome-ignore lint/correctness/useExhaustiveDependencies: router is not changing
  const setPaymentCompletionStatus = useCallback(
    ({
      value,
      selectedPlanId,
    }: {
      value: PaymentCompletionStatus | undefined;
      selectedPlanId: string | undefined;
    }) => {
      const { query, pathname } = router;
      const newQuery = value
        ? {
            ...query,
            ['plan']: selectedPlanId,
            [PAYMENT_STATUS_QUERY_PARAM]: value,
            [PAYMENT_MODAL_QUERY_PARAM]: PaymentModalStatus.HIDE,
          }
        : {
            ...query,
            ['plan']: selectedPlanId,
            [PAYMENT_STATUS_QUERY_PARAM]: undefined,
          };
      // noinspection JSIgnoredPromiseFromCall
      router.replace({ pathname, query: newQuery }, undefined, {
        shallow: true,
      });
    },
    [],
  );

  return { paymentCompletionStatus, setPaymentCompletionStatus };
};
