import { Instance, SnapshotOut, types } from 'mobx-state-tree';

export const UserModel = types
  .model('User')
  .props({
    token: types.maybe(types.string),
    id: types.number,
    email: types.string,
    isSubscriptionActive: types.boolean,
    isPasswordSet: types.boolean,
    accessCode: types.maybe(types.string),
  })
  .actions((self) => ({
    setEmail: (value: string) => {
      self.email = value;
    },
    setPasswordSet: () => {
      self.isPasswordSet = true;
    },
  }));

type UserType = Instance<typeof UserModel>;

export interface User extends UserType {}

type UserSnapshotType = SnapshotOut<typeof UserModel>;

export interface UserSnapshot extends UserSnapshotType {}
