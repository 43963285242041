import { reaction } from 'mobx';
import { useEffect, useState } from 'react';

import { useStores } from 'src/components/common/root-store-provider/root-store-provider';

export const useIsAutoRenewEnabled = () => {
  const { authStore } = useStores();
  const [isAutoRenewEnabled, setIsAutoRenewEnabled] = useState(false);

  useEffect(() => {
    const dispose = reaction(
      () => authStore.variant,
      (variant) => {
        setIsAutoRenewEnabled(
          variant === 'variant20' ||
            variant === 'variant21' ||
            variant === 'variant22',
        );
      },
      { fireImmediately: true },
    );

    return () => dispose();
  }, [authStore]);

  return isAutoRenewEnabled;
};
