import { useQuery } from '@tanstack/react-query';

import { getStripeSubscriptions } from '@features/Stripe/api';

export const useGetStripeSubscriptions = ({
  productId,
}: {
  productId: string | undefined;
}) => {
  const { data } = useQuery({
    queryKey: ['stripe-subscriptions', productId],
    queryFn: () => getStripeSubscriptions({ productId }),
    enabled: !!productId,
  });

  return data;
};
