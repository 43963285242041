import { Image } from 'src/components/common/Image';
import { FormattedMessage } from '@features/intl';
import styles from './press-section.module.scss';
export const PressSection = () => <div className={styles.container} data-sentry-component="PressSection" data-sentry-source-file="index.tsx">
    <div className={styles.section}>
      <Image src="magazine.svg" width={72} height={72} alt="" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
      <span className={styles.text}>
        <FormattedMessage id="Onboarding.Paywall.Main.Quote" defaultMessage='"Exercising Your face. {br} Face massage for a subtle glow"' values={{
        br: <br />
      }} data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
      </span>
    </div>
    <h3 className={styles.title}>
      {' '}
      <FormattedMessage id="Onboarding.Paywall.Main.AsSeenOn" defaultMessage="As seen on" data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
    </h3>
    <div className={styles.subsection}>
      <Image src="women-health.svg" width={136} height={28} alt="" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
      <Image src="oprah-magazine.svg" width={198} height={37} alt="" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
      <Image src="marie-claire.svg" width={132} height={64} alt="" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
    </div>
  </div>;