import { useRouter } from 'next/router';
import { ReactNode, useEffect, useState } from 'react';
import { IntlProvider as Provider } from 'react-intl';
import { languageDetector } from '@features/intl/languageDetector';
import { DEFAULT_LOCALE } from './contants';
import { getMessages } from './service';
import type { TpLocale } from './types';
export const IntlProvider = (props: TpIntlProviderProps) => {
  const router = useRouter();
  const {
    query
  } = router;
  const {
    children
  } = props;
  const [messages, setMessages] = useState({});
  const currentLocale = query.variant === 'variantlong' ? DEFAULT_LOCALE : languageDetector.detect() || DEFAULT_LOCALE;
  const currentLocaleTwoSymbols = currentLocale.slice(0, 2);
  useEffect(() => {
    document.documentElement.lang = navigator.language;
    getMessages((currentLocaleTwoSymbols as TpLocale)).then(data => setMessages(data));
  }, [currentLocaleTwoSymbols]);
  return <Provider defaultLocale="en" locale={(currentLocaleTwoSymbols as TpLocale)} messages={messages} onError={() => console.info('Translations is not full')} data-sentry-element="Provider" data-sentry-component="IntlProvider" data-sentry-source-file="provider.tsx">
      {children}
    </Provider>;
};
type TpIntlProviderProps = {
  children: ReactNode;
};