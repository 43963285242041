import { useStripe } from '@stripe/react-stripe-js';
import classNames from 'classnames';
import { useAtomValue, useSetAtom } from 'jotai/index';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import AmexLogo from 'src/assets/icons/amex.svg';
import ApplePayLogo from 'src/assets/images/payment/apple-pay-logo.svg';
import MasterCard from 'src/assets/images/payment/mastercard-logo.svg';
import PayPalLogo from 'src/assets/images/payment/paypal-logo.svg';
import UnionPayLogo from 'src/assets/images/payment/unionpay-logo.svg';
import VisaLogo from 'src/assets/images/payment/visa-logo.svg';
import { getDataFromSessionStorage } from 'src/utils';
import { tikTokAdvancedMatching, tikTokEvent, twitterEvent, useAnalytics } from '@features/Analytics';
import { processingModalStatusAtom, selectedPlanAtom, stripeSubscriptionWithPersistence, userAtom } from '@features/Stripe/atoms';
import { BILLING_PERIOD_LTV_MAP, CURRENCY_DOES_NOT_SUPPORT_PAYPAL } from '@features/Stripe/constants';
import { useCreateSubscription, usePaymentRequest } from '@features/Stripe/hooks';
import { CompactSelector } from '@features/Stripe/widgets/checkout-selector/widgets/compact-selector';
import { PaymentOptionTab } from '@features/Stripe/widgets/checkout-selector/widgets/payment-option-tab';
import GooglePayLogo from '../../../../../public/images/gpay-logo-white-theme.svg';
import styles from './checkout-selector.module.scss';
const PAYMENT_OPTIONS: {
  paymentMethodName: 'PayPal' | 'Google Pay' | 'Apple Pay' | 'Credit card';
  paymentMethodLogo: ReactNode;
  paymentMethodLogoCompact: ReactNode;
  paymentMethodContentList: string[] | undefined;
  paymentButtonText: string | undefined;
}[] = [{
  paymentMethodName: 'Credit card',
  paymentMethodLogo: <div style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gridGap: '8px',
    width: '146px',
    height: '18px'
  }}>
        <VisaLogo width={45.94} height={18} />
        <MasterCard width={23.4} height={18} />
        <AmexLogo width={25.2} height={18} />
        <UnionPayLogo width={27.9} height={18} />
      </div>,
  paymentMethodLogoCompact: <svg xmlns="http://www.w3.org/2000/svg" width="21" height="18" viewBox="0 0 21 18" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M20.7501 4.7898V10.0298C20.7501 11.4898 20.3601 12.5598 19.6701 13.2898C19.4901 13.4698 19.2201 13.3298 19.2201 13.0798V5.9098C19.2201 3.6598 17.4001 1.8398 15.1501 1.8398H4.28011C4.02011 1.8398 3.88011 1.5598 4.06011 1.3898C4.40011 1.0598 4.82011 0.809805 5.29011 0.639805C5.89011 0.409805 6.57011 0.299805 7.33011 0.299805H16.2601C18.9601 0.299805 20.7501 1.6598 20.7501 4.7898ZM13.97 3.25H4.53C4.25 3.25 3.99 3.26 3.75 3.27C1.38 3.41 0.75 4.28 0.75 6.98V7.56C0.75 8.11 1.2 8.56 1.75 8.56H16.75C17.3 8.56 17.75 8.11 17.75 7.56V6.98C17.75 4 16.99 3.25 13.97 3.25ZM1.75 10.0605C1.2 10.0605 0.75 10.5105 0.75 11.0605V13.9705C0.75 16.9505 1.51 17.7005 4.53 17.7005H13.97C16.94 17.7005 17.72 16.9805 17.75 14.1305V11.0605C17.75 10.5105 17.3 10.0605 16.75 10.0605H1.75ZM5.71 15.2605H4C3.59 15.2605 3.25 14.9205 3.25 14.5105C3.25 14.1005 3.59 13.7605 4 13.7605H5.72C6.13 13.7605 6.47 14.1005 6.47 14.5105C6.47 14.9205 6.13 15.2605 5.71 15.2605ZM11.3 15.2605H7.86C7.45 15.2605 7.11 14.9205 7.11 14.5105C7.11 14.1005 7.45 13.7605 7.86 13.7605H11.3C11.71 13.7605 12.05 14.1005 12.05 14.5105C12.05 14.9205 11.72 15.2605 11.3 15.2605Z" fill="#302C28" />
      </svg>,
  paymentMethodContentList: undefined,
  paymentButtonText: 'Continue'
}, {
  paymentMethodName: 'PayPal',
  paymentMethodLogo: <PayPalLogo />,
  paymentMethodLogoCompact: <PayPalLogo />,
  paymentMethodContentList: ['Fast, convenient payment option', 'Keeps your financial info safe with end-to-end encryption', "Backed by PayPal's industry-leading fraud protection"],
  paymentButtonText: 'Confirm purchase'
}, {
  paymentMethodName: 'Apple Pay',
  paymentMethodLogo: <ApplePayLogo />,
  paymentMethodLogoCompact: <ApplePayLogo />,
  paymentMethodContentList: ['Fast, convenient payment option', 'Keeps your financial info safe with end-to-end encryption', "Backed by PayPal's industry-leading fraud protection"],
  paymentButtonText: undefined
}, {
  paymentMethodName: 'Google Pay',
  paymentMethodLogo: <GooglePayLogo />,
  paymentMethodLogoCompact: <GooglePayLogo />,
  paymentMethodContentList: ['Fast, convenient payment option', 'Keeps your financial info safe with end-to-end encryption', "Backed by PayPal's industry-leading fraud protection"],
  paymentButtonText: undefined
}];
const SkeletonMethods = () => <>
    <Skeleton count={1} height={350} data-sentry-element="Skeleton" data-sentry-source-file="index.tsx" />
    <Skeleton count={1} height={50} data-sentry-element="Skeleton" data-sentry-source-file="index.tsx" />
    <Skeleton count={1} height={50} data-sentry-element="Skeleton" data-sentry-source-file="index.tsx" />
  </>;
const SkeletonMethodsCompact = () => <>
    <Skeleton containerClassName={styles.containerSkeleton} count={3} height={48} className={styles.skeletonItem} data-sentry-element="Skeleton" data-sentry-source-file="index.tsx" />
    <Skeleton count={1} height={64} className={styles.skeletonItemSubmitButton} data-sentry-element="Skeleton" data-sentry-source-file="index.tsx" />
  </>;
export const CheckoutSelector = ({
  isCompactStyle = false
}: {
  isCompactStyle?: boolean;
}) => {
  const planInfo = useAtomValue(selectedPlanAtom);
  const stripe = useStripe();
  const {
    paymentMethodResult
  } = usePaymentRequest({
    stripe,
    planInfo
  });
  const isCurrencySupportPayPal = useMemo(() => !CURRENCY_DOES_NOT_SUPPORT_PAYPAL.includes(planInfo.currency), [planInfo.currency]);
  const filteredPaymentOptions = useMemo(() => PAYMENT_OPTIONS.filter(paymentOption => {
    if (paymentMethodResult === 'applePay') {
      return paymentOption.paymentMethodName !== 'Google Pay';
    }
    if (paymentMethodResult === 'googlePay') {
      return paymentOption.paymentMethodName !== 'Apple Pay';
    }
    return paymentOption.paymentMethodName !== 'Apple Pay' && paymentOption.paymentMethodName !== 'Google Pay';
  }).filter(paymentOption => {
    if (!isCurrencySupportPayPal) {
      return paymentOption.paymentMethodName !== 'PayPal';
    }
    return true;
  }), [isCurrencySupportPayPal, paymentMethodResult]);
  const {
    email,
    token,
    userId,
    campaign
  } = useAtomValue(userAtom);
  const setCustomerId = useSetAtom(userAtom);
  const {
    mutateAsync: createSubscription
  } = useCreateSubscription();
  const [clientSecret, setClientSecret] = useState('');
  const [activePaymentMethod, setActivePaymentMethod] = useState(filteredPaymentOptions[0].paymentMethodName);
  const handlePaymentMethodSelect = useCallback((paymentMethodName: 'PayPal' | 'Google Pay' | 'Apple Pay' | 'Credit card') => {
    setActivePaymentMethod(paymentMethodName);
  }, []);
  const createCustomerAndSubscription = useCallback(async () => {
    const {
      client_secret,
      customer_id
    } = await createSubscription({
      priceId: (planInfo.priceId as string),
      currency: planInfo.currency,
      token,
      email,
      campaign,
      userId,
      introOfferId: undefined,
      introOfferDuration: undefined
    });
    setCustomerId(previous => ({
      ...previous,
      customerId: customer_id
    }));
    return client_secret;
  }, [createSubscription, planInfo.priceId, planInfo.currency, token, email, campaign, userId, setCustomerId]);
  useEffect(() => {
    let ignore = false;
    setClientSecret('');
    if (planInfo.priceId) {
      createCustomerAndSubscription().then(result => {
        !ignore && setClientSecret(result);
      });
    }
    return () => {
      ignore = true;
      setClientSecret('');
    };
  }, [createCustomerAndSubscription, planInfo.priceId]);
  const {
    trackFacebookEvent,
    trackGoogleEvent,
    trackPinterestEvent
  } = useAnalytics();
  const setProcessingModalStatus = useSetAtom(processingModalStatusAtom);
  const handleProcessingStatus = useCallback((status: boolean) => {
    setProcessingModalStatus(status);
  }, [setProcessingModalStatus]);
  const setStripeSubscriptionActive = useSetAtom(stripeSubscriptionWithPersistence);
  const analyticsCombinedParameters = useMemo(() => ({
    value: planInfo.fullPriceDiscount.toFixed(2),
    currency: planInfo.currency
  }), [planInfo.currency, planInfo.fullPriceDiscount]);
  const onSuccess = useCallback(async ({
    paymentMethod,
    paymentSystem
  }: {
    paymentMethod: string;
    paymentSystem?: 'stripe' | 'braintree';
  }) => {
    setStripeSubscriptionActive('subscriptionActive');
    handleProcessingStatus(false);
    const calculateLTV = BILLING_PERIOD_LTV_MAP[planInfo.billingPeriod] || undefined;
    trackFacebookEvent({
      eventName: 'Purchase',
      customData: {
        variant: getDataFromSessionStorage({
          store: 'authStore',
          key: 'variant'
        }),
        paymentMethod: paymentMethod,
        paymentSystem: paymentSystem ? paymentSystem : 'stripe'
      },
      options: {
        ...analyticsCombinedParameters,
        email: email,
        subscription_id: undefined,
        stripe_subscription_id: planInfo.priceId,
        predicted_ltv: getDataFromSessionStorage({
          store: 'authStore',
          key: 'variant'
        }) === 'variant18' ? calculateLTV : 80
      }
    });
    trackFacebookEvent({
      eventName: 'Subscribe',
      customData: {
        variant: getDataFromSessionStorage({
          store: 'authStore',
          key: 'variant'
        }),
        paymentMethod: paymentMethod,
        paymentSystem: paymentSystem ? paymentSystem : 'stripe'
      },
      options: {
        email: email
      }
    });
    twitterEvent('tw-oehe8-oehej', {
      email: email,
      ...analyticsCombinedParameters
    });
    trackPinterestEvent({
      eventName: 'Checkout',
      options: analyticsCombinedParameters
    });
    trackGoogleEvent({
      eventName: 'purchase_success',
      options: {
        product_id: planInfo.planName,
        payment_system: paymentSystem ? paymentSystem : 'stripe',
        payment_type: paymentMethod,
        campaign_name_manual: getDataFromSessionStorage({
          store: 'authStore',
          key: 'campaign'
        }) ?? 'undefined',
        email: email,
        ...analyticsCombinedParameters
      }
    });
    tikTokAdvancedMatching(email);
    tikTokEvent('CompletePayment', {
      content_id: planInfo.planName,
      quantity: 1,
      ...analyticsCombinedParameters
    });
  }, [analyticsCombinedParameters, handleProcessingStatus, planInfo.billingPeriod, planInfo.currency, planInfo.fullPriceDiscount, planInfo.planName, planInfo.priceId, setStripeSubscriptionActive, trackFacebookEvent, trackGoogleEvent, trackPinterestEvent, email]);
  const sendAddPaymentInfoEvents = useCallback(({
    paymentMethod
  }: {
    paymentMethod: string;
  }) => {
    trackFacebookEvent({
      eventName: 'AddPaymentInfo',
      customData: {
        variant: getDataFromSessionStorage({
          store: 'authStore',
          key: 'variant'
        }),
        paymentMethod: paymentMethod,
        paymentSystem: 'stripe'
      },
      options: {
        email: email
      }
    });
    twitterEvent('tw-oehe8-oehek', {
      email_address: email
    });
    trackPinterestEvent({
      eventName: 'AddToCart'
    });
    trackGoogleEvent({
      eventName: 'add_payment_info',
      options: {
        value: planInfo.fullPriceDiscount,
        payment_system: 'stripe',
        payment_method: paymentMethod
      }
    });
    tikTokAdvancedMatching(email);
    tikTokEvent('AddPaymentInfo', {
      content_id: planInfo.planName
    });
  }, [planInfo.fullPriceDiscount, planInfo.planName, trackFacebookEvent, trackGoogleEvent, trackPinterestEvent, email]);
  useEffect(() => {
    if (planInfo.priceId.length > 0 && clientSecret.length > 0) {
      trackGoogleEvent({
        eventName: 'checkout_methods_loaded'
      });
    }
  }, [clientSecret, planInfo.priceId.length, trackGoogleEvent]);
  if (planInfo.priceId.length === 0 || clientSecret === '') {
    return isCompactStyle ? <SkeletonMethodsCompact /> : <SkeletonMethods />;
  }
  return <div className={classNames(styles.selectorContainer, isCompactStyle && styles.selectorContainerCompact)} data-sentry-component="CheckoutSelector" data-sentry-source-file="index.tsx">
      {isCompactStyle ? <CompactSelector paymentMethods={filteredPaymentOptions} handleProcessingStatus={handleProcessingStatus} sendAddPaymentInfoEvents={sendAddPaymentInfoEvents} onSuccess={onSuccess} planInfo={planInfo} stripe={stripe} clientSecret={clientSecret} userEmail={email} /> : filteredPaymentOptions.map(paymentOption => <PaymentOptionTab paymentMethodResult={paymentMethodResult} onSelectPaymentMethod={handlePaymentMethodSelect} selected={activePaymentMethod === paymentOption.paymentMethodName} handleProcessingStatus={handleProcessingStatus} sendAddPaymentInfoEvents={sendAddPaymentInfoEvents} onSuccess={onSuccess} planInfo={planInfo} stripe={stripe} clientSecret={clientSecret} userEmail={email} key={paymentOption.paymentMethodName} {...paymentOption} />)}
    </div>;
};