import { useMutation } from '@tanstack/react-query';
import { useSetAtom } from 'jotai';
import { useCallback } from 'react';

import { createInvoice } from '@features/Stripe/api';
import { stripeWasPaymentTriedAtom } from '@features/Stripe/atoms';

export const useCreateInvoice = () => {
  const setCrossSailOffer = useSetAtom(stripeWasPaymentTriedAtom);
  const handleHideCrossSailOffer = useCallback(
    () =>
      setCrossSailOffer((previous) => ({
        ...previous,
        showCrossSailOffer: false,
      })),
    [setCrossSailOffer],
  );
  return useMutation({
    mutationFn: createInvoice,
    onSuccess: handleHideCrossSailOffer,
    onError: handleHideCrossSailOffer,
  });
};
