import classNames from 'classnames';
import type { ImageProps } from 'next/legacy/image';
import ImageTag from 'next/legacy/image';
import { IMAGE_SOURCE } from 'src/utils/constants';
import styles from './Image.module.scss';
export type TpImageProps = ImageProps & {
  className?: string;
};
const customLoader = ({
  src
}: {
  src: string;
}) => `${IMAGE_SOURCE}${src}`;
export const Image = (props: TpImageProps) => {
  const {
    src,
    className,
    ...otherProps
  } = props;
  return <span className={classNames(styles.container, className)} data-sentry-component="Image" data-sentry-source-file="index.tsx">
      <ImageTag src={src} loader={customLoader} {...otherProps} data-sentry-element="ImageTag" data-sentry-source-file="index.tsx" />
    </span>;
};