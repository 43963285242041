import * as Sentry from '@sentry/react';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements } from '@stripe/react-stripe-js';
import classNames from 'classnames';
import { useAtom } from 'jotai/index';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { UiKitLoadingButton } from 'src/components/common/ui-kit-loading-button';
import { useAnalytics } from '@features/Analytics';
import { stripePaymentErrorAtom } from '@features/Stripe/atoms';
import { CARD_ELEMENT_OPTIONS, ELEMENT_OPTIONS } from '@features/Stripe/constants';
import { validateCardholderName } from '@features/Stripe/utils/validate-cardholder-name';
import { DebitCreditBlock } from '@features/Stripe/widgets/checkout-form/widgets/debit-credit-block';
import { FormattedMessage } from '@features/intl';
import styles from './card-payment-block.module.scss';
type Props = {
  stripe: any;
  clientSecret: string;
  minimalStyle?: boolean;
  handleAddPaymentInfoEventsSend: ({
    paymentMethod
  }: {
    paymentMethod: string;
  }) => void;
  handleProcessingStatus: (status: boolean) => void;
  handleSuccessPayment: ({
    paymentMethod
  }: {
    paymentMethod: string;
  }) => Promise<void>;
  userEmail: string;
};
export const CardPaymentBlock = ({
  stripe,
  clientSecret,
  handleAddPaymentInfoEventsSend,
  handleSuccessPayment,
  handleProcessingStatus,
  userEmail,
  minimalStyle
}: Props) => {
  const elements = useElements();
  const {
    trackGoogleEvent
  } = useAnalytics();
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [name, setName] = useState('');
  const [userStartEnteringCardInfo, setUserStartEnteringCardInfo] = useState(false);
  const [_, setPaymentError] = useAtom(stripePaymentErrorAtom);
  const handleUserStartEnteringCardInfo = useCallback(() => setUserStartEnteringCardInfo(true), []);
  const handleNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value.toUpperCase();
    setName(newValue);
    event.target.value.length > 0 && event.target.value.length < 2 && handleUserStartEnteringCardInfo();
  }, [handleUserStartEnteringCardInfo]);
  const handleSuccessPaymentCallback = useCallback(async () => {
    await handleSuccessPayment({
      paymentMethod: 'card'
    });
  }, [handleSuccessPayment]);
  const memorizedCardholderNameValidation = useMemo(() => {
    const isValidName = validateCardholderName(name);
    if (name.length === 0) {
      setMessage('');
      return false;
    }
    if (isValidName && name.length > 0) {
      setMessage('');
      return true;
    } else {
      setMessage('Please enter the full name and surname');
      return false;
    }
  }, [name]);
  const checkIsPrepaidCard = useCallback(async () => {
    if (!stripe || !elements) {
      return;
    }
    const card = elements.getElement(CardNumberElement);
    try {
      const {
        paymentMethod
      } = await stripe.createPaymentMethod({
        type: 'card',
        card: card,
        billing_details: {
          email: userEmail,
          name: minimalStyle ? undefined : name
        }
      });
      if (paymentMethod?.card.funding === 'prepaid') {
        setMessage('Prepaid cards are not supported. Please use a different payment method.');
        return 'prepaid';
      }
      return paymentMethod;
    } catch (error) {
      console.error('Error creating payment method:', error);
      throw error;
    }
  }, [elements, minimalStyle, name, stripe, userEmail]);
  useEffect(() => {
    if (userStartEnteringCardInfo) {
      handleAddPaymentInfoEventsSend({
        paymentMethod: 'card'
      });
    }
  }, [handleAddPaymentInfoEventsSend, userStartEnteringCardInfo]);
  useEffect(() => {
    if (elements) {
      const element = elements.getElement(CardNumberElement);
      element !== null && element.on('ready', () => {
        setIsLoading(false);
      });
    }
  }, [elements]);
  const handleSubmit = useCallback(async (event: any) => {
    event.preventDefault();
    setIsLoading(true);
    handleProcessingStatus(true);
    trackGoogleEvent({
      eventName: 'add_payment_info_chosen'
    });
    if (!stripe || !elements) {
      setIsLoading(false);
      handleProcessingStatus(false);
      return;
    }
    if ((await checkIsPrepaidCard()) === 'prepaid') {
      setIsLoading(false);
      handleProcessingStatus(false);
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const card = elements.getElement(CardNumberElement);
    const cardExpiry = elements.getElement(CardExpiryElement);
    const cardCvc = elements.getElement(CardCvcElement);
    if (card == null) {
      setIsLoading(false);
      handleProcessingStatus(false);
      return;
    }

    // Use card Element to tokenize payment details
    const {
      error,
      paymentIntent
    } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: card,
        billing_details: {
          email: userEmail,
          name: minimalStyle ? undefined : name
        }
      }
    });
    card?.blur();
    cardExpiry?.blur();
    cardCvc?.blur();
    if (error) {
      Sentry.captureException(error);
      trackGoogleEvent({
        eventName: 'payment_error',
        options: {
          errorCode: error?.code,
          errorDeclineCode: error?.decline_code,
          paymentMethod: 'card',
          paymentSystem: 'Stripe'
        }
      });
      setMessage((error.message as string));
      setIsLoading(false);
      setPaymentError(previous => ({
        ...previous,
        declineCode: error.decline_code ?? '',
        errorCode: error.code,
        paymentMethod: 'card',
        paymentSystem: 'Stripe'
      }));
      handleProcessingStatus(false);
    } else if (paymentIntent && paymentIntent.status === 'succeeded') {
      await handleSuccessPaymentCallback();
    } else {
      setMessage('Unexpected state');
      setIsLoading(false);
      handleProcessingStatus(false);
    }
  }, [checkIsPrepaidCard, clientSecret, elements, handleProcessingStatus, handleSuccessPaymentCallback, minimalStyle, name, setPaymentError, stripe, trackGoogleEvent, userEmail]);
  return <div className={classNames(styles.cardBlockContainer, minimalStyle && styles.minimal)} data-sentry-component="CardPaymentBlock" data-sentry-source-file="index.tsx">
      <DebitCreditBlock minimalStyle={minimalStyle} data-sentry-element="DebitCreditBlock" data-sentry-source-file="index.tsx" />
      {!minimalStyle && <p className={styles.cardInfo}>
          <FormattedMessage defaultMessage="Card info" id="Onboarding.Checkout.Card" />
        </p>}
      <div className={styles.cardContainer}>
        <div className={styles.cardElement}>
          <CardNumberElement onChange={event => !event.empty && handleUserStartEnteringCardInfo()} id="cardNumber" options={CARD_ELEMENT_OPTIONS} data-sentry-element="CardNumberElement" data-sentry-source-file="index.tsx" />
        </div>
        {!minimalStyle && <div className={styles.nameElement}>
            <input className={styles.nameInput} id="name" required style={{
          color: !memorizedCardholderNameValidation ? '#FF2E00' : '#302C28'
        }} type="text" placeholder="Cardholder Name" value={name} onChange={handleNameChange} />
          </div>}
        <div className={styles.expiredAndCvc}>
          <div className={styles.expiredElement}>
            <CardExpiryElement id="expiry" options={ELEMENT_OPTIONS} onChange={event => !event.empty && handleUserStartEnteringCardInfo()} data-sentry-element="CardExpiryElement" data-sentry-source-file="index.tsx" />
          </div>
          <div className={styles.cvcElement}>
            <CardCvcElement id="cvc" onChange={event => !event.empty && handleUserStartEnteringCardInfo()} options={ELEMENT_OPTIONS} data-sentry-element="CardCvcElement" data-sentry-source-file="index.tsx" />
          </div>
        </div>
      </div>
      {message && <span className={styles.message}>{message}</span>}
      <UiKitLoadingButton className={styles.proceed} data-testid="stripe-submit-button" disabled={isLoading || !stripe || !memorizedCardholderNameValidation && !minimalStyle} id="submit" onClick={handleSubmit} type="submit" loading={isLoading} data-sentry-element="UiKitLoadingButton" data-sentry-source-file="index.tsx">
        {minimalStyle ? 'Continue with card' : <FormattedMessage id="Onboarding.Checkout.Button" defaultMessage="Continue" />}
      </UiKitLoadingButton>
    </div>;
};
CardPaymentBlock.displayName = 'CardPaymentBlock';