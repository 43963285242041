import * as Sentry from '@sentry/react';
import { PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import { useSetAtom } from 'jotai';
import { memo, useCallback } from 'react';
import { useAnalytics } from '@features/Analytics';
import { stripePaymentErrorAtom } from '@features/Stripe/atoms';
import { usePaymentRequest } from '@features/Stripe/hooks';
type Props = {
  planInfo: {
    planName: string;
    fullPriceDiscount: number;
    currency: string;
    priceId: string;
  };
  height?: number;
  borderRadius?: number;
  stripe: any;
  clientSecret: string;
  handleSuccessPayment: ({
    paymentMethod
  }: {
    paymentMethod: string;
  }) => Promise<void>;
  // handleStatusMessage: (message: string) => void;
  handleAddPaymentInfoEventsSend: ({
    paymentMethod
  }: {
    paymentMethod: string;
  }) => void;
};
export const PaymentRequestButton = memo(({
  stripe,
  clientSecret,
  planInfo,
  handleSuccessPayment,
  height = 48,
  borderRadius,
  // handleStatusMessage,
  handleAddPaymentInfoEventsSend
}: Props) => {
  const setPaymentError = useSetAtom(stripePaymentErrorAtom);
  const {
    trackGoogleEvent
  } = useAnalytics();
  const {
    paymentRequest,
    paymentMethodResult
  } = usePaymentRequest({
    stripe,
    planInfo
  });
  const handleSuccessPaymentCallback = useCallback(async () => {
    await handleSuccessPayment({
      paymentMethod: paymentMethodResult ?? ''
    });
  }, [handleSuccessPayment, paymentMethodResult]);
  if (paymentRequest) {
    paymentRequest.on('paymentmethod', async (event: any) => {
      // Confirm the PaymentIntent without handling potential next actions (yet).
      const {
        paymentIntent,
        error: confirmError
      } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: event.paymentMethod.id
      }, {
        handleActions: false
      });
      if (confirmError) {
        // Report to the browser that the payment failed, prompting it to
        // re-show the payment interface, or show an error message and close
        // the payment interface.
        Sentry.captureException(confirmError);
        trackGoogleEvent({
          eventName: 'payment_error',
          options: {
            errorCode: confirmError?.code,
            errorDeclineCode: confirmError?.decline_code,
            paymentMethod: 'ApplePay/GooglePay',
            paymentSystem: 'Stripe'
          }
        });
        setPaymentError(previous => ({
          ...previous,
          declineCode: confirmError.decline_code ?? '',
          errorCode: confirmError.code,
          paymentSystem: 'Stripe',
          paymentMethod: event.paymentMethod.id ?? 'ApplePay/GooglePay'
        }));
        event.complete('fail');
      } else {
        // Report to the browser that the confirmation was successful, prompting
        // it to close the browser payment method collection interface.
        event.complete('success');

        // Check if the PaymentIntent requires any actions and, if so, let Stripe.js
        // handle the flow. If using an API version older than "2019-02-11"
        // instead check for: `paymentIntent.status === "requires_source_action"`.
        if (paymentIntent.status === 'requires_action') {
          // Let Stripe.js handle the rest of the payment flow.
          const {
            error
          } = await stripe.confirmCardPayment(clientSecret);
          if (error) {
            // The payment failed -- ask your customer for a new payment method.
            // handleStatusMessage('Try again or use different payment method');
            Sentry.captureException(error);
            trackGoogleEvent({
              eventName: 'payment_error',
              options: {
                errorCode: error?.code,
                errorDeclineCode: error?.decline_code,
                paymentMethod: 'ApplePay/GooglePay',
                paymentSystem: 'Stripe'
              }
            });
            setPaymentError(previous => ({
              ...previous,
              declineCode: error.decline_code ?? '',
              errorCode: error.code,
              paymentSystem: 'Stripe',
              paymentMethod: event.paymentMethod.id ?? 'ApplePay/GooglePay'
            }));
          } else {
            // The payment has succeeded -- show a success message to your customer.
            await handleSuccessPaymentCallback();
          }
        } else {
          // The payment has succeeded -- show a success message to your customer.
          await handleSuccessPaymentCallback();
        }
      }
    });
  }
  return paymentRequest ? <div style={{
    borderRadius: borderRadius,
    overflow: 'hidden'
  }}>
        <PaymentRequestButtonElement onClick={() => handleAddPaymentInfoEventsSend({
      paymentMethod: paymentMethodResult ?? 'ApplePay/GooglePay'
    })} options={{
      paymentRequest: paymentRequest,
      style: {
        paymentRequestButton: {
          height: `${height}px`
        }
      }
    }} />
      </div> : null;
});
PaymentRequestButton.displayName = 'PaymentRequestButton';