import { useState } from 'react';

import { useTimer } from 'src/utils/hooks/use-timer';

const leadingZero = (numerous: number) =>
  numerous < 10 ? `0${numerous}` : `${numerous}`;

const getReturnValues = (countDown: number) => {
  const hours = leadingZero(
    Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
  );
  const minutes = leadingZero(
    Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60)),
  );
  const seconds = leadingZero(Math.floor((countDown % (1000 * 60)) / 1000));

  return [hours, minutes, seconds];
};

type TpUseCountdown = {
  targetDate: number;
  onComplete: () => void;
};

export const useCountdown = ({ targetDate, onComplete }: TpUseCountdown) => {
  const [countDown, setCountDown] = useState(targetDate - Date.now());

  useTimer({
    delay: 1000,
    startImmediately: true,
    callback: (time) => {
      const currentTime = countDown - time;
      if (currentTime <= 0) {
        onComplete();
      } else {
        setCountDown(currentTime);
      }
    },
  });

  return getReturnValues(countDown);
};
