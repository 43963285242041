import { atom } from 'jotai/index';

export const actionTypes = {
  SHOW_MODAL_LIMITED_UPSELL_OFFER: 'SHOW_MODAL_LIMITED_UPSELL_OFFER',
  HIDE_MODAL_LIMITED_UPSELL_OFFER: 'HIDE_MODAL_LIMITED_UPSELL_OFFER',
  SHOW_MODAL_UPSELL_OFFER: 'SHOW_MODAL_UPSELL_OFFER',
  HIDE_MODAL_UPSELL_OFFER: 'HIDE_MODAL_UPSELL_OFFER',
  SHOW_MODAL_SUCCEED: 'SHOW_MODAL_SUCCEED',
  HIDE_MODAL_SUCCEED: 'HIDE_MODAL_SUCCEED',
};

const loadState = () => {
  try {
    const serializedState = localStorage.getItem('modalsState');
    if (serializedState === null) {
      return {
        modalLimitedUpsellOfferVisible: false,
        wasLimitedUpsellOfferShown: false,
        modalUpsellOfferVisible: false,
        wasUpsellSailOfferShown: false,
        modalSucceedVisible: false,
      };
    }
    return JSON.parse(serializedState);
  } catch {
    return {
      modalLimitedUpsellOfferVisible: false,
      wasLimitedUpsellOfferShown: false,
      modalUpsellOfferVisible: false,
      wasUpsellSailOfferShown: false,
      modalSucceedVisible: false,
    };
  }
};

const saveState = (state: unknown) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('modalsState', serializedState);
  } catch (error) {
    console.log(error);
  }
};

export const initialModalState = atom({
  modalLimitedUpsellOfferVisible: false,
  wasLimitedUpsellOfferShown: false,
  modalUpsellOfferVisible: false,
  wasUpsellSailOfferShown: false,
  modalSucceedVisible: false,
});

export const stripeModalReducer = (
  state = loadState(),
  action: { type: string },
) => {
  let newState;
  switch (action.type) {
    case actionTypes.SHOW_MODAL_LIMITED_UPSELL_OFFER:
      newState = {
        ...state,
        modalLimitedUpsellOfferVisible: true,
        modalUpsellOfferVisible: false,
        modalSucceedVisible: false,
      };
      break;
    case actionTypes.HIDE_MODAL_LIMITED_UPSELL_OFFER:
      newState = {
        ...state,
        modalLimitedUpsellOfferVisible: false,
        wasLimitedUpsellOfferShown: true,
      };
      break;
    case actionTypes.SHOW_MODAL_UPSELL_OFFER:
      newState = {
        ...state,
        modalLimitedUpsellOfferVisible: false,
        wasLimitedUpsellOfferShown: true,
        modalUpsellOfferVisible: true,
        wasUpsellSailOfferShown: true,
        modalSucceedVisible: false,
      };
      break;
    case actionTypes.HIDE_MODAL_UPSELL_OFFER:
      newState = {
        ...state,
        modalUpsellOfferVisible: false,
        wasUpsellSailOfferShown: true,
      };
      break;
    case actionTypes.SHOW_MODAL_SUCCEED:
      newState = {
        ...state,
        modalLimitedUpsellOfferVisible: false,
        wasLimitedUpsellOfferShown: true,
        modalUpsellOfferVisible: false,
        wasUpsellSailOfferShown: true,
        modalSucceedVisible: true,
      };
      break;
    case actionTypes.HIDE_MODAL_SUCCEED:
      newState = { ...state, modalSucceedVisible: false };
      break;
    default:
      newState = state;
  }
  saveState(newState);
  return newState;
};
