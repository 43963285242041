export enum MobileOperatingSystem {
  ANDROID,
  IOS,
  UNKNOWN,
}

let mobileOperatingSystemMemoized: MobileOperatingSystem | undefined;

export function getMobileOperatingSystem() {
  if (mobileOperatingSystemMemoized != null) {
    return mobileOperatingSystemMemoized;
  }
  if (typeof navigator === 'undefined') {
    return MobileOperatingSystem.UNKNOWN;
  }

  const userAgent = navigator.userAgent;

  if (/android/i.test(userAgent)) {
    mobileOperatingSystemMemoized = MobileOperatingSystem.ANDROID;
  } else if (/iPod|iPhone|iPad/.test(userAgent)) {
    mobileOperatingSystemMemoized = MobileOperatingSystem.IOS;
  } else {
    mobileOperatingSystemMemoized = MobileOperatingSystem.UNKNOWN;
  }

  return mobileOperatingSystemMemoized;
}

export enum IosDeviceType {
  IPOD,
  IPHONE,
  IPAD,
  UNKNOWN,
}

let iosDeviceTypeMemoized: IosDeviceType | undefined;

export function getIosDeviceType() {
  if (iosDeviceTypeMemoized != null) {
    return iosDeviceTypeMemoized;
  }
  if (typeof navigator === 'undefined') {
    return IosDeviceType.UNKNOWN;
  }

  const userAgent = navigator.userAgent;

  if (/iPod/.test(userAgent)) {
    iosDeviceTypeMemoized = IosDeviceType.IPOD;
  } else if (/iPhone/.test(userAgent)) {
    iosDeviceTypeMemoized = IosDeviceType.IPHONE;
  } else if (/iPad/.test(userAgent)) {
    iosDeviceTypeMemoized = IosDeviceType.IPAD;
  } else {
    iosDeviceTypeMemoized = IosDeviceType.UNKNOWN;
  }

  return iosDeviceTypeMemoized;
}

export function iphoneVersion() {
  const iphoneHeight = window.screen.height;
  const iphoneWidth = window.screen.width;

  if (iphoneWidth === 428 && iphoneHeight === 926) {
    //iPhone 14 Plus //iPhone 12 Pro Max
    return true;
  } else if (iphoneWidth === 430 && iphoneHeight === 932) {
    //iPhone 14 Pro Max
    return true;
  } else if (iphoneWidth === 393 && iphoneHeight === 852) {
    //iPhone 14 Pro
    return true;
  } else if (iphoneWidth === 390 && iphoneHeight === 844) {
    //iPhone 14 //iPhone 13 //iPhone 13 Pro //iPhone 12 //iPhone 12 Pro
    return true;
  } else if (iphoneWidth === 375 && iphoneHeight === 667) {
    //iPhone SE 3rd gen //iPhone 8 //iPhone SE 2nd gen
    return false;
  } else if (iphoneWidth === 375 && iphoneHeight === 812) {
    //iPhone 13 mini //iPhone 12 mini //iPhone 11 Pro //iPhone XS //iPhone X
    return true;
  } else if (iphoneWidth === 428 && iphoneHeight === 926) {
    //iPhone 13 Pro Max
    return true;
  } else if (iphoneWidth === 414 && iphoneHeight === 896) {
    //iPhone 11 Pro Max //iPhone 11 //iPhone XR //iPhone XS Max
    return true;
  }
  return false;
}
