import Cookies from 'js-cookie';

import { post } from 'src/api/api';
import { ENDPOINT_MICROSERVICE, TEST_EVENT_CODE } from 'src/constants/api';

const pixelVariants = {
  '313267054006349': 'mimika',
  '1386433265600406': 'mimika_variant17',
  '919317399289097': 'mimika_web_v18',
};

type TpEventName =
  | 'AddToCart'
  | 'AddPaymentInfo'
  | 'ViewContent'
  | 'CompleteRegistration'
  | 'InitiateCheckout'
  | 'Subscribe'
  | 'Purchase';

type TpPostPath =
  | 'event_start_onboard'
  | 'event_completed_registration'
  | 'event_user_init_checkout'
  | 'event_add_to_cart'
  | 'event_add_payment_info'
  | 'event_purchase';

type TpPostOptions = {
  email?: string;
  subscription_id?: string | number;
  stripe_subscription_id?: string | number;
  first_name?: string;
  last_name?: string;
  date_of_birth?: string;
  gender?: string;
  currency?: string;
};

type TpFacebookEvent = {
  eventName: TpEventName;
  postPath: TpPostPath;
  options?: { [key: string]: any };
  eventId?: string;
  postOptions?: TpPostOptions;
  variant?: string;
  sendS2Sevent?: boolean;
};

export const fbAnalytics = (props: TpFacebookEvent) => {
  const {
    eventName,
    eventId: eventIdProperty,
    options = {},
    postPath,
    postOptions = {},
    sendS2Sevent = true,
  } = props;
  const externalId = Cookies.get('extId');
  const fbp = Cookies.get('_fbp');
  const fbc = Cookies.get('_fbc');
  const randomInt = Math.floor(Math.random() * 100_000_000);

  const eventId = eventIdProperty || `${eventName}.${randomInt}`;

  const sendAnalyticRequest = async () => {
    try {
      // @ts-ignore
      const pixelsArray = window?.fbq
        ?.getState()
        .pixels.map((pixel: any) => pixel.id);

      await Promise.all(
        pixelsArray.map(
          async (
            pixel: '313267054006349' | '1386433265600406' | '919317399289097',
          ) =>
            await post({
              endpoint: ENDPOINT_MICROSERVICE,
              path: postPath,
              body: {
                fbp,
                fbc,
                event_id: eventId,
                external_id: externalId,
                test_event_code: TEST_EVENT_CODE,
                origin: pixelVariants[pixel],
                ...postOptions,
              },
            }),
        ),
      );
    } catch (error) {
      console.error(error);
    }
  };

  // @ts-ignore
  window?.fbq?.('track', eventName, options, {
    eventID: eventId,
    extern_id: externalId,
  });

  (fbp || externalId) &&
    process.env.NEXT_PUBLIC_ENV === 'production' &&
    sendS2Sevent &&
    sendAnalyticRequest().then((r) => r);
};
