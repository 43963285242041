import { Instance, applySnapshot, types } from 'mobx-state-tree';

import { QuizStep } from 'src/constants/quiz';

import { TpSkinType } from '@features/Quiz/steps/recomendations-step';

export const QuizStoreModel = types
  .model('QuizStore')
  .props({
    country: types.maybe(types.string),
    countryCode: types.maybe(types.string),
    easilySpend: types.maybe(types.number),
    satisfied: types.maybe(types.number),
    worriesMost: types.maybe(types.number),
    focusOn: types.maybe(types.frozen(types.array(types.string))),
    focusOnTwo: types.maybe(types.frozen(types.array(types.string))),
    ageChanges: types.maybe(types.number),
    wrinkles: types.maybe(types.frozen(types.array(types.string))),
    changesFaced: types.maybe(types.frozen(types.array(types.string))),
    practiceCare: types.maybe(types.number),
    badHabits: types.maybe(types.frozen(types.array(types.string))),
    eatHabits: types.maybe(types.number),
    sugarHabits: types.maybe(types.number),
    sodiumHabits: types.maybe(types.number),
    vegetablesHabits: types.maybe(types.number),
    waterHabits: types.maybe(types.number),
    swellingHabits: types.maybe(types.number),
    sleepHabits: types.maybe(types.number),
    oilyShine: types.maybe(types.number),
    afterCleanFace: types.maybe(types.number),
    skinAcne: types.maybe(types.number),
    skinTexture: types.maybe(types.number),
    stress: types.maybe(types.number),
    skinColor: types.maybe(types.number),
    skinProblems: types.maybe(types.frozen(types.array(types.string))),
    age: types.maybe(types.number),
    faceShape: types.maybe(types.number),
    jawline: types.maybe(types.number),
    unhappy: types.maybe(types.number),
    plasticSurgery: types.maybe(types.number),
    darkCircles: types.maybe(types.number),
    lifestyle: types.maybe(types.number),
    work: types.maybe(types.number),
    afraidOfLosingBeauty: types.maybe(types.number),
    appearanceReason: types.maybe(types.number),
    trends: types.maybe(types.frozen(types.array(types.string))),
    hearAboutUs: types.maybe(types.frozen(types.array(types.string))),
    skincareGoals: types.maybe(types.frozen(types.array(types.string))),
    allergies: types.maybe(types.frozen(types.array(types.string))),
    discomfort: types.maybe(types.number),
    changesWorriesMost: types.maybe(types.frozen(types.array(types.string))),
    bonusChoose: types.maybe(types.number),
    innerAge: types.maybe(types.number),
    lastScreen: false,
    name: types.maybe(types.string),
    ageSimple: types.maybe(types.number),
    easilySpendSimple: types.maybe(types.number),
    excessiveWeight: types.maybe(types.number),
    facialCareSimple: types.maybe(types.number),
    desiredResults: types.maybe(types.number),
    medication: types.maybe(types.number),
    pregnant: types.maybe(types.number),
    skinCare: types.maybe(types.number),
    kids: types.maybe(types.number),
    fromHome: types.maybe(types.number),
    improveSleep: types.maybe(types.number),
    headaches: types.maybe(types.number),
    backAches: types.maybe(types.number),
    tension: types.maybe(types.number),
    behaviour: types.maybe(types.number),
    actionPlan: types.maybe(types.number),
    badHabitsSimple: types.maybe(types.frozen(types.array(types.string))),
    specificAreas: types.maybe(types.frozen(types.array(types.string))),
    specificActivities: types.maybe(types.frozen(types.array(types.string))),
    situation: types.maybe(types.frozen(types.array(types.string))),
    withMimika: types.maybe(types.frozen(types.array(types.string))),
    bestVersion: types.maybe(types.frozen(types.array(types.string))),
    mainMotivation: types.maybe(types.frozen(types.array(types.string))),
    wantExplore: types.maybe(types.frozen(types.array(types.string))),
    mentalWellness: types.maybe(types.frozen(types.array(types.string))),
  })
  .views((self) => ({
    getSingleOptionSelectStepValue: (step: QuizStep) => {
      switch (step) {
        case QuizStep.EASILY_SPEND:
          return self.easilySpend;
        case QuizStep.SATISFIED:
          return self.satisfied;
        case QuizStep.WORRIES_MOST:
          return self.worriesMost;
        case QuizStep.DARK_CIRCLES:
          return self.darkCircles;
        case QuizStep.SKIN_COLOR:
          return self.skinColor;
        case QuizStep.STRESS:
          return self.stress;
        case QuizStep.JAWLINE:
          return self.jawline;
        case QuizStep.UNHAPPY:
          return self.unhappy;
        case QuizStep.PLASTIC_SURGERY:
          return self.plasticSurgery;
        case QuizStep.AGE_CHANGES:
          return self.ageChanges;
        case QuizStep.PRACTICE_CARE:
          return self.practiceCare;
        case QuizStep.EAT_HABITS:
          return self.eatHabits;
        case QuizStep.SUGAR_HABITS:
          return self.sugarHabits;
        case QuizStep.SODIUM_HABITS:
          return self.sodiumHabits;
        case QuizStep.VEGETABLES_HABITS:
          return self.vegetablesHabits;
        case QuizStep.WATER_HABITS:
          return self.waterHabits;
        case QuizStep.SWELL_HABITS:
          return self.swellingHabits;
        case QuizStep.SLEEP_HABITS:
          return self.sleepHabits;
        case QuizStep.OILY_SHINE:
          return self.oilyShine;
        case QuizStep.AFTER_CLEAN_FACE:
          return self.afterCleanFace;
        case QuizStep.SKIN_ACNE:
          return self.skinAcne;
        case QuizStep.SKIN_TEXTURE:
          return self.skinTexture;
        case QuizStep.AGE:
          return self.age;
        case QuizStep.FACE_SHAPE:
          return self.faceShape;
        case QuizStep.LIFESTYLE:
          return self.lifestyle;
        case QuizStep.WORK:
          return self.work;
        case QuizStep.AFRAID_LOSE_BEAUTY:
        case QuizStep.AFRAID_LOSE_BEAUTY_BREAKS_TEST:
          return self.afraidOfLosingBeauty;
        case QuizStep.APPEARANCE_REASON:
        case QuizStep.APPEARANCE_REASON_BREAKS_TEST:
          return self.appearanceReason;
        case QuizStep.INNER_AGE:
          return self.innerAge;
        case QuizStep.AGE_SIMPLE:
          return self.ageSimple;
        case QuizStep.EASILY_SPEND_SIMPLE:
          return self.easilySpendSimple;
        case QuizStep.EXCESSIVE_WEIGHT_SIMPLE:
          return self.excessiveWeight;
        case QuizStep.FACIAL_CARE_SIMPLE:
          return self.facialCareSimple;
        case QuizStep.DESIRED_RESULTS_SIMPLE:
          return self.desiredResults;
        case QuizStep.MEDICATIONS_SIMPLE:
          return self.medication;
        case QuizStep.PREGNANT_SIMPLE:
          return self.pregnant;
        case QuizStep.SKIN_CARE_SIMPLE:
          return self.skinCare;
        case QuizStep.KIDS_SIMPLE:
          return self.kids;
        case QuizStep.WORK_FROM_HOME_SIMPLE:
          return self.fromHome;
        case QuizStep.IMPROVE_SLEEP_SIMPLE:
          return self.improveSleep;
        case QuizStep.HEADACHES_SIMPLE:
          return self.headaches;
        case QuizStep.BACK_ACHES_SIMPLE:
          return self.backAches;
        case QuizStep.TENSION_SIMPLE:
          return self.tension;
        case QuizStep.BEHAVIOUR_SIMPLE:
          return self.behaviour;
        case QuizStep.ACTION_PLAN_SIMPLE:
          return self.actionPlan;
        default:
          throw new Error('The step is not of type SINGLE_OPTION_SELECT');
      }
    },
    get easilySpendStepCompleted() {
      return self.easilySpend != null;
    },
    get satisfiedStepCompleted() {
      return self.satisfied != null;
    },
    get worriesMostStepCompleted() {
      return self.worriesMost != null;
    },
    get focusOnStepCompleted() {
      return self.focusOn != null && self.focusOn.length > 0;
    },
    get focusOnTwoStepCompleted() {
      return self.focusOnTwo != null && self.focusOnTwo.length > 0;
    },
    get ageChangesStepCompleted() {
      return self.ageChanges != null;
    },
    get wrinklesStepCompleted() {
      return self.wrinkles != null && self.wrinkles.length > 0;
    },
    get changesFacedStepCompleted() {
      return self.changesFaced != null && self.changesFaced.length > 0;
    },
    get practiceCareStepCompleted() {
      return self.practiceCare != null;
    },
    get badHabitsStepCompleted() {
      return self.badHabits != null && self.badHabits.length > 0;
    },
    get eatHabitsStepCompleted() {
      return self.eatHabits != null;
    },
    get sugarHabitsStepCompleted() {
      return self.sugarHabits != null;
    },
    get sodiumHabitsStepCompleted() {
      return self.sodiumHabits != null;
    },
    get vegetablesHabitsStepCompleted() {
      return self.vegetablesHabits != null;
    },
    get waterHabitsStepCompleted() {
      return self.waterHabits != null;
    },
    get swellingHabitsStepCompleted() {
      return self.swellingHabits != null;
    },
    get sleepHabitsStepCompleted() {
      return self.sleepHabits != null;
    },
    get oilyShineStepCompleted() {
      return self.oilyShine != null;
    },
    get afterCleanFaceStepCompleted() {
      return self.afterCleanFace != null;
    },
    get skinAcneStepCompleted() {
      return self.skinAcne != null;
    },
    get skinTextureStepCompleted() {
      return self.skinTexture != null;
    },
    get stressStepCompleted() {
      return self.stress != null;
    },
    get skinColorStepCompleted() {
      return self.skinColor != null;
    },
    get skinProblemsStepCompleted() {
      return self.skinProblems != null;
    },
    get ageStepCompleted() {
      return self.age != null;
    },
    get faceShapeStepCompleted() {
      return self.faceShape != null;
    },
    get jawlineStepCompleted() {
      return self.jawline != null;
    },
    get unhappyStepCompleted() {
      return self.unhappy != null;
    },
    get plasticSurgeryStepCompleted() {
      return self.plasticSurgery != null;
    },
    get darkCirclesStepCompleted() {
      return self.darkCircles != null;
    },
    get lifestyleStepCompleted() {
      return self.lifestyle != null;
    },
    get workStepCompleted() {
      return self.work != null;
    },
    get afraidOfLosingBeautyStepCompleted() {
      return self.afraidOfLosingBeauty != null;
    },
    get appearanceReasonStepCompleted() {
      return self.appearanceReason != null;
    },
    get trendsStepCompleted() {
      return self.trends != null && self.trends.length > 0;
    },
    get hearAboutUsStepCompleted() {
      return self.hearAboutUs != null && self.hearAboutUs.length > 0;
    },
    get skincareGoalsStepCompleted() {
      return self.skincareGoals != null && self.skincareGoals.length > 0;
    },
    get allergiesStepCompleted() {
      return self.allergies != null && self.allergies.length > 0;
    },
    get discomfortStepCompleted() {
      return self.discomfort != null;
    },
    get changesWorriesMostStepCompleted() {
      return self.changesWorriesMost != null;
    },
    get bonusChooseStepCompleted() {
      return self.bonusChoose != null;
    },
    get innerAgeStepCompleted() {
      return self.innerAge != null;
    },
    get nameCompleted() {
      return self.name != null;
    },
    get ageSimpleStepCompleted() {
      return self.ageSimple != null;
    },
    get easilySpendSimpleStepCompleted() {
      return self.easilySpendSimple != null;
    },
    get excessiveWeightStepCompleted() {
      return self.excessiveWeight != null;
    },
    get facialCareSimpletStepCompleted() {
      return self.facialCareSimple != null;
    },
    get desiredResultsStepCompleted() {
      return self.desiredResults != null;
    },
    get medicationStepCompleted() {
      return self.medication != null;
    },
    get pregnantStepCompleted() {
      return self.pregnant != null;
    },
    get skinCareStepCompleted() {
      return self.skinCare != null;
    },
    get kidsStepCompleted() {
      return self.kids != null;
    },
    get fromHomeStepCompleted() {
      return self.fromHome != null;
    },
    get improveSleepStepCompleted() {
      return self.improveSleep != null;
    },
    get headachesStepCompleted() {
      return self.headaches != null;
    },
    get backAchesStepCompleted() {
      return self.backAches != null;
    },
    get tensionStepCompleted() {
      return self.tension != null;
    },
    get behaviourStepCompleted() {
      return self.behaviour != null;
    },
    get actionPlanStepCompleted() {
      return self.actionPlan != null;
    },
    get badHabitsSimpleStepCompleted() {
      return self.badHabitsSimple != null && self.badHabitsSimple.length > 0;
    },
    get specificAreasStepCompleted() {
      return self.specificAreas != null && self.specificAreas.length > 0;
    },
    get specificActivitiesStepCompleted() {
      return (
        self.specificActivities != null && self.specificActivities.length > 0
      );
    },
    get situationStepCompleted() {
      return self.situation != null && self.situation.length > 0;
    },
    get withMimikaStepCompleted() {
      return self.withMimika != null && self.withMimika.length > 0;
    },
    get bestVersionStepCompleted() {
      return self.bestVersion != null && self.bestVersion.length > 0;
    },
    get mainMotivationStepCompleted() {
      return self.mainMotivation != null && self.mainMotivation.length > 0;
    },
    get wantExploreStepCompleted() {
      return self.wantExplore != null && self.wantExplore.length > 0;
    },
    get mentalWellnessStepCompleted() {
      return self.mentalWellness != null && self.mentalWellness.length > 0;
    },
  }))
  .views((self) => ({
    getMeaningAge: () => {
      switch (self.age) {
        case 0:
          return 18;
        case 1:
          return 25;
        case 2:
          return 35;
        case 3:
          return 45;
        case 4:
          return 55;
        case 5:
          return 65;
        default:
          return 45;
      }
    },
    getSkinColor: () => {
      switch (self.skinColor) {
        case 0:
          return 'Sand or {br} Warm ivory';
        case 1:
          return 'Fair or {br} Pale ivory';
        case 2:
          return 'Olive or {br} Light brown';
        case 3:
          return 'Brown or {br} Black brown';
        default:
          return 'Sand or {br} Warm ivory';
      }
    },
    getSkinType: () => {
      let dry: { label: TpSkinType; value: number } = {
        label: 'Dry',
        value: 0,
      };
      let combo: { label: TpSkinType; value: number } = {
        label: 'Combination',
        value: 0,
      };
      let oil: { label: TpSkinType; value: number } = {
        label: 'Oily',
        value: 0,
      };
      let normal: { label: TpSkinType; value: number } = {
        label: 'Normal',
        value: 0,
      };
      switch (self.oilyShine) {
        case 0:
          dry.value += 1;
          break;
        case 1:
          normal.value += 1;
          break;
        case 2:
          combo.value += 1;
          break;
        case 3:
          oil.value += 1;
          break;
      }
      switch (self.afterCleanFace) {
        case 0:
          normal.value += 1;
          break;
        case 1:
          combo.value += 1;
          break;
        case 2:
          dry.value += 1;
          break;
        case 3:
          oil.value += 1;
          break;
      }
      switch (self.skinAcne) {
        case 0:
          normal.value += 1;
          dry.value += 1;
          break;
        case 1:
          oil.value += 1;
          break;
        case 2:
          combo.value += 1;
          break;
      }
      switch (self.skinTexture) {
        case 0:
          normal.value += 1;
          oil.value += 1;
          break;
        case 1:
          dry.value += 1;
          combo.value += 1;
          break;
      }
      switch (self.stress) {
        case 0:
          normal.value += 1;
          oil.value += 1;
          break;
        case 1:
          dry.value += 1;
          combo.value += 1;
          break;
      }

      // eslint-disable-next-line unicorn/no-array-reduce
      const skinTypeMaxWithMaxValue = [dry, combo, oil, normal].reduce(
        (
          accum: { label: TpSkinType; value: number },
          current: { label: TpSkinType; value: number },
        ) => {
          if (current.value > accum.value) {
            return current;
          } else if (current.value === accum.value) {
            return combo;
          } else {
            return accum;
          }
        },
        { label: 'Normal', value: -1 },
      );
      return skinTypeMaxWithMaxValue.label;
    },
  }))
  .actions((self) => ({
    setCountry: (value: string) => {
      self.country = value;
    },
    setCountryCode: (value: string) => {
      self.countryCode = value;
    },
    setEasilySpend: (value: number) => {
      self.easilySpend = value;
    },
    setSatisfied: (value: number) => {
      self.satisfied = value;
    },
    setWorriesMost: (value: number) => {
      self.worriesMost = value;
    },
    setFocusOn: (value?: readonly string[]) => {
      self.focusOn = value;
    },
    setFocusOnTwo: (value?: readonly string[]) => {
      self.focusOnTwo = value;
    },
    setAgeChanges: (value: number) => {
      self.ageChanges = value;
    },
    setWrinkles: (value?: readonly string[]) => {
      self.wrinkles = value;
    },
    setChangesFaced: (value?: readonly string[]) => {
      self.changesFaced = value;
    },
    setPracticeCare: (value: number) => {
      self.practiceCare = value;
    },
    setBadHabits: (value?: readonly string[]) => {
      self.badHabits = value;
    },
    setEatHabits: (value: number) => {
      self.eatHabits = value;
    },
    setSugarHabits: (value: number) => {
      self.sugarHabits = value;
    },
    setSodiumHabits: (value: number) => {
      self.sodiumHabits = value;
    },
    setVegetablesHabits: (value: number) => {
      self.vegetablesHabits = value;
    },
    setWaterHabits: (value: number) => {
      self.waterHabits = value;
    },
    setSwellHabits: (value: number) => {
      self.swellingHabits = value;
    },
    setSleepHabits: (value: number) => {
      self.sleepHabits = value;
    },
    setOilyShine: (value: number) => {
      self.oilyShine = value;
    },
    setAfterCleanFace: (value: number) => {
      self.afterCleanFace = value;
    },
    setSkinAcne: (value: number) => {
      self.skinAcne = value;
    },
    setSkinTexture: (value: number) => {
      self.skinTexture = value;
    },
    setSkinColor: (value: number) => {
      self.skinColor = value;
    },
    setSkinProblems: (value?: readonly string[]) => {
      self.skinProblems = value;
    },
    setStress: (value: number) => {
      self.stress = value;
    },
    setAge: (value: number) => {
      self.age = value;
    },
    setFaceShape: (value: number) => {
      self.faceShape = value;
    },
    setJawline: (value: number) => {
      self.jawline = value;
    },
    setUnhappy: (value: number) => {
      self.unhappy = value;
    },
    setPlasticSurgery: (value: number) => {
      self.plasticSurgery = value;
    },
    setDarkCircles: (value: number) => {
      self.darkCircles = value;
    },
    setLifestyle: (value: number) => {
      self.lifestyle = value;
    },
    setWork: (value: number) => {
      self.work = value;
    },
    setAfraidOfLosingBeauty: (value: number) => {
      self.afraidOfLosingBeauty = value;
    },
    setAppearanceReason: (value: number) => {
      self.appearanceReason = value;
    },
    setTrends: (value?: readonly string[]) => {
      self.trends = value;
    },
    setHearAboutUs: (value?: readonly string[]) => {
      self.hearAboutUs = value;
    },
    setAllergies: (value?: readonly string[]) => {
      self.allergies = value;
    },
    setSkincareGoals: (value?: readonly string[]) => {
      self.skincareGoals = value;
    },
    setDiscomfort: (value: number) => {
      self.discomfort = value;
    },
    setChangesWorriesMost: (value?: readonly string[]) => {
      self.changesWorriesMost = value;
    },
    setBonusChoose: (value: number) => {
      self.bonusChoose = value;
    },
    setInnerAge: (value: number) => {
      self.innerAge = value;
    },
    setLastScreen: (value: boolean) => {
      self.lastScreen = value;
    },
    setName: (value: string) => {
      self.name = value;
    },
    setAgeSimple: (value: number) => {
      self.ageSimple = value;
    },
    setEasilySpendSimple: (value: number) => {
      self.easilySpendSimple = value;
    },
    setExcessiveWeight: (value: number) => {
      self.excessiveWeight = value;
    },
    setFacialCareSimple: (value: number) => {
      self.facialCareSimple = value;
    },
    setDesiredResults: (value: number) => {
      self.desiredResults = value;
    },
    setMedication: (value: number) => {
      self.medication = value;
    },
    setPregnant: (value: number) => {
      self.pregnant = value;
    },
    setSkinCare: (value: number) => {
      self.skinCare = value;
    },
    setFromHome: (value: number) => {
      self.fromHome = value;
    },
    setImproveSleep: (value: number) => {
      self.improveSleep = value;
    },
    setHeadaches: (value: number) => {
      self.headaches = value;
    },
    setBackAches: (value: number) => {
      self.backAches = value;
    },
    setKids: (value: number) => {
      self.kids = value;
    },
    setTension: (value: number) => {
      self.tension = value;
    },
    setBehaviour: (value: number) => {
      self.behaviour = value;
    },
    setActionPlan: (value: number) => {
      self.actionPlan = value;
    },
    setBadHabitsSimple: (value?: readonly string[]) => {
      self.badHabitsSimple = value;
    },
    setSpecificAreas: (value?: readonly string[]) => {
      self.specificAreas = value;
    },
    setSpecificActivities: (value?: readonly string[]) => {
      self.specificActivities = value;
    },
    setSituation: (value?: readonly string[]) => {
      self.situation = value;
    },
    setWithMimika: (value?: readonly string[]) => {
      self.withMimika = value;
    },
    setBestVersion: (value?: readonly string[]) => {
      self.bestVersion = value;
    },
    setMainMotivation: (value?: readonly string[]) => {
      self.mainMotivation = value;
    },
    setWantExplore: (value?: readonly string[]) => {
      self.wantExplore = value;
    },
    setMentalWellness: (value?: readonly string[]) => {
      self.mentalWellness = value;
    },
    fillInAllSteps: () => {
      if (!self.easilySpendStepCompleted) {
        self.easilySpend = 0;
      }
      if (!self.satisfiedStepCompleted) {
        self.satisfied = 0;
      }
      if (!self.worriesMostStepCompleted) {
        self.worriesMost = 0;
      }
      if (!self.focusOnStepCompleted) {
        self.focusOn = ['drooping_eyelids'];
      }
      if (!self.focusOnTwoStepCompleted) {
        self.focusOnTwo = ['double_chin'];
      }
      if (!self.ageChangesStepCompleted) {
        self.ageChanges = 0;
      }
      if (!self.wrinklesStepCompleted) {
        self.wrinkles = ['nasolabial_fold'];
      }
      if (!self.changesFacedStepCompleted) {
        self.changesFaced = ['tired_look'];
      }
      if (!self.practiceCareStepCompleted) {
        self.practiceCare = 0;
      }
      if (!self.badHabitsStepCompleted) {
        self.badHabits = ['smoking'];
      }
      if (!self.eatHabitsStepCompleted) {
        self.eatHabits = 0;
      }
      if (!self.sugarHabitsStepCompleted) {
        self.sugarHabits = 0;
      }
      if (!self.sodiumHabitsStepCompleted) {
        self.sodiumHabits = 0;
      }
      if (!self.vegetablesHabitsStepCompleted) {
        self.vegetablesHabits = 0;
      }
      if (!self.waterHabitsStepCompleted) {
        self.waterHabits = 0;
      }
      if (!self.swellingHabitsStepCompleted) {
        self.swellingHabits = 0;
      }
      if (!self.sleepHabitsStepCompleted) {
        self.sleepHabits = 0;
      }
      if (!self.oilyShineStepCompleted) {
        self.oilyShine = 0;
      }
      if (!self.afterCleanFaceStepCompleted) {
        self.afterCleanFace = 0;
      }
      if (!self.skinAcneStepCompleted) {
        self.skinAcne = 0;
      }
      if (!self.skinTextureStepCompleted) {
        self.skinTexture = 0;
      }
      if (!self.stressStepCompleted) {
        self.stress = 0;
      }
      if (!self.skinColorStepCompleted) {
        self.skinColor = 0;
      }
      if (!self.skinProblemsStepCompleted) {
        self.skinProblems = ['sensitive_dry'];
      }
      if (!self.ageStepCompleted) {
        self.age = 0;
      }
      if (!self.faceShapeStepCompleted) {
        self.faceShape = 0;
      }
      if (!self.jawlineStepCompleted) {
        self.jawline = 0;
      }
      if (!self.unhappyStepCompleted) {
        self.unhappy = 0;
      }
      if (!self.plasticSurgeryStepCompleted) {
        self.plasticSurgery = 0;
      }
      if (!self.darkCirclesStepCompleted) {
        self.darkCircles = 0;
      }
      if (!self.lifestyleStepCompleted) {
        self.lifestyle = 0;
      }
      if (!self.workStepCompleted) {
        self.work = 0;
      }
      if (!self.afraidOfLosingBeautyStepCompleted) {
        self.afraidOfLosingBeauty = 0;
      }
      if (!self.appearanceReasonStepCompleted) {
        self.appearanceReason = 0;
      }
      if (!self.hearAboutUsStepCompleted) {
        self.hearAboutUs = ['tv'];
      }
      if (!self.trendsStepCompleted) {
        self.trends = ['mewing'];
      }
      if (!self.skincareGoalsStepCompleted) {
        self.skincareGoals = ['moisturizing'];
      }
      if (!self.allergiesStepCompleted) {
        self.allergies = ['fragrances'];
      }
      if (!self.discomfortStepCompleted) {
        self.discomfort = 0;
      }
      if (!self.changesWorriesMostStepCompleted) {
        self.changesWorriesMost = ['facial_drooping'];
      }
      if (!self.bonusChooseStepCompleted) {
        self.bonusChoose = 0;
      }
      if (!self.innerAgeStepCompleted) {
        self.innerAge = 1;
      }
      if (!self.nameCompleted) {
        self.name = 'Jane';
      }
      if (!self.ageSimpleStepCompleted) {
        self.ageSimple = 0;
      }
      if (!self.easilySpendSimpleStepCompleted) {
        self.easilySpendSimple = 0;
      }
      if (!self.excessiveWeightStepCompleted) {
        self.excessiveWeight = 0;
      }
      if (!self.facialCareSimpletStepCompleted) {
        self.facialCareSimple = 0;
      }
      if (!self.desiredResultsStepCompleted) {
        self.desiredResults = 0;
      }
      if (!self.medicationStepCompleted) {
        self.medication = 0;
      }
      if (!self.pregnantStepCompleted) {
        self.pregnant = 0;
      }
      if (!self.skinCareStepCompleted) {
        self.skinCare = 0;
      }
      if (!self.kidsStepCompleted) {
        self.kids = 0;
      }
      if (!self.improveSleepStepCompleted) {
        self.improveSleep = 0;
      }
      if (!self.headachesStepCompleted) {
        self.headaches = 0;
      }
      if (!self.backAchesStepCompleted) {
        self.backAches = 0;
      }
      if (!self.fromHomeStepCompleted) {
        self.fromHome = 0;
      }
      if (!self.tensionStepCompleted) {
        self.tension = 0;
      }
      if (!self.behaviourStepCompleted) {
        self.behaviour = 0;
      }
      if (!self.actionPlanStepCompleted) {
        self.actionPlan = 0;
      }
      if (!self.badHabitsSimpleStepCompleted) {
        self.badHabitsSimple = ['smoking'];
      }
      if (!self.specificAreasStepCompleted) {
        self.specificAreas = ['cheekbones_and_temples'];
      }
      if (!self.specificActivitiesStepCompleted) {
        self.specificActivities = ['time_on_phone'];
      }
      if (!self.situationStepCompleted) {
        self.situation = ['marriage_relationship'];
      }
      if (!self.withMimikaStepCompleted) {
        self.withMimika = ['self_worth'];
      }
      if (!self.bestVersionStepCompleted) {
        self.bestVersion = ['full_of_energy'];
      }
      if (!self.mainMotivationStepCompleted) {
        self.mainMotivation = ['changing_how_i_look'];
      }
      if (!self.wantExploreStepCompleted) {
        self.wantExplore = ['femininity'];
      }
      if (!self.mentalWellnessStepCompleted) {
        self.mentalWellness = ['better_help'];
      }
    },
  }))
  .actions((self) => ({
    reset: () => {
      applySnapshot(self, {});
    },
  }));

export type QuizStoreType = Instance<typeof QuizStoreModel>;

export interface QuizStore extends QuizStoreType {}
