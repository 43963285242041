import { Children, cloneElement, isValidElement, memo, useEffect, useMemo } from 'react';
import { useABTestExperiment } from '@features/ABTesting';
import { useAnalytics } from '@features/Analytics';
type ABTestExperimentProps = {
  name: string;
  children: JSX.Element | JSX.Element[];
};
const ABTestExperimentCore = memo((props: ABTestExperimentProps & {
  experiment?: {
    name: string;
    case_name: string | null | undefined;
  };
}) => {
  const {
    children,
    experiment
  } = props;
  const variant = useMemo((): JSX.Element | null => {
    let node: JSX.Element | null = null;
    let defaultNode: JSX.Element | null = null;
    Children.forEach(children, element => {
      const name = element.props.segmentName;
      if (experiment?.case_name === name) {
        node = element;
      } else if (element.props.segmentName === 'default') {
        defaultNode = element;
      }
    });
    return isValidElement(node) ? node : defaultNode;
  }, [experiment, children]);
  return variant ? cloneElement(variant, {
    experiment
  }) : null;
});
ABTestExperimentCore.displayName = 'ABTestExperimentCore';
export const AbTestExperiment = (props: ABTestExperimentProps) => {
  const {
    name,
    children
  } = props;
  const {
    getCaseNameByTestName
  } = useABTestExperiment(name);
  const experiment = {
    name,
    case_name: getCaseNameByTestName(name)
  };
  const {
    trackGoogleEvent
  } = useAnalytics();
  useEffect(() => {
    trackGoogleEvent({
      eventName: 'ab_test',
      options: {
        name: getCaseNameByTestName(name) || 'default'
      }
    });
  }, [getCaseNameByTestName, name, trackGoogleEvent]);
  return <ABTestExperimentCore name={name} experiment={experiment} data-sentry-element="ABTestExperimentCore" data-sentry-component="AbTestExperiment" data-sentry-source-file="index.tsx">
      {children}
    </ABTestExperimentCore>;
};