import { useStripe } from '@stripe/react-stripe-js';
import { useSetAtom } from 'jotai';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { getDataFromSessionStorage } from 'src/utils';
import { tikTokAdvancedMatching, tikTokEvent, twitterEvent, useAnalytics } from '@features/Analytics';
import { useIsAutoRenewEnabled } from '@features/Payments';
import { processingModalStatusAtom, stripeSubscriptionWithPersistence } from '@features/Stripe/atoms';
import { AutoRenew } from '@features/Stripe/components/AutoRenew';
import { BILLING_PERIOD_LTV_MAP, CURRENCY_DOES_NOT_SUPPORT_PAYPAL } from '@features/Stripe/constants';
import { CardPaymentBlock } from '@features/Stripe/widgets/checkout-form/widgets/card-payment-block';
import { CheckoutPolicy } from '@features/Stripe/widgets/checkout-form/widgets/checkout-policy';
import { PaymentRequestButton } from '@features/Stripe/widgets/checkout-form/widgets/payment-request-button';
import { PaypalButtonBraintree } from '@features/Stripe/widgets/checkout-form/widgets/paypal-button-braintree';
import { PaypalButtonStripe } from '@features/Stripe/widgets/checkout-form/widgets/paypal-button-stripe';
import styles from './checkout-form.module.scss';
export const CheckoutForm = memo(({
  clientSecret,
  userEmail,
  planInfo,
  handleLoading
}: {
  clientSecret: string;
  userEmail: string;
  planInfo: {
    priceId: string;
    planName: string;
    fullPriceDiscount: number;
    billingPeriod: number;
    billingPeriodUnit: string;
    introOffer?: string;
    currency: string;
    braintreePlanId?: string;
    braintreeMerchantId?: string;
  };
  handleLoading: (status: boolean) => void;
}) => {
  const stripe = useStripe();
  const setProcessingModalStatus = useSetAtom(processingModalStatusAtom);
  const isBraintreePaypal = typeof planInfo.braintreePlanId === 'string' && planInfo.braintreePlanId.length > 0 && planInfo.braintreeMerchantId !== undefined;
  const isAutoRenewAvailable = useIsAutoRenewEnabled();
  const isCurrencySupportPayPal = useMemo(() => !CURRENCY_DOES_NOT_SUPPORT_PAYPAL.includes(planInfo.currency), [planInfo.currency]);
  const {
    trackFacebookEvent,
    trackGoogleEvent,
    trackPinterestEvent
  } = useAnalytics();
  useEffect(() => {
    if (stripe && (!isBraintreePaypal || !isCurrencySupportPayPal)) {
      handleLoading(false);
    }
  }, [handleLoading, isBraintreePaypal, isCurrencySupportPayPal, stripe]);
  const setStripeSubscriptionActive = useSetAtom(stripeSubscriptionWithPersistence);
  const analyticsCombinedParameters = useMemo(() => ({
    value: planInfo.fullPriceDiscount.toFixed(2),
    currency: planInfo.currency
  }), [planInfo.currency, planInfo.fullPriceDiscount]);
  const sendAddPaymentInfoEvents = useCallback(({
    paymentMethod,
    paymentSystem = 'stripe'
  }: {
    paymentMethod: string;
    paymentSystem?: 'stripe' | 'braintree';
  }) => {
    trackFacebookEvent({
      eventName: 'AddPaymentInfo',
      customData: {
        paymentSystem: paymentSystem,
        paymentMethod: paymentMethod
      },
      options: {
        email: userEmail
      }
    });
    twitterEvent('tw-oehe8-oehek', {
      email_address: userEmail
    });
    trackPinterestEvent({
      eventName: 'AddToCart'
    });
    trackGoogleEvent({
      eventName: 'add_payment_info',
      options: {
        value: planInfo.fullPriceDiscount,
        payment_system: paymentSystem,
        payment_method: paymentMethod
      }
    });
    tikTokAdvancedMatching(userEmail);
    tikTokEvent('AddPaymentInfo', {
      content_id: planInfo.planName
    });
  }, [planInfo.fullPriceDiscount, planInfo.planName, trackFacebookEvent, trackGoogleEvent, trackPinterestEvent, userEmail]);
  const handleProcessingStatus = useCallback((status: boolean) => {
    setProcessingModalStatus(status);
  }, [setProcessingModalStatus]);
  const onSuccess = useCallback(async ({
    paymentMethod,
    paymentSystem
  }: {
    paymentMethod: string;
    paymentSystem?: 'stripe' | 'braintree';
  }) => {
    setStripeSubscriptionActive('subscriptionActive');
    handleProcessingStatus(false);
    const calculateLTV = BILLING_PERIOD_LTV_MAP[planInfo.billingPeriod] || undefined;
    trackFacebookEvent({
      eventName: 'Purchase',
      customData: {
        variant: getDataFromSessionStorage({
          store: 'authStore',
          key: 'variant'
        }),
        paymentSystem: paymentSystem ? paymentSystem : 'stripe',
        paymentType: paymentMethod
      },
      options: {
        ...analyticsCombinedParameters,
        email: userEmail,
        subscription_id: undefined,
        stripe_subscription_id: planInfo.priceId,
        predicted_ltv: getDataFromSessionStorage({
          store: 'authStore',
          key: 'variant'
        }) === 'variant18' ? calculateLTV : 80
      }
    });
    trackFacebookEvent({
      eventName: 'Subscribe',
      customData: {
        variant: getDataFromSessionStorage({
          store: 'authStore',
          key: 'variant'
        })
      },
      options: {
        email: userEmail
      }
    });
    twitterEvent('tw-oehe8-oehej', {
      email: userEmail,
      ...analyticsCombinedParameters
    });
    trackPinterestEvent({
      eventName: 'Checkout',
      options: analyticsCombinedParameters
    });
    trackGoogleEvent({
      eventName: 'purchase_success',
      options: {
        product_id: planInfo.planName,
        payment_system: paymentSystem ? paymentSystem : 'stripe',
        payment_type: paymentMethod,
        campaign_name_manual: getDataFromSessionStorage({
          store: 'authStore',
          key: 'campaign'
        }) ?? 'undefined',
        email: userEmail,
        ...analyticsCombinedParameters
      }
    });
    tikTokAdvancedMatching(userEmail);
    tikTokEvent('CompletePayment', {
      content_id: planInfo.planName,
      quantity: 1,
      ...analyticsCombinedParameters
    });
  }, [analyticsCombinedParameters, handleProcessingStatus, planInfo.billingPeriod, planInfo.currency, planInfo.fullPriceDiscount, planInfo.planName, planInfo.priceId, setStripeSubscriptionActive, trackFacebookEvent, trackGoogleEvent, trackPinterestEvent, userEmail]);
  return <form id="payment-form" className={styles.form}>
        <div className={styles.paymentButtonsContainer}>
          <PaymentRequestButton clientSecret={clientSecret} stripe={stripe} handleAddPaymentInfoEventsSend={sendAddPaymentInfoEvents} handleSuccessPayment={onSuccess} planInfo={planInfo} />
          {isCurrencySupportPayPal && (isBraintreePaypal ? <PaypalButtonBraintree onSuccess={onSuccess} handleAddPaymentInfoEventsSend={sendAddPaymentInfoEvents} onLoadEnd={() => handleLoading(false)} subscriptionPlan={{
        braintreePlanId: planInfo.braintreePlanId!,
        merchantAccountId: planInfo.braintreeMerchantId!,
        fullPriceDiscount: planInfo.fullPriceDiscount,
        id: (planInfo.priceId as string)
      }} /> : <PaypalButtonStripe stripe={stripe} planId={planInfo.priceId} handleAddPaymentInfoEventsSend={sendAddPaymentInfoEvents} handleProcessingStatus={handleProcessingStatus} clientSecret={clientSecret} />)}
        </div>

        <CardPaymentBlock stripe={stripe} clientSecret={clientSecret} userEmail={userEmail} handleAddPaymentInfoEventsSend={sendAddPaymentInfoEvents} handleProcessingStatus={handleProcessingStatus} handleSuccessPayment={onSuccess} />

        {isAutoRenewAvailable && <AutoRenew placement="modal" />}

        <CheckoutPolicy />
      </form>;
});
CheckoutForm.displayName = 'CheckoutForm';