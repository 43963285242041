import * as Sentry from '@sentry/react';
import { ComponentProps, PropsWithChildren, useEffect } from 'react';
import { Image } from 'src/components/common/Image';
import { useAnalytics } from '@features/Analytics';
import { FormattedMessage } from '@features/intl';
import ContactUsLink from '../contact-us-link/contact-us-link';
import AlertWithCloseButton from '../modal/alert-with-close-button/alert-with-close-button';
import Alert from '../modal/alert/alert';
const ErrorAlert = ({
  AlertComponent = AlertWithCloseButton,
  show = true,
  error,
  ...props
}: {
  AlertComponent?: typeof Alert;
} & ComponentProps<typeof Alert>) => {
  const {
    trackGoogleEvent
  } = useAnalytics();
  useEffect(() => {
    if (error) {
      console.warn('Error handled', error);
      Sentry.captureException(error);
      trackGoogleEvent({
        eventName: 'something_went_wrong_opened',
        options: {
          reason: error
        }
      });
    }
  }, [error, trackGoogleEvent]);
  return <AlertComponent show={show} closeButtonText={<FormattedMessage defaultMessage="Refresh page" id="Onboarding.Payment.Method.Error.Button" />} image={<Image src="technical-problems.png" width={160} height={120} alt="" />} title={<FormattedMessage defaultMessage="Oops! Something went wrong" id="Onboarding.Payment.Method.Error.Title" />} description={<>
          <FormattedMessage defaultMessage="We’re trying to fix it." id="Onboarding.Payment.Method.Error.Text1" />
          <br />
          Please reload the page or try again later. If the issue persists, feel
          free to <ContactUsLink />.
        </>} {...props} data-sentry-element="AlertComponent" data-sentry-component="ErrorAlert" data-sentry-source-file="error-boundary.tsx" />;
};
export const ErrorBoundary = ({
  children
}: PropsWithChildren<{}>) => <Sentry.ErrorBoundary fallback={({
  error,
  resetError
}) => <ErrorAlert AlertComponent={AlertWithCloseButton} error={error} onHide={() => {
  resetError();
  window.location.reload();
}} />} showDialog data-sentry-element="unknown" data-sentry-component="ErrorBoundary" data-sentry-source-file="error-boundary.tsx">
    {children}
  </Sentry.ErrorBoundary>;