export const STRIPE_API_KEY = process.env.NEXT_PUBLIC_STRIPE_API_KEY as string;

export const ELEMENTS_APPEARANCE = {};

const getBaseStyles = () => ({
  style: {
    base: {
      fontSize: '16px',
      color: '#302c28',
      '::placeholder': {
        color: '#817B6F',
      },
    },
    invalid: {
      color: '#FF2E00',
    },
  },
});

export const ELEMENT_OPTIONS = {
  ...getBaseStyles(),
};

export const CARD_ELEMENT_OPTIONS = {
  placeholder: 'XXXX XXXX XXXX XXXX',
  ...getBaseStyles(),
};

//TODO: Replace with meta-data from stripe if it works
export const BILLING_PERIOD_LTV_MAP: Record<number, number> = {
  1: 91,
  3: 90,
  6: 83,
};

export const CURRENCY_DOES_NOT_SUPPORT_PAYPAL = [
  'AED',
  'ARS',
  'BGN',
  'CLP',
  'COP',
  'INR',
  'ISK',
  'KRW',
  'PEN',
  'PYG',
  'RSD',
  'RON',
  'SAR',
  'TRY',
  'TWD',
  'UYU',
  'VND',
  'ZAR',
];
