import { useAtom } from 'jotai/index';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo } from 'react';
import { Image } from 'src/components/common/Image';
import AlertWithCloseButton from 'src/components/common/modal/alert-with-close-button/alert-with-close-button';
import { routes } from 'src/constants/navigation';
import { useAnalytics } from '@features/Analytics';
import { stripePaymentErrorAtom } from '@features/Stripe/atoms';
import styles from './second-payment-failed-modal.module.scss';
export const SecondPaymentFailedModal = () => {
  const [{
    wasErrorModalWithOfferShown,
    errorCode,
    paymentSystem,
    paymentMethod
  }, setPaymentError] = useAtom(stripePaymentErrorAtom);
  const {
    trackGoogleEvent
  } = useAnalytics();
  const {
    query
  } = useRouter();
  const router = useRouter();
  const showModal = useMemo(() => wasErrorModalWithOfferShown && errorCode !== '', [errorCode, wasErrorModalWithOfferShown]);
  const handleModelClose = useCallback(() => {
    trackGoogleEvent({
      eventName: 'purchase_bank_rejected_tapped'
    });
    router.replace({
      pathname: routes.newPlanPaywall,
      query: {
        ...query,
        ['redirect_status']: undefined,
        ['payment_status']: undefined
      }
    }).then(r => r);
    setPaymentError(previous => ({
      ...previous,
      errorCode: ''
    }));
  }, [query, router, setPaymentError, trackGoogleEvent]);
  useEffect(() => {
    if (showModal) {
      trackGoogleEvent({
        eventName: 'purchase_bank_rejected_opened',
        options: {
          payment_system: paymentSystem ?? 'undefined',
          payment_type: paymentMethod ?? 'undefined',
          product_id: query['plan'] ?? 'undefined'
        }
      });
    }
  }, [paymentMethod, paymentSystem, query, showModal, trackGoogleEvent]);
  return <AlertWithCloseButton descriptionClassName={styles.description} show={showModal} onHide={handleModelClose} image={<Image src="card-error.svg" width={197} height={132} alt="card-error" />} title={<>
          Oops! Bank rejected
          <br /> the transaction again
        </>} description="The money was not debited, please try another card or payment method" closeButtonText="Try another payment method" data-sentry-element="AlertWithCloseButton" data-sentry-component="SecondPaymentFailedModal" data-sentry-source-file="index.tsx" />;
};