import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { currencyReplacer, useIsAutoRenewEnabled } from '@features/Payments';
import { TpCardPlanChildrenProps } from '@features/Paywall/types';
import { introOfferInfoAtom } from '@features/Stripe/atoms';
import styles from './full-price.module.scss';
type TpFullPriceProps = {
  withDiscount?: boolean;
  plan: TpCardPlanChildrenProps['plan'];
};
export const FullPrice = (props: TpFullPriceProps) => {
  const {
    withDiscount,
    plan
  } = props;
  const introOfferInfo = useAtomValue(introOfferInfoAtom);
  const currencySymbol = currencyReplacer(plan?.currency);
  const isAutoRenewEnabled = useIsAutoRenewEnabled();
  const isPlanWithIntroOffer = plan?.introOffer !== null;
  const resultPrice = useMemo(() => {
    if (withDiscount) {
      return isAutoRenewEnabled ? Number(plan?.introOfferData?.fullPriceDiscount).toFixed(2) : isPlanWithIntroOffer ? Number(introOfferInfo.fullPriceDiscount).toFixed(2) : plan?.fullPriceDiscount;
    } else {
      return isAutoRenewEnabled ? plan?.introOfferData?.fullPrice : plan?.fullPrice;
    }
  }, [introOfferInfo.fullPriceDiscount, isAutoRenewEnabled, isPlanWithIntroOffer, plan?.fullPrice, plan?.fullPriceDiscount, plan?.introOfferData?.fullPrice, plan?.introOfferData?.fullPriceDiscount, withDiscount]);
  return <span className={classNames({
    [styles.lineThrough]: !withDiscount
  })} data-sentry-component="FullPrice" data-sentry-source-file="index.tsx">{`${currencySymbol}${resultPrice}`}</span>;
};