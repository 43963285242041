import { useAtomValue } from 'jotai';
import { memo, useCallback, useContext, useEffect } from 'react';
import { FormattedNumber } from 'react-intl';
import Skeleton from 'react-loading-skeleton';
import { useDebounce } from 'use-debounce';
import Alert from 'src/components/common/modal/alert/alert';
import { UiKitButton } from 'src/components/common/ui-kit-button';
import { useAnalytics } from '@features/Analytics';
import { currencyReplacer } from '@features/Payments';
import { paymentMethodWithPersistence, stripeWasPaymentTriedAtom, upgradeInfoAtom, userAtom } from '@features/Stripe/atoms';
import { UpgradeSlider } from '@features/Stripe/components/UpgradeSlider';
import { useGetUpgradeData } from '@features/Stripe/hooks';
import { useModifySubscription } from '@features/Stripe/hooks/mutation/use-modify-subscription';
import { TpStripeUpgradeData } from '@features/Stripe/types';
import { ModalContext } from '@features/Stripe/widgets/payment-plans-stripe';
import { FormattedMessage } from '@features/intl';
import styles from './limited-upsell.module.scss';
type TpLimitedUpsellProps = {
  isShow: boolean;
  upgradeInfo: TpStripeUpgradeData;
  fullPrice: number;
};
export const LimitedUpsell = memo((props: TpLimitedUpsellProps) => {
  const {
    isShow,
    upgradeInfo,
    fullPrice
  } = props;
  const {
    mutateAsync: modifySubscription
  } = useModifySubscription();
  const {
    discountPercentage,
    currency,
    fullPriceDiscount
  } = useAtomValue(upgradeInfoAtom);
  const {
    data: upgradedData
  } = useGetUpgradeData({
    fullPrice,
    upgradePrice: fullPriceDiscount
  });
  const [upgradedDataDebounced] = useDebounce(upgradedData, 500);
  const {
    showCrossSailOffer
  } = useAtomValue(stripeWasPaymentTriedAtom);
  const {
    trackGoogleEvent
  } = useAnalytics();
  const {
    email,
    userId
  } = useAtomValue(userAtom);
  const {
    modalDispatch
  } = useContext(ModalContext);
  const paymentMethod = useAtomValue(paymentMethodWithPersistence);
  const isPayedWithBraintreePayPal = paymentMethod === 'paypal';
  const handleBuy = useCallback(() => {
    modifySubscription({
      email,
      userId,
      newPlanId: isPayedWithBraintreePayPal ? upgradeInfo?.braintreePlanId : upgradeInfo?.upgradeId,
      paymentSystem: isPayedWithBraintreePayPal ? 'braintree' : 'stripe'
    }).then(r => r);
    modalDispatch({
      type: 'SHOW_MODAL_UPSELL_OFFER'
    });
    trackGoogleEvent({
      eventName: 'upgrade_chose',
      options: {
        email,
        upgradeId: upgradeInfo?.upgradeId,
        fullPrice: upgradeInfo?.fullPrice
      }
    });
  }, [email, isPayedWithBraintreePayPal, modalDispatch, modifySubscription, trackGoogleEvent, upgradeInfo?.braintreePlanId, upgradeInfo?.fullPrice, upgradeInfo?.upgradeId, userId]);
  const handleDecline = useCallback(() => {
    modalDispatch({
      type: 'SHOW_MODAL_UPSELL_OFFER'
    });
    trackGoogleEvent({
      eventName: 'upgrade_decline',
      options: {
        email,
        upgradeId: upgradeInfo?.upgradeId,
        fullPrice: upgradeInfo?.fullPrice
      }
    });
  }, [email, modalDispatch, trackGoogleEvent, upgradeInfo?.fullPrice, upgradeInfo?.upgradeId]);
  useEffect(() => {
    if (showCrossSailOffer && fullPrice !== 0) {
      trackGoogleEvent({
        eventName: 'upgrade_open',
        options: {
          email,
          upgradeId: upgradeInfo?.upgradeId,
          fullPrice: upgradeInfo?.fullPrice
        }
      });
    }
  }, [email, fullPrice, showCrossSailOffer, trackGoogleEvent, upgradeInfo?.fullPrice, upgradeInfo?.upgradeId]);
  return <Alert className={styles.container} show={isShow}>
      <div className={styles.widthWrapper}>
        <h2 className={styles.title}>
          <FormattedMessage defaultMessage="Upgrade to an annual {br} {mark1} " id="Onboarding.Upgrade.Title" values={{
          br: <br />,
          mark1: <mark className={styles.mark}>
                  <FormattedMessage id="Onboarding.Upgrade.Title.Mark1" defaultMessage="Limited Plus+" />
                </mark>
        }} />
          <FormattedMessage id="Onboarding.Upgrade.Title.Text2" defaultMessage="Program" />
        </h2>

        <UpgradeSlider />

        <h2 className={styles.subtitle}>
          <FormattedMessage id="Onboarding.Upgrade.Offer" defaultMessage="Limited offer " />
          <mark className={styles.offMark}>
            -{discountPercentage}%{' '}
            <FormattedMessage id="Onboarding.Upgrade.Offer.Off" defaultMessage="off" />
          </mark>
        </h2>

        <div>
          <span className={styles.priceBlockSubTitle}>
            <FormattedMessage id="Onboarding.Upgrade.Main.Text" defaultMessage="Get 365 trainings days only for" />{' '}
            {upgradedDataDebounced == null ? <Skeleton count={1} width={40} height={18} /> : <>
                <FormattedNumber value={Number(Number(upgradedDataDebounced?.surcharge).toFixed(2))} style="currency" currency={currency} />
                *
              </>}
          </span>
          {upgradedDataDebounced == null ? <Skeleton count={1} width={100} height={58} /> : <div className={styles.priceBlock}>
              <span className={styles.sign}>+</span>
              <sup className={styles.currency}>
                {currencyReplacer(currency)}
              </sup>
              <p className={styles.surchargePerDay}>
                {Number(upgradedDataDebounced?.surchargePerDay).toString()[0]}{' '}
                <sup>
                  {Number(upgradedDataDebounced?.surchargePerDay).toFixed(2).toString().slice(2)}
                </sup>
              </p>

              <span className={styles.period}>
                {' '}
                <FormattedMessage id="Onboarding.Upgrade.Price" defaultMessage="per day" />
              </span>
            </div>}
        </div>

        <p className={styles.cupContainer}>
          <FormattedMessage id="Onboarding.Upgrade.Text" defaultMessage="Less than a cup of coffee" />
        </p>

        <UiKitButton fullWidth className={styles.payButton} onClick={handleBuy} data-testid="upgrade-button">
          <FormattedMessage id="Onboarding.Upgrade.Button" defaultMessage="Upgrade" />
        </UiKitButton>

        <UiKitButton as="a" color="pure" className={styles.declineButton} onClick={handleDecline} data-testid="upgrade-button-decline">
          <FormattedMessage id="Onboarding.Upgrade.Decline" defaultMessage="Decline an offer" />
        </UiKitButton>

        <div className={styles.additionalDescription}>
          <p className={styles.additionalCharge}>
            <FormattedMessage id="Onboarding.Upgrade.Info" defaultMessage="*additional charge to your current subscription " />
          </p>
          <p>
            <FormattedMessage id="Onboarding.Upgrade.Rules1" defaultMessage="The Mimika Plus+ Subscription is a recurring, extended " />
            <FormattedMessage id="Onboarding.Upgrade.Rules2" defaultMessage="annual subscription. Today, you will be charged" />{' '}
            <FormattedNumber value={Number(Number(upgradedDataDebounced?.surcharge).toFixed(2))} style="currency" currency={currency} />{' '}
            <FormattedMessage id="Onboarding.Upgrade.Rules3" defaultMessage="in addition to your current subscription. When the first subscription period ends, payment will be made at the" />{' '}
            <FormattedMessage id="Onboarding.Upgrade.Rules4" defaultMessage="full subscription price of" />{' '}
            <FormattedNumber value={fullPrice} currency={currency} style="currency" />{' '}
            <FormattedMessage id="Onboarding.Upgrade.Rules5" defaultMessage="with the" />{' '}
            {discountPercentage}%{' '}
            <FormattedMessage id="Onboarding.Upgrade.Rules6" defaultMessage="discount" />
            <FormattedMessage id="Onboarding.Upgrade.Rules7" defaultMessage=" (the full price is" />{' '}
            {fullPriceDiscount}
            <FormattedMessage id="Onboarding.Upgrade.Rules8" defaultMessage="). The discount will be reserved." />
            <FormattedMessage id="Onboarding.Upgrade.Rules9" defaultMessage=" You can cancel your subscription at any time." />
          </p>
          <p>
            <FormattedMessage id="Onboarding.Upgrade.Terms1" defaultMessage="By purchasing, you agree to the T&Cs, Privacy Policy, Money Back, and Cancelation Policy Mimika will use your payment details for seemingless future payments. The transaction will be converted into USD based on your bank’s exchange rate. Subscriptions will renew automatically at the end of each period unless you cancel at least 24 hours before the end of the current period. To avoid being charged, cancel your subscription by contacting our support at least 24 hours before the end of the paid period. If you are unsure how " />
            <FormattedMessage id="Onboarding.Upgrade.Terms2" defaultMessage="to cancel, please contact us via" />{' '}
            <a href="mailto:support@mimika-app">support@mimika-app</a>.
          </p>
        </div>
      </div>
    </Alert>;
});
LimitedUpsell.displayName = 'LimitedUpsell';