import { ComponentProps, ReactNode } from 'react';
import { UiKitButton } from 'src/components/common/ui-kit-button';
import Alert from '../../../common/modal/alert/alert';
const AlertWithCloseButton = ({
  closeButtonText = 'OK',
  footer,
  buttonVariant,
  ...props
}: {
  closeButtonText?: ReactNode;
} & ComponentProps<typeof Alert>) => {
  const {
    onHide
  } = props;
  return <Alert footer={<>
          {footer}
          <UiKitButton fullWidth variant={buttonVariant} type="button" onClick={onHide}>
            {closeButtonText}
          </UiKitButton>
        </>} {...props} data-sentry-element="Alert" data-sentry-component="AlertWithCloseButton" data-sentry-source-file="alert-with-close-button.tsx" />;
};
export default AlertWithCloseButton;