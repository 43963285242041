import { axiosMicroservice } from 'src/api/axios';
import type { TpStripeSubscriptionResponse } from 'src/features/Stripe/types';

export const getStripeUnsubscription = async ({
  productId,
  countryCode,
}: {
  productId: string | undefined;
  countryCode: string | undefined;
}): Promise<TpStripeSubscriptionResponse> => {
  const { data } = await axiosMicroservice.get(
    `stripe/cancellation_plans?app=FaceYoga&product_id=${productId}&country_code=${countryCode}`,
  );
  return data;
};
