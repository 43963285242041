import classNames from 'classnames';
import { ElementType, ReactNode, useRef } from 'react';
import useResizeObserver from 'use-resize-observer';
import styles from './mobile-header.module.scss';
export type Props = {
  as?: ElementType;
  className?: string;
  titleClassName?: string;
  title?: ReactNode;
  left?: ReactNode;
  right?: ReactNode;
};
const MobileHeader = ({
  as: ContainerComponent = 'div',
  className,
  titleClassName,
  title,
  left,
  right
}: Props) => {
  const leftContainerRef = useRef<HTMLDivElement>(null);
  const rightContainerRef = useRef<HTMLDivElement>(null);
  const handleLeftRightResize = () => {
    const left = leftContainerRef.current;
    const right = rightContainerRef.current;
    if (left && right) {
      left.style.removeProperty('min-width');
      right.style.removeProperty('min-width');
      const leftWidth = left.offsetWidth;
      const rightWidth = right.offsetWidth;
      if (leftWidth > rightWidth) {
        right.style.setProperty('min-width', `${leftWidth}px`);
      } else if (rightWidth > leftWidth) {
        left.style.setProperty('min-width', `${rightWidth}px`);
      }
    }
  };
  useResizeObserver({
    ref: leftContainerRef,
    onResize: handleLeftRightResize
  });
  useResizeObserver({
    ref: rightContainerRef,
    onResize: handleLeftRightResize
  });
  return <ContainerComponent className={classNames(styles.container, className)} data-sentry-element="ContainerComponent" data-sentry-component="MobileHeader" data-sentry-source-file="mobile-header.tsx">
      <div ref={leftContainerRef} className={styles.leftContainer}>
        {left}
      </div>
      {title && <h2 className={classNames(styles.title, titleClassName)}>{title}</h2>}
      <div ref={rightContainerRef} className={styles.rightContainer}>
        {right}
      </div>
    </ContainerComponent>;
};
export default MobileHeader;