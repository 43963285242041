import { memo } from 'react';
import { CardSkeleton } from 'src/features/Paywall/components/skeleton';
export const EmptySubscriptionPlanCardList = memo(({
  length = 3
}: {
  length?: number;
}) => <>
      {Array.from({
    length
  }, (_, index) => <CardSkeleton key={index} />)}
    </>);
EmptySubscriptionPlanCardList.displayName = 'EmptySubscriptionPlanCardList';