import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { Image } from 'src/components/common/Image';
import { useCountdown } from 'src/utils/hooks';
import { currencyReplacer } from '@features/Payments';
import { noMoneyPlanInfoAtom, selectedPlanAtom } from '@features/Stripe/atoms';
import styles from './start-block-transaktion-declined.module.scss';
export const StartBlockTransactionDeclined = ({
  variant
}: {
  variant?: string;
}) => {
  const noMoneyPlanInfo = useAtomValue(noMoneyPlanInfoAtom);
  const selectedPlanInfo = useAtomValue(selectedPlanAtom);
  const targetTime = useMemo(() => Date.now() + 5 * 60 * 1000, []);
  const fullPrice = useMemo(() => variant === 'email2' ? selectedPlanInfo.fullPrice : noMoneyPlanInfo.priceFromDefaultOneMonth, [noMoneyPlanInfo.priceFromDefaultOneMonth, selectedPlanInfo.fullPrice, variant]);
  const fullPriceDiscount = useMemo(() => variant === 'email2' ? selectedPlanInfo.fullPriceDiscount : noMoneyPlanInfo.fullPriceDiscount, [noMoneyPlanInfo.fullPriceDiscount, selectedPlanInfo.fullPriceDiscount, variant]);
  const currency = useMemo(() => variant === 'email2' ? currencyReplacer(selectedPlanInfo.currency) : currencyReplacer(noMoneyPlanInfo.currency), [noMoneyPlanInfo.currency, selectedPlanInfo.currency, variant]);
  const [_, minutes, seconds] = useCountdown({
    targetDate: targetTime,
    onComplete: () => null
  });
  return <section className={classNames(styles.container, variant === 'email2' && styles.email2Container)} data-sentry-component="StartBlockTransactionDeclined" data-sentry-source-file="index.tsx">
      {variant === 'email2' && <div className={styles.imageMask}>
          <Image src="email-paywall-mask.svg" width={164} height={200} alt="" />
        </div>}
      <div>
        <div className={styles.priceBanner}>
          <del>{`${currency}${fullPrice}`}</del>
          <b>{` ${currency}${fullPriceDiscount}`}</b> per 1 month
          <Image className={styles.bannerLabel} src="fire-emoji.webp" width={17.5} height={17.5} alt="" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
        </div>
        <p className={styles.bannerDescription}>
          For your <b>personal program</b>
        </p>
      </div>

      <div className={styles.timerContainer}>
        <p className={styles.timerDescription}>
          Act now, the time is running out!
        </p>
        <div className={styles.timer}>
          <div className={styles.timerTime}>0</div>
          <div className={styles.timerTime}>0</div>
          <div className={styles.timerDivider}>:</div>
          <div className={styles.timerTime}>
            {+minutes >= 10 ? Math.floor(+minutes / 10) : 0}
          </div>
          <div className={styles.timerTime}>
            {+minutes >= 10 ? Math.floor(+minutes % 10) : +minutes}
          </div>
          <div className={styles.timerDivider}>:</div>
          <div className={styles.timerTime}>
            {+seconds >= 10 ? Math.floor(+seconds / 10) : 0}
          </div>
          <div className={styles.timerTime}>
            {+seconds >= 10 ? Math.floor(+seconds % 10) : +seconds}
          </div>
        </div>
      </div>
    </section>;
};