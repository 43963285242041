import { sendGAEvent } from '@next/third-parties/google';

export const googleAnalyticsEvent = ({
  eventName,
  properties,
}: {
  eventName: string;
  properties?: { [key: string]: any };
}) => sendGAEvent('event', eventName, properties || {});

export const googleSetUserProperties = ({
  properties,
}: {
  properties: { [key: string]: string | number | boolean | null };
}) => window.gtag?.('set', 'user_data', properties);
