import braintree from 'braintree-web';
import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import { observer } from 'mobx-react-lite';
import type { FlowType } from 'paypal-checkout-components';
import { HTMLProps, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import LoadingIndicator from 'src/components/common/loading-indicator/loading-indicator';
import { useStores } from 'src/components/common/root-store-provider/root-store-provider';
import { PaymentMethod } from 'src/models/payments-store/payments-store';
import type { SubscriptionPlan } from 'src/models/subscription-plan/subscription-plan';
import { usePrevious } from 'src/utils/hooks/use-previous';
import { useAnalytics } from '@features/Analytics';
import { AnalyticsPaymentMethod, AnalyticsPaymentSystem, PaymentCompletionStatus, createBraintreeSubscription, handleCheckoutOpenRequest, usePaymentCompletionStatus } from '@features/Payments';
import { selectedPlanAtom } from '@features/Stripe/atoms';
import PaypalLogo from '../../../../assets/images/payment/paypal-logo.svg';
import { braintreeClientTokenAtom } from '../../atoms';
import styles from './paypal-payment-button.module.scss';
const PAYPAL_BUTTONS_CONTAINER_ID = 'paypal-buttons-container';
export const PAYPAL_BUTTONS_SECOND_CONTAINER_ID = 'paypal-buttons-second-container';
export const PAYPAL_BUTTONS_THIRD_CONTAINER_ID = 'paypal-buttons-third-container';
const PaypalPaymentButton = observer(({
  className,
  subscriptionPlan,
  buttonContent = <PaypalLogo />,
  onLoadEnd,
  id = PAYPAL_BUTTONS_CONTAINER_ID,
  ...props
}: {
  onLoadEnd: () => void;
  subscriptionPlan: SubscriptionPlan;
  id?: string;
  buttonContent?: ReactNode;
} & Omit<HTMLProps<HTMLDivElement>, 'children'>) => {
  const {
    authStore,
    paymentsStore
  } = useStores();
  const {
    user,
    auth_token,
    variant
  } = authStore;
  const braintreeClientToken = useAtomValue(braintreeClientTokenAtom);
  const {
    introOfferData
  } = useAtomValue(selectedPlanAtom);
  const {
    trackFacebookEvent,
    trackGoogleEvent,
    trackPinterestEvent
  } = useAnalytics();
  const {
    setPaymentCompletionStatus
  } = usePaymentCompletionStatus();
  const previousPlan: any = usePrevious({
    subscriptionPlan
  });
  const shouldFetch = useMemo(() => previousPlan ? previousPlan.subscriptionPlan !== subscriptionPlan : true, [previousPlan, subscriptionPlan]);
  const [isLoading, setIsLoading] = useState(true);
  const braintreeHandle = useCallback(async () => {
    setIsLoading(true);
    if (!user) {
      throw new Error('A user is required');
    }
    if (!braintreeClientToken) {
      throw new Error('Client token is required');
    }
    const client = await braintree.client.create({
      authorization: braintreeClientToken
    });
    const paypalCheckout = await braintree.paypalCheckout.create({
      client
    });
    await paypalCheckout.loadPayPalSDK({
      vault: true
    });
    const buttons = window.paypal.Buttons({
      style: {
        height: 55
      },
      // @ts-ignore (an issue with library typings)
      fundingSource: window.paypal.FUNDING.PAYPAL,
      createBillingAgreement: () => {
        paymentsStore.setPaymentMethod(PaymentMethod.PAYPAL);
        handleCheckoutOpenRequest({
          subscriptionPlan,
          paymentMethod: AnalyticsPaymentMethod.PAYPAL,
          paymentSystem: AnalyticsPaymentSystem.BRAINTREE,
          email: user!.email,
          variant: variant
        });
        trackFacebookEvent({
          eventName: 'AddPaymentInfo',
          customData: {
            paymentMethod: 'payPal',
            paymentSystem: 'braintree'
          },
          options: {
            email: user?.email
          }
        });
        trackPinterestEvent({
          eventName: 'AddToCart'
        });
        return paypalCheckout.createPayment({
          flow: ('vault' as (typeof FlowType)['Vault'])
        });
      },
      onCancel: () => {
        trackGoogleEvent({
          eventName: 'add_payment_info_skipped',
          options: {
            payment_system: 'braintree',
            payment_method: AnalyticsPaymentMethod.PAYPAL
          }
        });
      },
      onApprove: async data => {
        setIsLoading(true);
        try {
          const authorizationResponse = await paypalCheckout.tokenizePayment(data);
          await createBraintreeSubscription({
            customerId: user!.id.toString(),
            customerToken: auth_token!,
            merchantAccountId: subscriptionPlan.merchantAccountId,
            planId: subscriptionPlan.braintreePlanId!,
            paymentMethodNonce: authorizationResponse.nonce,
            btIntroOfferAmount: introOfferData?.fullPriceDiscount,
            btIntroOfferCurrency: introOfferData?.currency
          });
          setPaymentCompletionStatus({
            value: PaymentCompletionStatus.SUCCESS,
            selectedPlanId: subscriptionPlan.id
          });
          return authorizationResponse;
        } finally {
          setIsLoading(false);
        }
      },
      onError: () => {
        setPaymentCompletionStatus({
          value: PaymentCompletionStatus.FAILURE,
          selectedPlanId: subscriptionPlan.id
        });
      }
    });
    if (document.querySelector(`#${PAYPAL_BUTTONS_CONTAINER_ID}`)) {
      buttons.render(`#${PAYPAL_BUTTONS_CONTAINER_ID}`);
    }
    if (document.querySelector(`#${PAYPAL_BUTTONS_SECOND_CONTAINER_ID}`)) {
      buttons.render(`#${PAYPAL_BUTTONS_SECOND_CONTAINER_ID}`);
    }
    setIsLoading(false);
    onLoadEnd();
  }, [user, braintreeClientToken, onLoadEnd, paymentsStore, subscriptionPlan, variant, trackFacebookEvent, trackPinterestEvent, trackGoogleEvent, auth_token, introOfferData?.fullPriceDiscount, introOfferData?.currency, setPaymentCompletionStatus]);
  useEffect(() => {
    if (shouldFetch && braintreeClientToken !== '') {
      braintreeHandle().then(r => r);
    }
  }, [braintreeClientToken, braintreeHandle, shouldFetch]);
  return <div className={classNames(styles.container, className)} {...props} style={{
    pointerEvents: props.disabled ? 'none' : 'initial',
    opacity: props.disabled ? 0.7 : 1
  }}>
        {isLoading ? <LoadingIndicator /> : buttonContent}
        <div className={styles.frame} id={id} />
      </div>;
});
export default PaypalPaymentButton;