import { useShouldInit } from '@features/Analytics/hooks';
import { GoogleAnalytics } from '@next/third-parties/google';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import useCookie from 'react-use-cookie';
import { useStores } from 'src/components/common/root-store-provider/root-store-provider';
import { GA_ID, GA_ID_V2 } from '../../constants';
export const GoogleAnalyticsComponent = observer(() => {
  const {
    authStore: {
      variant,
      setGooglePseudoID
    }
  } = useStores();
  const {
    shouldGoogleAnalyticsV2Init
  } = useShouldInit(variant);
  const [googlePseudoId] = useCookie('_ga', '0');
  useEffect(() => {
    setGooglePseudoID(googlePseudoId.slice(6));
  }, [googlePseudoId, setGooglePseudoID]);
  const gaId = shouldGoogleAnalyticsV2Init ? GA_ID_V2 : GA_ID;
  return <GoogleAnalytics gaId={(gaId as string)} />;
});