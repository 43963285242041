import { ReactNode } from 'react';
import { TpMessageKeys } from 'src/features/intl';
import { Image } from '../../common/Image';
export type TpReview = {
  name: string | {
    id: TpMessageKeys;
    defaultMessage: string;
  };
  rating: number;
  date: string | {
    id: TpMessageKeys;
    defaultMessage: string;
  };
  review: string | {
    id: TpMessageKeys;
    defaultMessage: string;
  };
  avatar: ReactNode;
};
export const reviews: {
  [key: string]: TpReview;
} = {
  user7: {
    name: {
      id: 'Onboarding.Paywall.Main.Review2.Name',
      defaultMessage: 'Anna C.'
    },
    rating: 5,
    date: {
      id: 'Onboarding.Paywall.Main.Review2.Date',
      defaultMessage: '1 Jan'
    },
    review: {
      id: 'Onboarding.Paywall.Main.Review2.Text',
      defaultMessage: 'This is a wonderful app, I really like the progress I’m having 😍'
    },
    avatar: <Image src="avatar7-paywall.webp" width={48} height={48} alt="avatar" />
  },
  user8: {
    name: {
      id: 'Onboarding.Paywall.Main.Review1.Name',
      defaultMessage: 'Louise R.'
    },
    rating: 5,
    date: {
      id: 'Onboarding.Paywall.Main.Review1.Date',
      defaultMessage: '23 Dec'
    },
    review: {
      id: 'Onboarding.Paywall.Main.Review1.Text',
      defaultMessage: 'It takes a little time, but the effect is colossal. Thanks! I feel and look 15 years younger.😎'
    },
    avatar: <Image src="avatar8-paywall.webp" width={48} height={48} alt="avatar" />
  },
  user9: {
    name: {
      id: 'Onboarding.Paywall.Main.Review3.Name',
      defaultMessage: 'Marie M.'
    },
    rating: 5,
    date: {
      id: 'Onboarding.Paywall.Main.Review3.Date',
      defaultMessage: '13 Mar'
    },
    review: {
      id: 'Onboarding.Paywall.Main.Review3.Text',
      defaultMessage: 'The training is amazing, and you do not need a beautician or special equipment 😉'
    },
    avatar: <Image src="avatar9-paywall.webp" width={48} height={48} alt="avatar" />
  }
};