import { Elements } from '@stripe/react-stripe-js';
import { Stripe } from '@stripe/stripe-js';
import classNames from 'classnames';
import { memo } from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import { CurrentPrice } from 'src/features/Paywall/widgets/subscription-card/current-price';
import { PriceUnitFull } from 'src/features/Paywall/widgets/subscription-card/price-unit-full';
import { SubscriptionCardMaster } from '@features/Paywall';
import { BillingPeriod } from '@features/Paywall/widgets/subscription-card/billing-period';
import { DiscountPercentage } from '@features/Paywall/widgets/subscription-card/discount-percentage';
import { FullPrice } from '@features/Paywall/widgets/subscription-card/full-price';
import { LearnMoreList } from '@features/Stripe/components/LearnMoreList';
import { TpStripePlanCardProps } from '@features/Stripe/types';
import { CheckoutSelector } from '@features/Stripe/widgets/checkout-selector';
import styles from './subscription-plan-card-with-payment-methods.module.scss';
export const SubscriptionPlanCardStripeWithPaymentMethods = memo((props: TpStripePlanCardProps & {
  stripePromise: Promise<Stripe | null>;
}) => {
  const {
    plan,
    disabled = false,
    wasPaymentTried,
    stripePromise,
    planCardClassname,
    learnMoreList
  } = props;
  return <SubscriptionCardMaster {...props} planCardClassname={classNames(styles.planCard, planCardClassname)} contentClassname={styles.content} leftBottomContent={(value: boolean) => !disabled && <div className={styles.wrapper}>
              <div className={classNames(styles.text, {
      [styles.textChecked]: value
    })}>
                {plan?.fractionalPrice !== plan?.fractionalPriceDiscount && value && <FullPrice plan={plan} />}{' '}
                <FullPrice withDiscount plan={plan} />{' '}
                <BillingPeriod plan={plan} />
              </div>
            </div>} rightContent={(value: boolean) => <div className={styles.alignEnd}>
            <div className={styles.rightBlockContainer}>
              {plan?.fractionalPrice !== plan?.fractionalPriceDiscount && value && <div className={styles.fullPriceAndDiscount}>
                    <PriceUnitFull plan={plan} reverse checked={value} className={styles.priceUnitFull} />

                    <div className={styles.discountPercentage}>
                      {wasPaymentTried && plan.preselected && <del className={styles.defaultDiscount}>
                          {plan.specialDiscountPercentage}%
                        </del>}
                      {plan?.discountPercentage !== 0 && plan?.discountPercentage != null && <DiscountPercentage discountPercentage={plan.discountPercentage} isActive={value && !wasPaymentTried} wasPaymentTried={value && wasPaymentTried} />}
                    </div>
                  </div>}
              <CurrentPrice plan={plan} checked={value} />
            </div>
          </div>} fullWidthBottomContent={(value: boolean) => value && <div className={styles.bottomContent}>
              {(plan?.learnMoreList || learnMoreList) && <>
                  {plan?.learnMoreList && <LearnMoreList isCompactStyle learnMoreList={plan.learnMoreList} />}
                  {learnMoreList && <LearnMoreList isCompactStyle learnMoreList={learnMoreList} />}
                  <Elements stripe={stripePromise}>
                    <CheckoutSelector isCompactStyle />
                  </Elements>
                </>}
            </div>} />;
});
SubscriptionPlanCardStripeWithPaymentMethods.displayName = 'SubscriptionPlanCardStripeWithPaymentMethods';