import classNames from 'classnames';
import React, { memo } from 'react';
import styles from 'src/widgets/policy/components/links.module.scss';
import { useAnalytics } from '@features/Analytics';
import { FormattedMessage, TpMessageKeys } from '@features/intl';
export const PolicyLink = memo(({
  place,
  className,
  children
}: {
  place: 'paywall' | 'checkout' | 'burger_menu' | 'email_modal' | 'upsale' | 'explit_checkout';
  className?: string;
  children?: React.ReactNode;
}) => {
  const {
    trackGoogleEvent
  } = useAnalytics();
  let formattedMessageId: TpMessageKeys;
  switch (place) {
    case 'paywall':
      {
        formattedMessageId = 'Onboarding.Paywall.Main.Contact.PrivacyPolicy.A';
        break;
      }
    case 'burger_menu':
      {
        formattedMessageId = 'Onboarding.Menu.Privacy';
        break;
      }
    case 'email_modal':
      {
        formattedMessageId = 'Onboarding.Email.Terms.PrivacyPolicy.A';
        break;
      }
    case 'upsale':
      {
        formattedMessageId = 'Onboarding.Book.PrivacyPolicy.A';
        break;
      }
    case 'checkout':
      {
        formattedMessageId = 'Onboarding.Checkout.PrivacyPolicy.A';
        break;
      }
    default:
      formattedMessageId = 'Onboarding.Menu.Privacy';
      break;
  }
  return <a className={classNames(styles.link, className)} href="https://legal.mentalgrowth.app/mimika/privacy.pdf" target="_blank" rel="noreferrer" onClick={() => trackGoogleEvent({
    eventName: 'link_open',
    options: {
      place: place,
      link_name: 'privacy policy'
    }
  })}>
        {children || <FormattedMessage id={formattedMessageId} defaultMessage="Privacy Policy" />}
      </a>;
});
PolicyLink.displayName = 'PolicyLink';