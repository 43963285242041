import { useQuery } from '@tanstack/react-query';

import { getStripeUnsubscription } from '@features/Stripe/api/getStripeUnsubscriptions';

export const useGetUnsubscribePlans = ({
  productId,
  countryCode,
}: {
  productId: string | undefined;
  countryCode: string | undefined;
}) => {
  const { data } = useQuery({
    queryKey: ['stripe-subscriptions', productId],
    queryFn: () => getStripeUnsubscription({ productId, countryCode }),
    enabled: !!productId && !!countryCode,
  });

  return data;
};
