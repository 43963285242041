import { useMutation } from '@tanstack/react-query';
import { useAtom } from 'jotai/index';
import { useEffect } from 'react';

import {
  braintreeClientTokenAtom,
  generateBraintreeClientToken,
} from '@features/Payments';

export const useGenerateBraintreeClientToken = ({
  userFields,
  merchantAccountId,
}: {
  userFields: { email?: string; id?: number | string };
  merchantAccountId?: number | string | null;
}): string | null => {
  const [braintreeClientToken, setBraintreeClientToken] = useAtom(
    braintreeClientTokenAtom,
  );

  const { mutateAsync: mutation } = useMutation({
    mutationFn: generateBraintreeClientToken,
    onSuccess: (data: string) => setBraintreeClientToken(data),
  });

  useEffect(() => {
    if (
      userFields.id &&
      userFields.email &&
      merchantAccountId &&
      braintreeClientToken === ''
    ) {
      mutation({
        customerEmail: userFields.email,
        customerId: userFields.id.toString(),
        merchantAccountId: merchantAccountId,
      }).then((response: string) => setBraintreeClientToken(response));
    }
  }, [
    braintreeClientToken,
    merchantAccountId,
    mutation,
    setBraintreeClientToken,
    userFields.email,
    userFields.id,
  ]);

  return braintreeClientToken;
};
