import { Image } from 'src/components/common/Image';
import { FormattedMessage } from 'src/features/intl';
import { RefundLink } from 'src/widgets/policy';
import styles from './guarantees.module.scss';
export const Guarantees = () => <div className={styles.result} data-sentry-component="Guarantees" data-sentry-source-file="index.tsx">
    <h3>
      <FormattedMessage defaultMessage="Money-Back guarantee" id="Onboarding.Paywall.Main.MoneyBack.Rules.Title" data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
    </h3>
    <p>
      <FormattedMessage defaultMessage="We are convinced of the quality and efficiency of our service. If you are ready to achieve your goals, it’s risk-free offer. We guarantee you’ll see visible result or you you’ll receive a full refund within 30 days after your purchase. You will need to demonstrate that you have followed the program." id="Onboarding.Paywall.Main.MoneyBack.Rules.Text" data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
      <br />
      <br />
      <span style={{
      marginRight: '102px',
      display: 'inline-block'
    }}>
        Find more about the applicable limitations in our{' '}
        <RefundLink place="paywall" data-sentry-element="RefundLink" data-sentry-source-file="index.tsx" />
      </span>
    </p>
    <Image src="stamp.webp" width={112} height={102} alt="stamp image" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
  </div>;