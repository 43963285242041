import { ReactElement, memo, useEffect } from 'react';
import { TpABTest } from '@features/ABTesting';
import { useAnalytics } from '@features/Analytics';
type TpABTestVariantProps = {
  segmentName: string;
  experiment?: TpABTest;
  children: ReactElement;
};
export const AbTestVariant = memo((props: TpABTestVariantProps) => {
  const {
    children,
    segmentName,
    experiment
  } = props;
  const {
    trackGoogleEvent
  } = useAnalytics();
  useEffect(() => {
    trackGoogleEvent({
      eventName: 'ab_test',
      options: {
        name: experiment?.name || 'default',
        group: experiment?.case_name || 'default'
      }
    });
  }, [experiment?.case_name, experiment?.name, trackGoogleEvent]);
  return children;
});
AbTestVariant.displayName = 'AbTestVariant';