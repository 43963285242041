import classNames from 'classnames';
import { PolicyLink } from 'src/widgets/policy/components/PolicyLink';
import { RefundLink } from 'src/widgets/policy/components/RefundLink';
import { TermsLink } from 'src/widgets/policy/components/TermsLink';
import { FormattedMessage } from '@features/intl';
import styles from './policy.module.scss';
export type TpPolicyProps = {
  className?: string;
  place: 'paywall' | 'checkout' | 'burger_menu' | 'email_modal';
};
export const Policy = ({
  className,
  place
}: TpPolicyProps) => <span className={classNames(styles.container, className)} data-sentry-component="Policy" data-sentry-source-file="policy.tsx">
    <FormattedMessage id="Onboarding.Paywall.Main.Contact.Terms" defaultMessage="By continuing, you accept the {terms}, {privacy}, {subscription}" values={{
    privacy: <PolicyLink place={place} />,
    terms: <TermsLink place={place} />,
    subscription: <RefundLink place={place} />
  }} data-sentry-element="FormattedMessage" data-sentry-source-file="policy.tsx" />
  </span>;