import { observer } from 'mobx-react-lite';
import { ReactNode, createContext, useEffect, useMemo } from 'react';
import { useStores } from 'src/components/common/root-store-provider/root-store-provider';
export type ThemeContextType = {
  variant: string;
};
export const ThemeContext = createContext<ThemeContextType>({
  variant: 'variant16'
});
export const ThemeProvider = observer(({
  children
}: {
  children: ReactNode;
}) => {
  const {
    authStore
  } = useStores();
  const {
    variant
  } = authStore;
  const value: ThemeContextType = useMemo(() => ({
    variant: variant ?? 'variant16'
  }), [variant]);
  useEffect(() => {
    document.body.dataset.theme = variant;
  }, [variant]);
  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>;
});